import React from 'react';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';
import { TaskQuery } from '../../models/tasks/TaskQuery';

interface TaskFavoritesFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TaskQuery, value: any) => void;
  secondLabel?: string;
}

function TaskFavoritesFilter({
  isChecked = false,
  onChange,
  secondLabel,
}: TaskFavoritesFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <SwitchInput
      id="taskFavoritesFilter"
      checked={isChecked}
      onChange={(value) => onChange('onlyFavorites', value || undefined)}
      label={t('favoriteTickets')}
      secondLabel={secondLabel}
    />
  );
}

export default TaskFavoritesFilter;
