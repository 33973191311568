import { useCallback } from 'react';
import { DayOfWeek, ServiceTimeModel } from '../../models/contract-management/ServiceTimeModel';
import { SupportContactPersonModel } from '../../models/contract-management/SupportContactPersonModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import './TicketContract.scss';
import { Contract } from '../../models/contract-management/ContractManagementModel';
import Tooltip from '../../ui/tooltip/Tooltip';

interface ServiceTimesProps {
  serviceTimes: ServiceTimeModel[];
}

function ServiceTimes({ serviceTimes }: ServiceTimesProps) {
  const { t } = useTranslationText('commons');
  const { t: tContract } = useTranslationText('contractManagements');
  const getDayTranslation = useCallback((day: DayOfWeek) => t(`${day}_SHORT`), [t]);
  const getTime = useCallback(
    (time: string | undefined) => (time ? time.substring(0, time.lastIndexOf(':')) : '-'),
    []
  );

  return (
    <div className="service-times">
      {serviceTimes.map((serviceTime) => (
        <div className="service-time-item" id={serviceTime.id} key={serviceTime.id}>
          <div className="days">
            <span>{getDayTranslation(serviceTime.days[0])}</span> -
            <span> {getDayTranslation(serviceTime.days[serviceTime.days.length - 1])}</span>
          </div>
          <span className="time-begin">{getTime(serviceTime.begin)}</span>
          <span>{getTime(serviceTime.end)}</span>
          <span className={serviceTime.publicHolidaysIncluded ? 'with-public-holiday' : ''}>{t('clock')}</span>
          {serviceTime.publicHolidaysIncluded && (
            <Tooltip anchorId={serviceTime.id} content={tContract('serviceTime_alsoPublicHoliday')} delayShow={200} />
          )}
        </div>
      ))}
    </div>
  );
}

interface SupportContactsProps {
  contacts: SupportContactPersonModel[];
}

function SupportContacts({ contacts }: SupportContactsProps) {
  return (
    <>
      {contacts.map((contact) => (
        <div className="contract-details-wrapper" key={contact.id}>
          <div className="contacts">
            <div>{contact.companyName ?? '-'}</div>
            <div>{contact.email ? <a href={`mailto:${contact.email}`}>{contact.email}</a> : <span>-</span>}</div>
            <div>
              {contact.phoneNumber ? <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a> : <span>-</span>}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

interface ContractSupportDetailsProps {
  contract: Contract;
}

export function ContractSupportDetails({ contract }: ContractSupportDetailsProps) {
  const { t } = useTranslationText('contractManagements');
  return (
    <div className="contract-support-details">
      {contract && (
        <div className="columns contact-details">
          {contract.supportContactPersons && (
            <div className="column is-half no-overflow">
              <span className="col-title">{t('malfunctionContacts')}</span>
              <SupportContacts contacts={contract.supportContactPersons} />
            </div>
          )}
          {contract.serviceTimes && contract.serviceTimes.length > 0 && (
            <div className="column is-half">
              <span className="col-title">{t('serviceTimes')}</span>
              <ServiceTimes serviceTimes={contract.serviceTimes} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
