import { useState } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { Contract } from '../models/contract-management/ContractManagementModel';
import { Page } from '../models/pagination/Pagination';
import { useGetAllContracts } from '../react-query/ContractManagementSystemApi';

import { FetchError } from '../react-query/FetchError';

export default function useGetContractComponent(options?: UseQueryOptions<Page<Contract>, FetchError>) {
  const [contractFilter, setContractFilter] = useState<string>('');

  const { data, isLoading, isError } = useGetAllContracts(
    {
      ...options,
      enabled: contractFilter != null && contractFilter.length > 0,
      gcTime: 0,
    },
    {
      query: contractFilter,
      size: 10,
      page: 0,
    },
  );

  return { data, isLoading, isError, filter: contractFilter, setFilter: setContractFilter };
}
