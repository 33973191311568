/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable guard-for-in */

export function queryStringFromObject(obj: any, queryString = '') {
  const urlSearchParams = new URLSearchParams(queryString);

  if (obj === undefined) {
    return '';
  }

  for (const key in obj) {
    const value = obj[key] instanceof String ? obj[key].trim() : obj[key];
    if (value && value.length > 0) {
      if (Array.isArray(value)) {
        urlSearchParams.set(key, value.join(','));
      } else {
        urlSearchParams.set(key, value);
      }
    } else {
      urlSearchParams.delete(key);
    }
  }

  return urlSearchParams.toString();
}
