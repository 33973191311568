import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useId } from "react";
import { ActorKind } from "../../models/actor/Actor";
import './AutogeneratedTicketInformation.scss';
import Tooltip from "../../ui/tooltip/Tooltip";
import { useTranslationText } from "../../translation/TranslationHooks";

interface AutogeneratedTicketInformationProps {
  actorKind: ActorKind;
  showInformationText?: boolean;
}

function AutogeneratedTicketInformation({ actorKind, showInformationText = false } : AutogeneratedTicketInformationProps) {
  const isAutogeneratedTicket = actorKind !== 'USER'
  const { t } = useTranslationText('tickets');
  const autogeneratedTicketText = t('autogeneratedTicket')
  const anchorId = useId()

  return (
    <>
      { isAutogeneratedTicket &&
        <div className="automatically-generated is-fullwidth">
          <span id={anchorId} className="icon">
            <FontAwesomeIcon icon={faNetworkWired}/>
          </span>
          { showInformationText ?
            <p className="label">{autogeneratedTicketText}</p>
            :
            <Tooltip anchorId={anchorId} content={autogeneratedTicketText} />
          }
        </div>
      }
    </>
  )
}

export default AutogeneratedTicketInformation