import React from 'react';
import { DecodedValueMap } from 'use-query-params';
import { keepPreviousData } from '@tanstack/react-query';
import { TicketState } from '../../models/operation/TicketStatesModel';
import { TicketQuery, TicketQueryParamConfigModel } from '../../models/operation/TicketQuery';
import { TicketingItem } from '../item/TicketingItem';
import Collapsible from '../../ui/collapsible/Collapsible';
import { useGetAllTickets } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import Pagination from '../../ui/pagination/Pagination';
import { DEFAULT_PAGE } from '../../models/pagination/Pagination';
import { FetchError } from '../../react-query/FetchError';

interface CollapsibleElementProps {
  state: TicketState;
  query: DecodedValueMap<TicketQueryParamConfigModel>;
  statesForMenu: TicketState[];
  getStateFetchError: FetchError | null;
  isStatesLoading: boolean;
  handlePageChange: (state: TicketState, page: number) => void;
  page?: number | null;
}

export function CollapsibleElement({
  state,
  query,
  statesForMenu,
  getStateFetchError,
  isStatesLoading,
  handlePageChange,
  page,
}: CollapsibleElementProps) {
  const {
    data: pageableTickets,
    isLoading,
    error: getAllTicketsFetchError,
  } = useGetAllTickets({ placeholderData: keepPreviousData }, {
    ...query,
    states: [state.key],
    page: page ?? DEFAULT_PAGE,
    pagePerState: undefined,
  } as TicketQuery);

  const { t } = useTranslationText('tickets');

  const errors = useErrorsCollector([
    {
      fetchError: getStateFetchError,
      errorText: '404_ticketsStates',
    },
    {
      fetchError: getAllTicketsFetchError,
      errorText: '404_ticketingSystem',
    },
  ]);

  return (
    <Collapsible
      collapsibleKey={`collapsible-${state.key}`}
      title={t(state.translationKey)}
      badgeCount={pageableTickets?.totalElements ?? 0}
      initialExpanded
    >
      <LoadingSpinner isLoading={isLoading || isStatesLoading} errors={errors}>
        <div className="tickets" data-role={`${state.key}-tickets`}>
          {pageableTickets && pageableTickets.content.length > 0 ? (
            pageableTickets.content?.map((ticket, index) => (
              <TicketingItem
                key={`${state.key}-${ticket.id}`}
                index={index}
                ticket={ticket}
                statesForMenu={statesForMenu}
              />
            ))
          ) : (
            <p>{t('noTicketsFound')}</p>
          )}
        </div>
      </LoadingSpinner>
      <Pagination
        currentPage={pageableTickets?.number ?? 0}
        totalPages={pageableTickets?.totalPages ?? 0}
        handleOnPageChange={(newPage: number) => handlePageChange(state, newPage)}
        size="is-small"
      />
    </Collapsible>
  );
}
