import classNames from 'classnames';
import React, { ComponentType, useEffect, useState } from 'react';
import { ActionMeta, GroupBase, MultiValue, MultiValueRemoveProps } from 'react-select';
import { AsyncPaginate, Response } from 'react-select-async-paginate';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { Control, Field, Label } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import { JSXSelectOption } from '../jsx-search-dropdown/JSXSearchDropdown';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  filterSelectOptionLabel,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

interface MultiJSXSearchDropdownProps<T> {
  dataRole: string;
  isError?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoading?: boolean;
  isLoadingMessage?: string;
  onChange: (selectedOptions: MultiValue<JSXSelectOption>, action: ActionMeta<JSXSelectOption>) => void;
  onBlur?: () => void;
  placeholder?: string;
  values?: T[];
  onInputChange?: (value: string) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T[]) => MultiValue<JSXSelectOption>;
  requiredPermission: UserPermission;
  hideDropDownButton?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  loadOptions: (searchInput: string) => Promise<Response<JSXSelectOption, GroupBase<JSXSelectOption>, any>>;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  filterOption?: (option: FilterOptionOption<JSXSelectOption>, inputValue: string) => boolean;
  size?: BulmaSize;
}

export default function MultiJSXSearchDropdown<T>({
  dataRole,
  isError,
  label,
  noOptionsMessage,
  isLoading,
  isLoadingMessage,
  onChange,
  placeholder,
  values,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  hideDropDownButton = false,
  isSearchable,
  openMenuOnClick = false,
  loadOptions,
  onBlur,
  disabled,
  hideSelectedOptions = false,
  filterOption,
  size = 'is-normal',
}: MultiJSXSearchDropdownProps<T>): React.ReactElement<MultiJSXSearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<MultiValue<JSXSelectOption>>([]);
  const { hasPermission } = useAuthUser();

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  function handleChange(selectedOptions: MultiValue<JSXSelectOption>, action: ActionMeta<JSXSelectOption>) {
    onChange(selectedOptions, action);
  }

  useEffect(() => {
    if (values && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(values));
    } else {
      setSelectOption([]);
    }
  }, [mapValueToSelectOption, values]);

  return (
    <Field data-role={dataRole}>
      {label && <Label htmlFor={label}>{label}</Label>}
      <Control>
        <AsyncPaginate<JSXSelectOption, GroupBase<JSXSelectOption>, any, true>
          className={classNames('aos-multiple-select', size, {
            'is-error': isError,
            'is-closed': menuIsClosed,
          })}
          classNamePrefix="react-select"
          loadingMessage={() => isLoadingMessage}
          onInputChange={onInputChange}
          isLoading={isLoading}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebbaren Fehler
          onChange={handleChange as any}
          onBlur={onBlur}
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={selectOption}
          isDisabled={disabled || !hasPermission(requiredPermission)}
          loadOptions={loadOptions}
          isMulti
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={hideSelectedOptions}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          components={{
            MultiValueRemove: createCustomMultiValueRemove(size) as
              | ComponentType<MultiValueRemoveProps<JSXSelectOption, true, GroupBase<JSXSelectOption>>>
              | undefined,
            ...(!hideDropDownButton ? { DropdownIndicator: null } : { DropdownIndicator }),
          }}
          filterOption={filterOption ?? filterSelectOptionLabel}
          styles={multiStyles}
          classNames={multiClassNames<JSXSelectOption>()}
          unstyled
        />
      </Control>
    </Field>
  );
}
