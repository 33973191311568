import dayjs from 'dayjs';
import WidgetWrapper from '../../WidgetWrapper';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { useGetKnowledgeBaseArticles } from '../../../../react-query/KnowledgeBaseApi';
import WidgetArticleView from '../widget-content/WidgetArticleView';
import DateFormats from '../../../../models/date-formats/DateFormats';

export default function HighlightedArticles() {
  const { t } = useTranslationText('knowledgeBases');
  const {
    data: articles = [],
    isLoading,
    isError,
  } = useGetKnowledgeBaseArticles({ limit: 5, sort: 'updated_at:DESC', highlighted: true });
  return (
    <WidgetWrapper
      title={t('highlightedArticles')}
      isLoading={isLoading}
      isError={isError}
      noContent={t('noHighlightedArticles')}
    >
      {articles.map((article) => (
        <WidgetArticleView
          key={article.id}
          id={article.id}
          title={article.title}
          subTitle={dayjs(article.updatedAt).format(DateFormats.FULLDATE_MONTHTEXT_HOURS_MINS)}
        />
      ))}
    </WidgetWrapper>
  );
}
