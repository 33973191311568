/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Table as AosTable } from '@aos/styleguide-react';
import { TranslationSection } from '../../graphql/TranslationSection';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SortState } from '../table-sort/TableSort';

export interface TableColumn {
  translationKey: string;
  columnName?: string;
  property: string;
  disableSort?: boolean;
  width?: string;
  visible?: boolean;
}

interface TableHeaderProps {
  onSortChange?: (property: string, currentSort: string) => void;
  columns: TableColumn[];
  translationSection: TranslationSection;
  currentSort: string | undefined;
}

function getSortState(currentSortState: string | undefined, property: string) {
  const sortedPropertyArray = currentSortState?.split(':');

  const isVariableSorted = sortedPropertyArray?.[0] === property;

  let sortState;
  if (isVariableSorted && sortedPropertyArray) {
    sortState = [...sortedPropertyArray].at(1);
  } else {
    sortState = SortState.None;
  }

  switch (sortState) {
    case SortState.Ascending:
      return 'asc';
    case SortState.Descending:
      return 'desc';
    default:
      return undefined;
  }
}

function TableHeader({ translationSection, columns, onSortChange = () => {}, currentSort }: TableHeaderProps) {
  const { t } = useTranslationText(translationSection);

  return (
    <AosTable.Header>
      <AosTable.Row>
        {columns
          .filter((column) => column.visible !== false)
          .map((column) =>
            !column.disableSort ? (
              <AosTable.HeaderCell
                hasSorting
                sortState={getSortState(currentSort, column.property)}
                onClick={() => onSortChange(column.property, currentSort?.split(':')[1]!)}
                key={column.property}
              >
                {column.columnName ?? t(column.translationKey)}
              </AosTable.HeaderCell>
            ) : (
              <AosTable.HeaderCell key={column.property}>
                {column.columnName ?? t(column.translationKey)}
              </AosTable.HeaderCell>
            ),
          )}
      </AosTable.Row>
    </AosTable.Header>
  );
}

export default TableHeader;
