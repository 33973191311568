import { ControllerRenderProps, FormState } from 'react-hook-form';
import { useState } from 'react';
import { MultiValue } from 'react-select';
import { CreateContractorModel } from '../../../models/contract-management/ContractorModel';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../ui/text-input/TextInput';
import useGetAllUsers, { loadUserOptions } from '../../../react-query/getUsers';
import { mapUsersToOptions } from '../../../models/operation/TicketFunctions';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import MultiSearchDropdown from '../../../ui/multi-search-dropdown-paginated/MultiSearchDropdownPaginated';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import useCustomFetch from '../../../react-query/CustomFetch';

interface ContractorInputProps {
  field: ControllerRenderProps<CreateContractorModel, any>;
  formState: FormState<CreateContractorModel>;
  t: (key: string) => string;
  disabled?: boolean;
}

export function CompanyNameInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.companyName}>
      <TextInput
        placeholder={t('companyNameInputPlaceholder')}
        dataRole="contractor-company-name"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.companyName}
        label={t('companyNameLabel')}
        isRequired
        disabled={disabled}
      />
    </FormFieldWrapper>
  );
}

export function StreetInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.street}>
      <TextInput
        placeholder={t('streetPlaceholder')}
        dataRole="contractor-street"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.street}
        label={t('streetLabel')}
        disabled={disabled}
        isRequired
      />
    </FormFieldWrapper>
  );
}

export function HouseNumberInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.houseNumber}>
      <TextInput
        placeholder={t('houseNumberPlaceholder')}
        dataRole="contractor-house-number"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.houseNumber}
        label={t('houseNumberLabel')}
        disabled={disabled}
        isRequired
      />
    </FormFieldWrapper>
  );
}
export function PostalCodeInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.postalCode}>
      <TextInput
        placeholder={t('postalCodePlaceholder')}
        dataRole="contractor-postal-code"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.postalCode}
        label={t('postalCodeLabel')}
        disabled={disabled}
        isRequired
      />
    </FormFieldWrapper>
  );
}
export function CityInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.city}>
      <TextInput
        placeholder={t('cityPlaceholder')}
        dataRole="contractor-city"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.city}
        label={t('cityLabel')}
        disabled={disabled}
        isRequired
      />
    </FormFieldWrapper>
  );
}

export function PhoneNumberInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.phoneNumber}>
      <TextInput
        placeholder={t('phoneNumberPlaceholder')}
        dataRole="contractor-phone-number"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.phoneNumber}
        label={t('phoneNumberLabel')}
        type="tel"
        disabled={disabled}
      />
    </FormFieldWrapper>
  );
}

export function FaxNumberInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.faxNumber}>
      <TextInput
        placeholder={t('faxNumberPlaceholder')}
        dataRole="contractor-fax-number"
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.faxNumber}
        label={t('faxNumberLabel')}
        type="tel"
        disabled={disabled}
      />
    </FormFieldWrapper>
  );
}

export function EmailsInput({ field, formState, t, disabled }: ContractorInputProps) {
  return (
    <FormFieldWrapper error={formState.errors?.email}>
      <TextInput
        placeholder={t('emailPlaceholder')}
        dataRole="contractor-email"
        onValueChange={field.onChange}
        value={field.value || ''}
        error={formState.errors?.email}
        label={t('emailLabel')}
        type="email"
        disabled={disabled}
      />
    </FormFieldWrapper>
  );
}

export function AssigneeIdsInput({ field, formState, t, disabled }: ContractorInputProps) {
  const [isError, setIsError] = useState(false);
  const customFetch = useCustomFetch();

  const { data: allUsers } = useGetAllUsers(field.value, {
    enabled: field.value.length > 0, // returns all users if no User-Id's are provided
  });

  function handleOnChange(selectedOptions: MultiValue<ReactSelectOption>) {
    field.onChange(selectedOptions.map((user) => user.value));
  }

  const loadOptions = (searchInput: string, prev?: ReactSelectOption[]) =>
    loadUserOptions(searchInput, prev, setIsError, customFetch);

  return (
    <FormFieldWrapper error={formState.errors?.assigneeIds}>
      <MultiSearchDropdown
        dataRole="assignee-select"
        onChange={handleOnChange}
        requiredPermission={UserResourcePermissions.Contractor.CreateOrUpdate}
        loadOptions={loadOptions}
        values={allUsers?.content}
        isError={isError}
        noOptionsMessage={t('noSuggestionsUsers')}
        isLoadingMessage={t('usersAreLoading')}
        mapValueToSelectOption={mapUsersToOptions}
        isSearchable
        label={t('assignedUsers')}
        openMenuOnClick
        disabled={disabled}
        placeholder={t('assignedUsersPlaceholder')}
      />
    </FormFieldWrapper>
  );
}
