import { TicketSeverities } from '../../../models/operation/TicketSeveritiesModel';
import { TicketStates } from '../../../models/operation/TicketStatesModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import RichTextContent from '../../../ui/rich-text-content/RichTextContent';
import './HistoryItem.scss';

interface UpdatedHistoryEntry {
  payload: any;
  testId: number;
  severities: TicketSeverities;
  states: TicketStates;
}

function UpdatedHistoryComponent({ payload, testId, severities, states }: UpdatedHistoryEntry) {
  const { t } = useTranslationText('histories');
  const { t: tTickets } = useTranslationText('tickets');
  const { oldValue, newValue, fieldTranslationKey } = payload;

  const updateDetails = (value: string) => {
    switch (fieldTranslationKey) {
      case 'SEVERITY':
        return <span>{tTickets(severities?.find((severity) => severity.key === value)?.translationKey || '')}</span>;
      case 'ASSIGNED_COMPONENT':
        return <span>{value || t('nothing')}</span>;
      case 'STATE':
        return <span>{tTickets(states?.find((state) => state.key === value)?.translationKey || '')}</span>;
      case 'TASK_STATE':
        return <span>{tTickets(value)}</span>;
      case 'DESCRIPTION':
        return <RichTextContent value={value} />;
      default:
        return !value || value === '[]' ? <span>{t('nothing')}</span> : value;
    }
  };

  return (
    <>
      <span className="history-value" data-role={`${testId}-oldValue`}>
        {updateDetails(oldValue)}
      </span>
      <span className="history-arrow">→</span>
      <span className="history-value" data-role={`${testId}-newValue`}>
        {updateDetails(newValue)}
      </span>
    </>
  );
}

interface PayloadFactoryProps {
  payload: any;
  testId: number;
  severities: TicketSeverities;
  states: TicketStates;
}

export default function PayloadFactory({ payload, testId, severities, states }: PayloadFactoryProps) {
  if (payload.newValue !== undefined) {
    return <UpdatedHistoryComponent payload={payload} testId={testId} severities={severities} states={states} />;
  }
  return <></>;
}
