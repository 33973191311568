/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback } from 'react';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextArea from '../../ui/text-area/TextArea';
import TextInput from '../../ui/text-input/TextInput';
import SingleSelectDropdown, { ReactSelectOption } from '../../ui/single-select-dropdown/SingleSelectDropdown';
import { AlertingRulesComponentStatus, ComponentStatus } from '../../models/operation/ComponentModel';
import { SwitchInput } from '../../ui/switch/SwitchInput';
import { Criticality } from '../../components/active-alert-overview/Criticality';

interface AlertingRulesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
  index: number;
  onRemove: () => void;
}

export default function AlertingRulesForm({ form, index, onRemove }: AlertingRulesFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { t: componentsTranslation } = useTranslationText('components');
  const { t: alertsTranslation } = useTranslationText('alerts');

  const { control, formState } = form;

  const QueryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.query`> }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.query}>
        <TextArea
          placeholder={t('alertingRuleQueryPlaceholder')}
          dataRole="component-type-alerting-rule-query"
          onValueChange={field.onChange}
          value={field.value}
          error={formState?.errors?.alertingRules?.at?.(index)?.query}
          label={t('alertingRuleQueryLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.alertingRules?.at?.(index)?.query],
  );

  const TitleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.title`> }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.title}>
        <TextInput
          placeholder={t('alertingRuleTitlePlaceholder')}
          dataRole="component-type-alerting-rule-title"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.alertingRules?.at?.(index)?.title}
          label={t('alertingRuleTitle')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.alertingRules?.at?.(index)?.title],
  );

  const toSelectOption = (opt: ComponentStatus): ReactSelectOption<ComponentStatus> => ({
    label: componentsTranslation(`status-${opt.toLowerCase()}`),
    value: opt,
  });

  const StatusInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.status`> }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.status}>
        <SingleSelectDropdown
          placeholder={t('alertingRuleStatusPlaceholder')}
          dataRole="component-type-alerting-rule-status"
          onChange={(opt) => field.onChange(opt?.value)}
          options={AlertingRulesComponentStatus.map(toSelectOption)}
          value={AlertingRulesComponentStatus.includes(field.value) ? toSelectOption(field.value) : undefined}
          isError={!!formState.errors?.alertingRules?.at?.(index)?.status}
          requiredPermission={UserResourcePermissions.ComponentType.Update}
          label={t('alertingRuleStatus')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.alertingRules?.at?.(index)?.status],
  );

  const CriticalityOptions = [Criticality.NONE, Criticality.MINOR, Criticality.MAJOR, Criticality.CRITICAL];

  const toSelectOptionCriticality = (opt: Criticality): ReactSelectOption<Criticality> => ({
    label: alertsTranslation(`criticality-${opt.toLowerCase()}`),
    value: opt,
  });

  const CriticalityInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.criticality`> }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.criticality}>
        <SingleSelectDropdown
          placeholder={t('criticalityPlaceholder')}
          dataRole="component-type-alerting-rule-criticality"
          onChange={(opt) => field.onChange(opt?.value)}
          options={CriticalityOptions.map(toSelectOptionCriticality)}
          value={toSelectOptionCriticality(field.value)}
          isError={!!formState.errors?.alertingRules?.at?.(index)?.criticality}
          requiredPermission={UserResourcePermissions.ComponentType.Update}
          label={t('alertingRuleCriticality')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.alertingRules?.at?.(index)?.criticality],
  );

  const TicketGenerationEnabled = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.ticketGenerationEnabled`>;
    }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.ticketGenerationEnabled}>
        <SwitchInput
          checked={field.value}
          onChange={(checked) => field.onChange(checked)}
          isError={!!formState.errors?.alertingRules?.at?.(index)?.ticketGenerationEnabled}
          label={t('ticketGenerationEnabledLabel')}
          isRequired
          id={`ticketGenerationEnabled${index}`}
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.alertingRules?.at?.(index)?.notifyAfterSeconds],
  );
  const NotifyAfterInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.notifyAfterSeconds`>;
    }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.notifyAfterSeconds}>
        <TextInput
          placeholder={t('alertingRuleNotifyAfterPlaceholder')}
          dataRole="component-type-alerting-rule-notifyAfter"
          onValueChange={(valueInMinutes) => field.onChange(parseInt(valueInMinutes, 10) * 60)}
          value={field.value !== undefined ? `${Math.floor(field.value / 60)}` : undefined}
          error={formState.errors?.alertingRules?.at?.(index)?.notifyAfterSeconds}
          label={t('alertingRuleNotifyAfterLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.alertingRules?.at?.(index)?.notifyAfterSeconds],
  );

  const DescriptionInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `alertingRules.${typeof index}.description`> }) => (
      <FormFieldWrapper error={formState.errors?.alertingRules?.at?.(index)?.description}>
        <TextArea
          placeholder={t('alertingRuleDescriptionPlaceholder')}
          dataRole="component-type-alerting-rule-description"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.alertingRules?.at?.(index)?.description}
          label={t('alertingRuleDescriptionLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.alertingRules?.at?.(index)?.description],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="alertingRules"
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.ComponentType.CreateOrUpdate}
    >
      <Controller name={`alertingRules.${index}.title`} control={control} render={TitleInput} />
      <Controller name={`alertingRules.${index}.query`} control={control} render={QueryInput} />
      <Controller name={`alertingRules.${index}.status`} control={control} render={StatusInput} />
      <Controller name={`alertingRules.${index}.criticality`} control={control} render={CriticalityInput} />
      <Controller
        name={`alertingRules.${index}.ticketGenerationEnabled`}
        control={control}
        render={TicketGenerationEnabled}
      />
      <Controller name={`alertingRules.${index}.notifyAfterSeconds`} control={control} render={NotifyAfterInput} />
      <Controller name={`alertingRules.${index}.description`} control={control} render={DescriptionInput} />
    </ListItemWrapper>
  );
}
