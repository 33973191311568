import React from 'react';
import { useTranslationText } from '../translation/TranslationHooks';
import { FetchError } from '../react-query/FetchError';

interface FetchErrors {
  fetchError: FetchError | null;
  errorText: string | React.ReactNode;
}

export default function useErrorsCollector(errors: FetchErrors[]): React.ReactNode[] {
  const { t: tError } = useTranslationText('errorTexts');

  return errors
    ?.map((error) => {
      if (error.fetchError !== null) {
        if (typeof error.errorText === 'string') {
          return tError(error.errorText);
        }
        return error.errorText;
      }
      return null;
    })
    .filter((err) => err);
}
