import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { KnowledgeBaseArticleComment } from '../../../../../models/knowledge-base/KnowledgeBaseModel';
import './ArticleCommentListingItem.scss';
import DateFormats from '../../../../../models/date-formats/DateFormats';

interface ArticleCommentListingItemProps {
  articleComment: KnowledgeBaseArticleComment;
}

function useGetCommentTranslation(articleComment: KnowledgeBaseArticleComment) {
  return (
    <Trans
      i18nKey="comments.userCommentOnDate.text"
      values={{
        author: articleComment.author,
        date: `${dayjs(articleComment.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)} `,
      }}
      components={{ 1: <span className="highlight" /> }}
    />
  );
}

export default function ArticleCommentListingItem({ articleComment }: ArticleCommentListingItemProps) {
  return (
    <div className="article-comment-listing-item">
      <div className="article-comment-header">{useGetCommentTranslation(articleComment)}</div>
      <div className="article-comment-text">{parse(articleComment.text ?? '')}</div>
    </div>
  );
}
