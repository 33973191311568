import classNames from 'classnames';
import React, { ComponentType, useEffect, useState } from 'react';
import Select, { GroupBase, MultiValue, MultiValueRemoveProps } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Control, Field, Label } from '@aos/styleguide-react';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import { JSXSelectOption } from '../jsx-search-dropdown/JSXSearchDropdown';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  filterSelectOptionLabel,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

export function sortByLabel(o1: JSXSelectOption, o2: JSXSelectOption) {
  return o1.labelText.localeCompare(o2.labelText);
}

interface MultiJSXSelectDropdownProps {
  dataRole: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  options: JSXSelectOption[];
  onChange: (selectedOptions: MultiValue<JSXSelectOption>) => void;
  onBlur?: () => void;
  placeholder?: string;
  mappedValues?: MultiValue<JSXSelectOption>;
  inputValue?: string;
  formatOptionLabel?: any;
  isDropdownIndicatorEnabled?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  onInputChange?: (value: string) => void;
  disabled?: boolean;
  requiredPermission?: UserPermission;
  filterOption?: (option: FilterOptionOption<JSXSelectOption>, inputValue: string) => boolean;
  size?: BulmaSize;
}

export default function MultiJSXSelectDropdown({
  dataRole,
  isError,
  isLoading,
  label,
  noOptionsMessage,
  isLoadingMessage,
  options,
  onChange,
  onBlur,
  placeholder,
  mappedValues,
  formatOptionLabel,
  isDropdownIndicatorEnabled = true,
  isSearchable,
  openMenuOnClick = false,
  inputValue,
  onInputChange = () => {},
  disabled,
  requiredPermission,
  filterOption,
  size = 'is-normal',
}: MultiJSXSelectDropdownProps): React.ReactElement<MultiJSXSelectDropdownProps> {
  const [selectOption, setSelectOption] = useState<MultiValue<JSXSelectOption>>();
  const { hasPermission } = useAuthUser();

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  function handleChange(newValue: MultiValue<JSXSelectOption>) {
    onChange(newValue);
  }

  useEffect(() => {
    if (mappedValues && mappedValues.length > 0) {
      setSelectOption(mappedValues);
    } else {
      setSelectOption([]);
    }
  }, [mappedValues]);

  const isDisabled = disabled || (requiredPermission && !hasPermission(requiredPermission));

  return (
    <Field data-role={dataRole}>
      {label && (
        <Label htmlFor={label} size={size}>
          {label}
        </Label>
      )}
      <Control size={size}>
        <Select
          className={classNames('aos-multiple-select', size, {
            'is-error': isError,
            'is-closed': menuIsClosed,
          })}
          classNamePrefix="react-select"
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadingMessage={() => isLoadingMessage}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebaren Fehler
          onChange={handleChange as any}
          onBlur={onBlur}
          placeholder={placeholder}
          inputId="select-input"
          onInputChange={onInputChange}
          inputValue={inputValue}
          formatOptionLabel={formatOptionLabel}
          hideSelectedOptions={false}
          value={selectOption}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          filterOption={filterOption ?? filterSelectOptionLabel}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          isMulti
          unstyled
          components={{
            MultiValueRemove: createCustomMultiValueRemove(size) as
              | ComponentType<MultiValueRemoveProps<JSXSelectOption, true, GroupBase<JSXSelectOption>>>
              | undefined,
            ...(!isDropdownIndicatorEnabled ? { DropdownIndicator: null } : { DropdownIndicator }),
          }}
          styles={multiStyles}
          classNames={multiClassNames<JSXSelectOption>()}
        />
      </Control>
    </Field>
  );
}
