import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import AttributesForm from './AttributesForm';

interface AttributesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function AttributesFormCollapsible({ form }: AttributesFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `attributes`,
  });

  return (
    <Collapsible
      collapsibleKey="componentTypesAttributes"
      initialExpanded
      title={t('attributes')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ name: '', valueSelector: { query: '', label: '' } })}
      hasNoItems={fields.length === 0}
      addPermission={UserResourcePermissions.ComponentType.Create}
      dataRole="component-type-attribtues"
    >
      {fields.map((field, index: number) => (
        <AttributesForm form={form} key={field.id} index={index} onRemove={() => remove(index)} />
      ))}
    </Collapsible>
  );
}
