import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import useYupLocal from '../../../translation/YupLocal';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { patchSupportContactPersonsValidation } from '../../../models/contract-management/ContractValidationSchema';
import { PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import {
  CreateSupportContactPersonModel,
  defaultSupportContactPerson,
  SupportContactPersonModel,
} from '../../../models/contract-management/SupportContactPersonModel';
import { SupportContactPersonDetails } from '../SupportContactPersonDetails';
import { ContractRole } from '../../../models/contract-management/ContractRole';
import { useGetContractRoles } from '../../../react-query/ContractManagementSystemApi';
import { EditSupportContactPerson } from '../../create-contract/CreateSupportContactPersonForm/EditSupportContactPerson';

function mapSupportContactPersonsToCreateSupportContactPersons(
  contactPersons: SupportContactPersonModel[]
): CreateSupportContactPersonModel[] {
  return contactPersons.map(({ id, ...rest }) => rest);
}

interface EditableSupportContactPersonsProps {
  currentContactPersons: SupportContactPersonModel[];
  onSubmit: (
    newValue: CreateSupportContactPersonModel[] | undefined,
    callback: (savedData: SupportContactPersonModel[] | undefined) => void
  ) => void;
  isSaving?: boolean;
}

interface ReadonlySupportContactPersonsProps {
  currentContactPersons: SupportContactPersonModel[];
  contractRoles: ContractRole[];
}

function ReadonlySupportContactPersons({
  currentContactPersons,
  contractRoles,
}: ReadonlySupportContactPersonsProps): JSX.Element {
  const { t } = useTranslationText('contractManagements');
  return (
    <Collapsible
      badgeCount={currentContactPersons.length}
      collapsibleKey="supportContactPerson"
      initialExpanded
      title={t('supportContactPerson')}
    >
      {currentContactPersons.map((contactPerson, index) => (
        <SupportContactPersonDetails
          key={contactPerson.id}
          supportContactPerson={contactPerson}
          contractRoles={contractRoles}
          index={index}
        />
      ))}
    </Collapsible>
  );
}

export default function EditableSupportContactPersons({
  currentContactPersons,
  onSubmit,
  isSaving,
}: EditableSupportContactPersonsProps) {
  const { t } = useTranslationText('contractManagements');
  const { yup } = useYupLocal();

  const {
    data: contractRoles,
    isLoading: isLoadingContractRoles,
    isError: isErrorContractRoles,
  } = useGetContractRoles();

  const form = useForm<PatchContractModel>({
    mode: 'onChange',
    resolver: yupResolver(patchSupportContactPersonsValidation(yup, t)),
    defaultValues: {
      supportContactPersons: mapSupportContactPersonsToCreateSupportContactPersons(currentContactPersons),
    },
  });

  const { getValues, control, formState, reset, trigger } = form;

  const { isValid } = formState;

  const {
    append,
    fields: newSupportContactPersonFields,
    remove,
  } = useFieldArray({
    control,
    name: 'supportContactPersons',
  });

  function onDiscard() {
    reset({ supportContactPersons: mapSupportContactPersonsToCreateSupportContactPersons(currentContactPersons) });
  }

  function onSave() {
    trigger('supportContactPersons')
      .then((isFormValid) => {
        if (isFormValid) {
          onSubmit(getValues('supportContactPersons'), (savedSupportContactPersons) => {
            if (savedSupportContactPersons) {
              reset({
                supportContactPersons: savedSupportContactPersons,
              });
            }
          });
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <LoadingSpinner isLoading={isLoadingContractRoles} errors={isErrorContractRoles}>
      <UserRoleCheck
        requiredPermission={UserResourcePermissions.Contract.Update}
        renderNoPermission={() => (
          <ReadonlySupportContactPersons
            currentContactPersons={currentContactPersons}
            contractRoles={contractRoles ?? []}
          />
        )}
      >
        <Collapsible
          badgeCount={newSupportContactPersonFields.length}
          collapsibleKey="supportContactPerson"
          initialExpanded
          dirtyState={formState.isDirty}
          discardBtnClicked={onDiscard}
          saveBtnClicked={onSave}
          isSaving={isSaving}
          isValid={isValid}
          title={t('supportContactPerson')}
          addBtnClicked={() => append(defaultSupportContactPerson)}
          hasNoItems={newSupportContactPersonFields.length === 0}
          addPermission={UserResourcePermissions.Contract.Update}
          dataRole="contact-persons"
        >
          <div className="editable-support-contact-persons ">
            {newSupportContactPersonFields.map((field, index) => (
              <EditSupportContactPerson
                form={form}
                key={field.id}
                index={index}
                onRemove={() => remove(index)}
                contractRoles={contractRoles}
              />
            ))}
          </div>
        </Collapsible>
      </UserRoleCheck>
    </LoadingSpinner>
  );
}
