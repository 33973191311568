import { useQueryClient } from '@tanstack/react-query';
import { useTranslationText } from '../../translation/TranslationHooks';
import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '../../react-query/MutationQueries';
import { SwitchInput } from '../../ui/switch/SwitchInput';
import { invalidateComponent } from '../../react-query/InvalidationQueries';

interface FavoriteComponentToggleProps {
  componentId: string;
  toggleState: boolean;
}

function FavoriteComponentToggle({ componentId, toggleState }: FavoriteComponentToggleProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslationText('components');

  const { mutate } = usePutMutation(MutationKey.PutFavoriteComponent, {
    onSuccess: () => {
      void invalidateComponent(queryClient, componentId);
    },
  });

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteFavoriteComponent, {
    onSuccess: () => {
      void invalidateComponent(queryClient, componentId);
    },
  });

  function handleOnChange(value: boolean) {
    if (value) {
      mutate({
        body: undefined,
        path: MutationPath.PutOrDeleteFavoriteComponent(componentId),
      });
    } else {
      deleteMutate({
        path: MutationPath.PutOrDeleteFavoriteComponent(componentId),
      });
    }
  }

  return (
    <SwitchInput
      id="favorite-component"
      onChange={handleOnChange}
      checked={toggleState}
      label={t('favoriteComponent')}
    />
  );
}

export default FavoriteComponentToggle;
