import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import { useMemo } from 'react';

interface TextInputProps {
  label?: string;
  onValueChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string | number;
  dataRole?: string;
  error?: FieldError;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  isRequired?: boolean;
  isUpdated?: boolean;
}

function TextInput({
  label,
  onValueChange,
  placeholder,
  readOnly,
  dataRole,
  value,
  error,
  type = 'text',
  isRequired = false,
  disabled,
  isUpdated,
}: TextInputProps) {
  const displayValue = useMemo(() => (value || !Number.isNaN(value) ? value : ''), [value]);

  return (
    <div className="field is-fullwidth">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div
        className={classNames('control', 'is-fullwidth', {
          'is-updated--success': isUpdated,
        })}
      >
        <input
          tabIndex={0}
          className={classNames('input', {
            'is-danger': error !== undefined,
          })}
          onChange={(event) => onValueChange?.(event.target.value)}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          value={displayValue}
          data-role={dataRole}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default TextInput;
