import React, { useEffect, useRef, useState } from 'react';
import './Dropdown.scss';
import classNames from 'classnames';

export interface DropdownItemContext {
  closeDropdown(): void;
  isActive: boolean;
}

export type DropdownItemsFactory = (context: DropdownItemContext) => JSX.Element[];

export interface DropdownProps {
  title: JSX.Element;
  renderItems: DropdownItemsFactory;
  isLoading?: boolean;
}

export function Dropdown({ renderItems, title, isLoading }: DropdownProps) {
  const [active, setActive] = useState(false);
  const dropdownMenu = useRef(null);

  useEffect(() => {
    const closeOpenMenus = (e: MouseEvent) => {
      if (dropdownMenu.current && active && !(dropdownMenu.current as any).contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', closeOpenMenus);

    return () => {
      document.removeEventListener('mousedown', closeOpenMenus);
    };
  }, [active]);

  const context = {
    closeDropdown: () => setActive(false),
    isActive: active,
  } as DropdownItemContext;

  const items = renderItems(context).map((item) => item);

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames({
        dropdown: true,
        'is-right': true,
        'is-active': active,
      })}
      ref={dropdownMenu}
    >
      <div className="dropdown-trigger">
        <button
          type="button"
          className={classNames('button', {
            'is-loading': isLoading,
          })}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setActive(!active)}
          data-role="dropdown-button"
        >
          {title}
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">{items}</div>
      </div>
    </div>
  );
}
