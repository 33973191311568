import { TicketSort } from '../models/operation/TicketQuery';

const NavigationRoutes = {
  Dashboard: '/dashboard',

  Status: '/status',

  ComponentsRoot: '/components',
  Components: '/components/overview',
  ComponentsMap: '/components/map',
  Reports: '/components/reports',
  Systems: '/components/systems',
  SystemId: (systemId: string) => `/components/systems/${systemId}`,

  AllComponentsWithDisplayName: (displayName: string) => `/components/overview?displayName=${displayName}`,
  Agents: '/components/agents',
  AgentId: (agentId: string) => `/components/agents/${agentId}`,
  ComponentsWithContractId: (contractId: string) =>
    `/components/overview?assignedContractId=${contractId}&relation=Tabellenansicht`,
  ComponentId: (componentId: string) => `/components/overview/${componentId}`,
  ComponentCreate: '/components/overview/create',

  ComponentTypes: '/components/componentTypes',
  ComponentTypeId: (componentTypeId: string) => `/components/componentTypes/${componentTypeId}`,

  ActiveAlertOverview: '/components/active-alerts',

  MaintenanceRoot: '/maintenance',
  MaintenanceOverview: '/maintenance/overview',
  MaintenanceRequests: '/maintenance/requests',
  MaintenanceRequestId: (maintenanceRequestId: string) => `/maintenance/requests/${maintenanceRequestId}`,
  CreateMaintenanceRequest: '/maintenance/requests/create',

  TicketsRoot: '/tickets',
  TicketsAll: '/tickets/overview',
  TicketsCurrent: '/tickets/current',
  TasksAll: '/tickets/tasks/overview',
  TaskId: (taskId: number) => `/tickets/tasks/overview/${taskId}`,
  AutomationRules: '/tickets/automation-rules',
  RuleCreate: '/tickets/automation-rules/create',
  TicketCurrentId: (ticketId: number) => `/tickets/current/${ticketId}`,
  TicketAllId: (ticketId: number) => `/tickets/overview/${ticketId}`,
  AllTicketsWithQuery: (query: string) => `/tickets/overview?query=${query}&sort=${TicketSort.Relevance}`,

  KnowledgeBaseRoot: '/knowledge-base',
  KnowledgeBaseDashboard: '/knowledge-base/dashboard?showWidget=1',
  KnowledgeBaseDashboardWithQuery: (query: string) => `/knowledge-base/dashboard?search=${query}&showWidget=0`,
  KnowledgeBaseArticle: '/knowledge-base/articles',
  KnowledgeBaseArticleCreate: '/knowledge-base/articles/create',
  KnowledgeBaseArticleId: (articleId: string) => `/knowledge-base/articles/${articleId}`,

  ContractManagementRoot: '/contracts',
  AllContracts: '/contracts/overview',
  AllContractsWithQuery: (query: string) => `/contracts/overview?query=${query}`,
  ContractId: (contractId: string) => `/contracts/overview/${contractId}`,

  ContractCreate: '/contracts/create',
  AllContractors: '/contracts/contractors',
  ContractorCreate: '/contracts/contractors/create',
  ContractorId: (contractorId: string) => `/contracts/contractors/${contractorId}`,
  User: '/user',
} as const;

export default NavigationRoutes;
