import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { GroupBase, SingleValue, StylesConfig } from 'react-select';
import { AsyncPaginate, Response } from 'react-select-async-paginate';
import '../search-dropdown/SearchDropdown.scss';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';

export interface ReactSelectOption {
  readonly label: string;
  readonly value: any;
}

interface SearchDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  onChange: (selectedOption: SingleValue<ReactSelectOption>) => void;
  placeholder?: string;
  value?: T;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T) => SingleValue<ReactSelectOption>;
  requiredPermission: UserPermission;
  hideDropDownButton?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  isSmall?: boolean;
  isRequired?: boolean;
  loadOptions: (searchInput: string) => Promise<Response<ReactSelectOption, GroupBase<ReactSelectOption>, any>>;
  isDisabled?: boolean;
}

export default function SearchDropdown<T>({
  dataRole,
  isError,
  label,
  noOptionsMessage,
  isLoadingMessage,
  onChange,
  placeholder,
  value,
  inputValue,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  hideDropDownButton = false,
  isClearable = false,
  isSearchable,
  openMenuOnClick = false,
  isSmall,
  isRequired = false,
  loadOptions,
  isDisabled = false,
}: SearchDropdownProps<T>): React.ReactElement<SearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<SingleValue<ReactSelectOption | undefined>>(undefined);
  const { hasPermission } = useAuthUser();

  function handleChange(newValue: SingleValue<ReactSelectOption>) {
    onChange(newValue);
  }

  useEffect(() => {
    if (value && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(value));
    } else {
      setSelectOption(undefined);
    }
  }, [mapValueToSelectOption, value]);

  const colourStyles: StylesConfig<SingleValue<ReactSelectOption>> = {
    menu: (styles) => ({ ...styles, display: value ? 'none' : 'block' }),
    dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  };

  return (
    <div className="field is-fullwidth" data-role={dataRole}>
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control is-fullwidth">
        <AsyncPaginate
          className={classNames('react-select-container', {
            'react-select-container-error': isError,
            'is-small': isSmall,
          })}
          classNamePrefix="react-select"
          styles={hideDropDownButton ? colourStyles : undefined}
          isClearable={isClearable}
          loadingMessage={() => isLoadingMessage}
          onInputChange={onInputChange}
          inputValue={inputValue}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => (inputValue?.length === 0 ? placeholder : noOptionsMessage)}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebaren Fehler
          onChange={handleChange as any}
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={selectOption}
          isDisabled={!hasPermission(requiredPermission) || isDisabled}
          loadOptions={loadOptions}
        />
      </div>
    </div>
  );
}
