import React from 'react';
import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { ReactSelectOption } from '../../search-dropdown/SearchDropdown';
import MultiSelectDropdown from '../../multi-select-dropdown/MultiSelectDropdown';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { useGetComponentTypes } from '../../../react-query/ComponentTypeApi';
import { ComponentType } from '../../../models/operation/ComponentTypeModel';

interface ComponentTypeFilterProps {
  label?: string;
  onChange: (types: string[]) => void;
  selectedComponentTypeIds?: (string | null)[] | null | undefined;
  size?: BulmaSize;
}

function mapToOption(componentTypesList?: ComponentType[]): ReactSelectOption[] {
  return (
    componentTypesList?.map((componentType) => ({
      value: componentType.id,
      label: componentType.name,
    })) ?? []
  );
}

export default function ComponentTypeFilter({
  label,
  onChange,
  selectedComponentTypeIds,
  size = 'is-small',
}: ComponentTypeFilterProps) {
  const {
    data: componentTypeIds,
    isLoading: areComponentTypesLoading,
    isError: isComponentTypesError,
  } = useGetComponentTypes();
  const { t } = useTranslationText('components');

  const handleOnChange = (newSelectedTypes: MultiValue<ReactSelectOption>) =>
    onChange(newSelectedTypes.map((componentType) => componentType.value));

  const getMappedValues = () =>
    mapToOption(componentTypeIds?.content.filter(({ id }) => selectedComponentTypeIds?.includes(id)));

  return (
    <MultiSelectDropdown
      dataRole="search-dropdown-component-types"
      onChange={handleOnChange}
      options={mapToOption(componentTypeIds?.content)}
      openMenuOnClick
      mappedValues={getMappedValues()}
      placeholder={t('componentTypePlaceholder')}
      isError={isComponentTypesError}
      isLoading={areComponentTypesLoading}
      label={label}
      size={size}
    />
  );
}
