import { useCallback } from 'react';
import { UseFormReturn, ControllerRenderProps, Controller } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { ContactPerson } from '../../../models/maintenance/ContactPerson';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import TextInput from '../../../ui/text-input/TextInput';

interface ContactPersonFormProps {
  contractorIndex: number;
  contactPersonIndex: number;
  onRemove: () => void;
  form: UseFormReturn<BaseMaintenance>;
  readonly: boolean;
}

export function ContactPersonForm({
  contractorIndex,
  contactPersonIndex,
  onRemove,
  form,
  readonly: readOnly,
}: ContactPersonFormProps) {
  const { control, formState } = form;
  const { t } = useTranslationText('maintenances');

  const getErrors = useCallback(
    (field: keyof ContactPerson) => {
      const involvedContractors = formState.errors?.involvedContractors;
      if (involvedContractors === undefined) {
        return involvedContractors;
      }

      const involvedContractor = involvedContractors[contractorIndex];
      if (involvedContractor === undefined) {
        return undefined;
      }

      const { contactPersons } = involvedContractor;
      if (contactPersons === undefined) {
        return undefined;
      }

      const contactPerson = contactPersons[contactPersonIndex];
      return contactPerson !== undefined ? contactPerson[field] : undefined;
    },
    [formState.errors?.involvedContractors, contactPersonIndex, contractorIndex]
  );

  const FirstNameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        BaseMaintenance,
        `involvedContractors.${typeof contractorIndex}.contactPersons.${typeof contactPersonIndex}.firstName`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('firstName')}>
        <TextInput
          label={t('firstName')}
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [getErrors, readOnly, t]
  );

  const LastNameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        BaseMaintenance,
        `involvedContractors.${typeof contractorIndex}.contactPersons.${typeof contactPersonIndex}.lastName`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('lastName')}>
        <TextInput
          label={t('lastName')}
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [getErrors, readOnly, t]
  );

  const EmailInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        BaseMaintenance,
        `involvedContractors.${typeof contractorIndex}.contactPersons.${typeof contactPersonIndex}.email`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('email')}>
        <TextInput label={t('email')} value={field.value} onValueChange={field.onChange} readOnly={readOnly} />
      </FormFieldWrapper>
    ),
    [getErrors, readOnly, t]
  );

  const PhoneInput = useCallback(
    ({
      field,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      field: ControllerRenderProps<
        BaseMaintenance,
        `involvedContractors.${typeof contractorIndex}.contactPersons.${typeof contactPersonIndex}.phone`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('phone')}>
        <TextInput label={t('phone')} value={field.value} onValueChange={field.onChange} readOnly={readOnly} />
      </FormFieldWrapper>
    ),
    [getErrors, readOnly, t]
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`invlovedContractors.${contractorIndex}.contactPersons`}
      index={contactPersonIndex}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      deleteDisabled={readOnly}
    >
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name={`involvedContractors.${contractorIndex}.contactPersons.${contactPersonIndex}.firstName`}
            control={control}
            render={FirstNameInput}
          />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name={`involvedContractors.${contractorIndex}.contactPersons.${contactPersonIndex}.lastName`}
            control={control}
            render={LastNameInput}
          />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name={`involvedContractors.${contractorIndex}.contactPersons.${contactPersonIndex}.email`}
            control={control}
            render={EmailInput}
          />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name={`involvedContractors.${contractorIndex}.contactPersons.${contactPersonIndex}.phone`}
            control={control}
            render={PhoneInput}
          />
        </div>
      </div>
    </ListItemWrapper>
  );
}
