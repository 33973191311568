import './Components.scss';
import { MultiValue } from 'react-select';
import { faEllipsisH, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { NumberParam, StringParam, withDefault } from 'use-query-params';
import { Link } from 'react-router-dom';
import Pagination from '../ui/pagination/Pagination';
import useGetComponentsSummarized, { pathForAllComponentsWithFilter } from '../react-query/ComponentApi';
import { useRelationTypeTranslations, useTranslationText } from '../translation/TranslationHooks';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import ActionBar from '../ui/action-bar/ActionBar';
import { Dropdown, DropdownItemContext } from '../ui/dropdown/Dropdown';
import { MutationKey, MutationPath, useDeleteMutation, usePostMutation } from '../react-query/MutationQueries';
import { DiscoveryCollection } from '../models/operation/DiscoveryModel';
import ComponentTable from './component-table/ComponentTable';
import { RelationTypeView, SummarizedComponent } from '../models/operation/ComponentModel';
import useGetRelations from '../react-query/ComponentRelationApi';
import SelectDropdown, { SelectDropdownOption } from '../ui/select-dropdown/SelectDropdown';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import { useGetComponentRelationTypes } from '../react-query/RelationTypeApi';
import { RelationType } from '../models/operation/RelationTypeModel';
import UserRoleCheck, { useAuthUser } from '../auth/UserRoleCheck';
import { DebouncedSearchBar } from '../ui/filter/search-bar/SearchBar';
import AllComponentFilter from './all-component-filter/AllComponentFilter';
import useToggle from '../custom-hooks/useToggle';
import { ComponentFilter, componentFilterQueryParamConfigMap, ComponentSort } from '../models/operation/ComponentQuery';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../models/pagination/Pagination';
import { getNextSortState, SortState } from '../ui/table-sort/TableSort';
import ComponentsMultiSelectContext from './ComponentsMultiSelectContext';
import MultiSelectContainer from '../ui/multiselect/multiselect-container/MultiSelectContainer';
import ComponentMultiSelectDropdown from './component-multiselect-dropdown/ComponentMultiSelectDropdown';
import MultiSelectDropdown, { ReactSelectOption } from '../ui/multi-select-dropdown/MultiSelectDropdown';
import {
  allColumnOptions,
  defaultColumnConfig,
  mapColumnConfigsToOptions,
  UserOverviewColumnConfig,
  UserOverviewColumnType,
  UserOverviewConfig,
} from './component-table/UserOverviewConfig';
import FilterMenuButton from '../ui/filter/FilterMenuButton';
import Tooltip from '../ui/tooltip/Tooltip';
import { useGetUserOverviewConfig } from '../react-query/MonitoringSystemApi';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';
import useQueryParamsWithPageReset from '../custom-hooks/useQueryParamsWithPageReset';
import { addSelectedTenantsToPath } from '../react-query/CustomFetch';

const useExpand = (item: SummarizedComponent, relationType?: string): SummarizedComponent[] | undefined => {
  const { data } = useGetRelations(
    item.id,
    { type: relationType },
    { refetchOnWindowFocus: false, refetchInterval: false },
  );
  if (!data) {
    return undefined;
  }
  return data.content.filter((e) => !!e.source).map((e) => e.source as SummarizedComponent);
};

export default function Components() {
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { forward } = useRelationTypeTranslations();
  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);
  const { hasPermission } = useAuthUser();

  const [query, setQuery] = useQueryParamsWithPageReset({
    ...componentFilterQueryParamConfigMap,
    relation: withDefault(StringParam, RelationTypeView.PART_OF),
    sort: withDefault(StringParam, ComponentSort.DisplayNameAsc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });

  const [componentFilter, setComponentFilter] = useState<ComponentFilter>();
  const [componentParentsFilter, setComponentParentsFilter] = useState<ComponentFilter>();
  const { data: relationTypes } = useGetComponentRelationTypes({ refetchOnWindowFocus: false, refetchInterval: false });

  const { currentTenant } = useCurrentTenant();

  const selectedTenants = [currentTenant];

  const {
    isLoading,
    data: componentsPaged,
    isError: isGetComponentError,
    refetch,
  } = useGetComponentsSummarized(
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      enabled: !!componentFilter,
    },
    componentFilter,
  );
  const { data: componentParentsPaged } = useGetComponentsSummarized(
    { refetchOnWindowFocus: false, refetchInterval: false, enabled: !!componentParentsFilter },
    componentParentsFilter,
  );

  const { t } = useTranslationText('components');
  const { mutate, isPending: isRunningDiscovery } = usePostMutation<undefined, DiscoveryCollection>(
    MutationKey.PostDiscovery,
    {
      onSuccess: async () => {
        await refetch();
        toast.success(tSuccess('executedComponentDiscovery'));
      },
      onError: (error) => {
        toast.error(`${tError('componentDiscovery')}: ${error.message}`);
      },
    },
  );

  const runDiscovery = (ctx: DropdownItemContext) => {
    if (!isRunningDiscovery) {
      mutate({ path: MutationPath.RunAllDiscoveries, body: undefined });
    }
    ctx.closeDropdown();
  };

  const setGroup = (v: string | undefined) => {
    setQuery((q) => ({ ...q, relation: v }));
  };

  const handleFilterChange = (name: keyof ComponentFilter, value: string | string[] | boolean | undefined) => {
    setQuery((q) => ({ ...q, name: undefined, [name]: value, page: DEFAULT_PAGE }));
  };

  useEffect(() => {
    setComponentFilter({
      displayName: query.displayName,
      assignedContractId: query.assignedContractId,
      relationRoot: query.relation,
      componentTypeIds: query.componentTypeIds,
      status: query.status,
      useRoot: !query.displayName && !query.status && !query.componentTypeIds && !query.contractors,
      size: query.relation === RelationTypeView.TABLE_VIEW ? DEFAULT_PAGE_SIZE : undefined,
      page: query.relation === RelationTypeView.TABLE_VIEW ? query.page : undefined,
      sort: query.relation === RelationTypeView.TABLE_VIEW ? query.sort : undefined,
      withoutContract: query.withoutContract,
      contractors: query.contractors,
      loadDescendentComponentStats: query.relation === RelationTypeView.PART_OF,
      ipAddress: query.ipAddress ? query.ipAddress : undefined,
      onlyFavorites: query.onlyFavorites,
      name: query.name,
    } as ComponentFilter);
    setComponentParentsFilter({
      assignedContractId: query.assignedContractId,
      relationParent: query.relation,
      useRoot: false,
    } as ComponentFilter);
  }, [query]);

  const applySelectedFilter = (filter: ComponentFilter) => {
    setQuery({
      ...query,
      displayName: filter?.displayName ?? undefined,
      assignedContractId: filter?.assignedContractId ?? undefined,
      componentTypeIds: filter?.componentTypeIds?.length ? filter.componentTypeIds : undefined,
      status: filter?.status ?? undefined,
      useRoot: filter?.useRoot ?? undefined,
      sort: filter?.sort ?? undefined,
      page: filter?.page ?? undefined,
      size: filter?.size ?? undefined,
      contractors: filter?.contractors?.length ? filter.contractors : undefined,
      withoutContract: filter?.withoutContract ?? undefined,
      loadDescendentComponentStats: filter?.loadDescendentComponentStats ?? undefined,
      ipAddress: filter?.ipAddress ?? undefined,
      onlyFavorites: filter?.onlyFavorites ?? undefined,
      name: filter?.name ?? undefined,
    });
  };

  const resetComponentFilter = () => {
    setQuery((q) => ({
      ...q,
      status: undefined,
      componentTypeIds: undefined,
      assignedContractId: undefined,
      contractors: undefined,
      contractorIds: undefined,
      withoutContract: undefined,
      ipAddress: undefined,
      onlyFavorites: undefined,
      id: undefined,
      name: undefined,
    }));
  };

  const getRelationTypesOptions = (): SelectDropdownOption[] => [
    { value: undefined, displayText: t('defaultHierarchy') },
    ...(relationTypes?.map((rt: RelationType) => ({
      value: rt.identifier,
      displayText: forward(rt.identifier),
    })) ?? []),
  ];

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort?.startsWith(property) ?? false;
    const sortState = getNextSortState(currentSortState, resetSortState);
    const sortQuery = sortState === SortState.None ? undefined : `${property}:${sortState}`;
    setQuery({ ...query, sort: sortQuery });
  }

  const [columnConfigs, setColumnConfigs] =
    useState<Map<UserOverviewColumnType, UserOverviewColumnConfig>>(defaultColumnConfig);

  const {
    data: userOverviewConfig,
    isLoading: userOverviewConfigIsLoading,
    refetch: refetchUserOverviewConfig,
  } = useGetUserOverviewConfig();

  useEffect(() => {
    if (userOverviewConfig) {
      setColumnConfigs(new Map(userOverviewConfig.columnConfigs.map((it) => [it.type, it])));
    }
  }, [userOverviewConfig]);

  const { mutate: saveColumnSelection } = usePostMutation<UserOverviewConfig, UserOverviewConfig>(
    MutationKey.UserColumnConfig,
  );

  const { mutate: resetColumnSelection } = useDeleteMutation(MutationKey.UserColumnConfig);

  const onColumnSelectionChanged = (selectedOptions: MultiValue<ReactSelectOption<UserOverviewColumnType>>) => {
    const typesSelected = selectedOptions.map((it) => it.value);
    setColumnConfigs((prevState) => {
      Array.from(prevState.keys() ?? []).forEach((columnType) => {
        const isSelected = typesSelected.includes(columnType);
        prevState.set(columnType, { ...prevState.get(columnType)!, visible: isSelected });
      });
      return prevState;
    });
    saveColumnSelection({
      body: {
        columnConfigs: Array.from(columnConfigs.values()),
      } as UserOverviewConfig,
      path: MutationPath.SaveUserColumnConfig,
    });
  };

  const onColumnConfigReset = () => {
    resetColumnSelection(
      { path: MutationPath.DeleteUserColumnConfig() },
      { onSuccess: () => refetchUserOverviewConfig() },
    );
  };

  function handleUnselectFilter() {
    setQuery({ ...query, name: undefined });
  }

  const getItems = (ctx: DropdownItemContext) => {
    const renderItems: JSX.Element[] = [
      <a
        type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        download
        href={addSelectedTenantsToPath(pathForAllComponentsWithFilter(query), selectedTenants)}
        className="dropdown-item"
        data-role="export-component-button"
        key="export-component-button"
        onClick={() => ctx.closeDropdown()}
      >
        {t('exportComponents')}
      </a>,
    ];

    if (hasPermission(UserResourcePermissions.Discovery.Create)) {
      renderItems.push(
        <a
          onClick={() => runDiscovery(ctx)}
          className="dropdown-item"
          data-role="discovery-run"
          key="discovery-components-dropdown"
        >
          {t('discoveryRun')}
        </a>,
      );
    }

    return renderItems;
  };

  return (
    <div className="has-pagination">
      <MultiSelectContainer context={ComponentsMultiSelectContext}>
        <ActionBar
          left={
            <>
              <SelectDropdown
                onChange={(e) => setGroup(e)}
                options={getRelationTypesOptions()}
                dataRole="select-relation-type"
                selectedKey={query.relation || undefined}
                requiredPermission={UserResourcePermissions.RelationType.Read}
              />
              <MultiSelectDropdown
                dataRole="column-selection"
                placeholder={t('selectVisibleColumns')}
                options={allColumnOptions(t)}
                onChange={onColumnSelectionChanged}
                mappedValues={mapColumnConfigsToOptions(columnConfigs, t)}
                openMenuOnClick
              />
              <div className="reset-config-btn-wrapper" id="reset-config-btn" onClick={onColumnConfigReset}>
                <FontAwesomeIcon icon={faUndo} role="button" className="reset-config-btn" />
              </div>
              <Tooltip anchorId="reset-config-btn" content={t('resetUserColumnConfigTooltip')} delayShow={100} />
            </>
          }
          center={
            <DebouncedSearchBar
              placeholder={t('componentSearchPlaceholder')}
              data-role="component-search-input"
              isLoading={isLoading}
              onChangeDebounced={(newValue) => handleFilterChange('displayName', newValue)}
              value={query.displayName ?? ''}
              className="abc def"
              autoComplete="on"
            />
          }
          right={
            <>
              <ComponentMultiSelectDropdown />
              <UserRoleCheck requiredPermission={UserResourcePermissions.Component.Create}>
                <Link className="button is-primary" to="create">
                  {t('createNewComponent')}
                </Link>
              </UserRoleCheck>
              <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
              <FilterMenuButton
                query={query}
                countWhen={(propertyName) => propertyName !== 'relation' && propertyName !== 'displayName'}
                toggleMenu={toggleIsFilterHidden}
              />
            </>
          }
        />
        <LoadingSpinner
          isLoading={isLoading || isRunningDiscovery || userOverviewConfigIsLoading}
          errors={isGetComponentError ? tError('404_component') : undefined}
        >
          <ComponentTable
            config={columnConfigs}
            components={componentsPaged?.content}
            onExpand={useExpand}
            relationType={query.relation || undefined}
            onSortChange={onSortChange}
            currentSort={query.sort || undefined}
            canExpand={(i) =>
              componentParentsFilter?.relationParent !== undefined &&
              componentParentsPaged?.content?.findIndex((e) => e.id === i.id) !== -1
            }
            sortable={query.relation === 'Tabellenansicht'}
            paginationFooter={
              <footer className="pagination-footer">
                <Pagination
                  currentPage={query.page ?? 0}
                  totalPages={componentsPaged?.totalPages ?? 0}
                  handleOnPageChange={onPageChange}
                  size="is-small"
                />
              </footer>
            }
          />
        </LoadingSpinner>
        {componentFilter && (
          <AllComponentFilter
            componentFilter={componentFilter}
            handleFilterChange={handleFilterChange}
            resetComponentFilter={resetComponentFilter}
            applySelectedFilter={applySelectedFilter}
            isFilterHidden={isFilterHidden}
            hideFilter={toggleIsFilterHidden}
            handleUnselectFilter={handleUnselectFilter}
          />
        )}
      </MultiSelectContainer>
    </div>
  );
}
