import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Ticket } from '../../../models/operation/TicketModel';
import { TicketStates } from '../../../models/operation/TicketStatesModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { Dropdown } from '../../../ui/dropdown/Dropdown';
import MenuStateItem from './MenuStateItem';

import './TicketingItemMenu.scss';

interface TicketingItemMenuProps {
  statesForMenu: TicketStates;
  ticket: Ticket;
}

export default function TicketingItemMenu({ statesForMenu, ticket }: TicketingItemMenuProps) {
  const { t } = useTranslationText('tickets');
  const [isLoading, setIsLoading] = useState(false);

  const menuStatesSection = (
    <div key="states-section">
      <div className="dropdown-item menu-catagory">
        <span>{t('moveToState')}</span>
      </div>
      {statesForMenu.map((stateForMenu) => (
        <MenuStateItem
          state={stateForMenu}
          ticket={ticket}
          setIsLoading={setIsLoading}
          key={`menu-item-${stateForMenu.key}-${ticket.id}`}
        />
      ))}
    </div>
  );

  return (
    <div className="ticketing-item-context-menu" onClick={(e) => e.preventDefault()}>
      <Dropdown
        title={<FontAwesomeIcon icon={faEllipsisH} />}
        isLoading={isLoading}
        renderItems={() => [menuStatesSection]}
      />
    </div>
  );
}
