import { KnowledgeBaseArticleTag } from './KnowledgeBaseArticleTagModel';
import { BaseComponentType } from '../operation/ComponentTypeModel';

export interface SummarizedKnowledgeBaseArticle {
  id: string;
  title: string;
  descriptionShort: string;
  tags?: KnowledgeBaseArticleTag[];
  author: string;
  visits?: number;
  lastVisitedAt?: string;
  updatedAt?: string;
}

export interface KnowledgeBaseArticle extends SummarizedKnowledgeBaseArticle {
  content?: string;
  highlighted: boolean;
  media: PersistableFile[];
  tags: KnowledgeBaseArticleTag[];
  componentTypes: BaseComponentType[];
  components: KnowledgeBaseComponent[];
  author: string; // combination of name and email: '<name> (<email>)'
  currentEditor: string; // combination of name and email: '<name> (<email>)'
  comments: KnowledgeBaseArticleComment[];
  integrationsscheinId?: string;
  lastVisitedAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface KnowledgeBaseComponent {
  id: string;
  displayName: string;
}

export interface KnowledgeBaseArticleComment {
  id: string;
  text: string;
  author: string; // combination of name and email: '<name> (<email>)'
  createdAt: string;
}

export function keyForArticleCommentModel(model: KnowledgeBaseArticleComment) {
  return `${model.author}@${model.createdAt}`;
}

export interface PersistableFile {
  id: string;
  name: string;
  size: number;
  mime: string;
  sha256: string;
  updatedAt: string;
}
