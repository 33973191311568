import classNames from 'classnames';
import React, { ComponentType, ReactNode, useCallback, useState } from 'react';
import { GroupBase, MenuPlacement, MultiValue, MultiValueGenericProps, MultiValueRemoveProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Control, Field, Label } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

export interface Option {
  readonly label: string;
  readonly value: string;
}

interface MultiSelectCreatableDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  createLabel: (inputValue: string) => ReactNode;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string | null;
  onChange: (newValues: MultiValue<T>) => void;
  onCreateOption: (inputValue: string) => void;
  placeholder?: string;
  values: T[];
  options?: T[];
  menuPlacement?: MenuPlacement;
  hideDropdown?: boolean;
  errorMessage?: string;
  size?: BulmaSize;
}

export default function MultiSelectCreatableDropdown<T>({
  dataRole,
  isError,
  createLabel,
  isLoading,
  label,
  noOptionsMessage,
  onChange,
  onCreateOption,
  placeholder,
  values,
  options,
  menuPlacement,
  hideDropdown,
  errorMessage,
  size = 'is-normal',
}: MultiSelectCreatableDropdownProps<T>) {
  const FormatCreateLabel = useCallback(
    (inputValue: string) => <div data-role="multi-select-create-button">{createLabel(inputValue)}</div>,
    [createLabel],
  );

  const MultiValueLabel = useCallback(
    ({ innerProps, data }: MultiValueGenericProps<Option>) => (
      <div {...innerProps} data-role={`label-${data.label}`}>
        {data.label}
      </div>
    ),
    [],
  );

  /* eslint-disable react/no-unstable-nested-components */
  const components = hideDropdown
    ? { MultiValueLabel, DropdownIndicator: null }
    : { MultiValueLabel, DropdownIndicator };

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  return (
    <Field data-role={dataRole}>
      {label && <Label htmlFor={label}>{label}</Label>}
      <Control>
        <CreatableSelect
          className={classNames('aos-multiple-select', size, {
            'is-error': isError,
            'is-closed': menuIsClosed,
          })}
          classNamePrefix="react-select"
          formatCreateLabel={FormatCreateLabel}
          isClearable
          isLoading={isLoading}
          isMulti
          options={options}
          noOptionsMessage={() => noOptionsMessage}
          onChange={onChange}
          onCreateOption={onCreateOption}
          placeholder={placeholder}
          value={values}
          inputId="creatable-select-input"
          menuPlacement={menuPlacement}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          unstyled
          components={{
            MultiValueRemove: createCustomMultiValueRemove(size) as
              | ComponentType<MultiValueRemoveProps<T, true, GroupBase<T>>>
              | undefined,
            ...components,
          }}
          styles={multiStyles}
          classNames={multiClassNames<T>()}
        />
      </Control>
      {isError && errorMessage && (
        <div data-role="form-error-message" className="aos-form-helpers">
          <div className="helper is-error">{errorMessage}</div>
        </div>
      )}
    </Field>
  );
}
