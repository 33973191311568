import { Trans } from 'react-i18next';
import { AlertItem } from '../../models/operation/AlertModel';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Collapsible from '../../ui/collapsible/Collapsible';
import useGetAlertsForComponent from '../../react-query/AlertApi';

interface ComponentAlertsProps {
  id: string;
  title: string;
  renderElement: (alert: AlertItem) => JSX.Element;
}

export function ComponentAlerts({ id, title, renderElement }: ComponentAlertsProps) {
  const {
    data: alerts,
    isLoading: areAlertsLoading,
    isError: alertsError,
  } = useGetAlertsForComponent(id, { status: 'ACTIVE' });

  return (
    <LoadingSpinner
      isLoading={areAlertsLoading}
      errors={
        alertsError ? <Trans i18nKey="errorTexts.alertsForComponentLoadingError.text" values={{ id }} /> : undefined
      }
    >
      {alerts && alerts?.length > 0 ? (
        <Collapsible title={title} collapsibleKey="alerts" badgeCount={alerts?.length} initialExpanded>
          {alerts?.map(renderElement)}
        </Collapsible>
      ) : null}
    </LoadingSpinner>
  );
}
