import React, { useState } from 'react';
import { MultiValue } from 'react-select';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { loadComponentsOptions } from '../../../react-query/ComponentApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { mapComponentsToOptions } from '../../../models/operation/TicketFunctions';
import useCustomFetch from '../../../react-query/CustomFetch';
import MultiJSXSearchDropdown from '../../../ui/multi-jsx-search-dropdown/MultiJSXSearchDropdown';
import { JSXSelectOption } from '../../../ui/jsx-search-dropdown/JSXSearchDropdown';
import { BaseComponentItem } from '../../../models/operation/ComponentModel';
import { KnowledgeBaseComponent } from '../../../models/knowledge-base/KnowledgeBaseModel';

interface ArticleComponentSelectionProps {
  onChange: (selectedOptions: BaseComponentItem[]) => void;
  values: KnowledgeBaseComponent[];
}

export function ArticleComponentSelection({ onChange, values }: ArticleComponentSelectionProps) {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslationText('tickets');
  const customFetch = useCustomFetch();

  const onOptionSelect = (selectedOptions: MultiValue<JSXSelectOption>): void => {
    onChange(
      selectedOptions.map((selectedOption) => ({
        id: selectedOption.value.id,
        displayName: selectedOption.labelText,
        isFavorite: selectedOption.value.isFavorite,
      })),
    );
  };

  const loadOptions = async (searchInput: string, prev?: JSXSelectOption[]) =>
    loadComponentsOptions(searchInput, prev, setIsError, customFetch);

  return (
    <div>
      <label className="label" htmlFor={t('components')}>
        {t('components')}
      </label>
      <MultiJSXSearchDropdown<KnowledgeBaseComponent>
        dataRole="component-select"
        onChange={onOptionSelect}
        requiredPermission={UserResourcePermissions.Article.Update}
        loadOptions={loadOptions}
        values={values}
        isError={isError}
        placeholder={t('searchComponent')}
        noOptionsMessage={t('noSuggestionsAssignedComponents')}
        isLoadingMessage={t('componentsAreLoading')}
        mapValueToSelectOption={mapComponentsToOptions}
        hideSelectedOptions
      />
    </div>
  );
}
