import { SwitchInput } from '../../switch/SwitchInput';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface ComponentOnlyFavoritesFilterProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function ComponentOnlyFavoritesFilter({ isChecked, onChange }: ComponentOnlyFavoritesFilterProps) {
  const { t } = useTranslationText('components');

  return (
    <SwitchInput
      checked={isChecked}
      onChange={onChange}
      label={t('onlyFavorites')}
      id="only-favorites"
    />
  );
}
