import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import RichTextEditor from '../../rich-text/RichTextEditor';
import { useAuthUser } from '../../../auth/UserRoleCheck';
import RichTextContent from '../../rich-text-content/RichTextContent';
import { useTranslationText } from '../../../translation/TranslationHooks';
import usePreventNavigation from '../../../custom-hooks/PreventNavigation';
import { UserPermission } from '../../../auth/UserPermission';

interface EditableRichTextEditorProps {
  id: string;
  isLoading: boolean;
  onChange: (value: string) => void;
  onAcceptNewValue?: (value: string, callback: (endEditing: boolean) => void) => void;
  value: string;
  placeholder: string;
  label: string;
  requiredPermission: UserPermission;
}

export default function EditableRichTextEditor({
  id,
  isLoading,
  label,
  onChange,
  onAcceptNewValue,
  placeholder,
  value,
  requiredPermission,
}: EditableRichTextEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [oldValue, setOldValue] = useState<string | undefined>(undefined);
  const { hasPermission } = useAuthUser();
  const { t: tCommon } = useTranslationText('commons');

  usePreventNavigation(isEditing ? value !== oldValue : false, tCommon('discardOpenChangesQuestion'));

  function handleAcceptNewValue() {
    if (onAcceptNewValue !== undefined) {
      onAcceptNewValue(value, (endEditing) => {
        if (endEditing) {
          setOldValue(value);
          setIsEditing(false);
        }
      });
    }
  }

  function handleCancelClick() {
    onChange(oldValue || '');
    setIsEditing(false);
  }

  const enterEditMode = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (value === oldValue || oldValue === undefined) {
      setOldValue(value);
    }
    if (oldValue && value !== oldValue) {
      enterEditMode();
    }
  }, [enterEditMode, oldValue, value]);

  return (
    <>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div
        role="button"
        onClick={hasPermission(requiredPermission) ? enterEditMode : undefined}
        tabIndex={0} // == focusable
      >
        {!isEditing ? (
          <RichTextContent value={value} dataRole="ticket-description" />
        ) : (
          <>
            <div className="field">
              <div className="control">
                <RichTextEditor
                  id={id}
                  onChange={onChange}
                  onBlur={handleAcceptNewValue}
                  placeholder={placeholder}
                  value={value}
                  isWarning={value !== oldValue}
                />
              </div>
            </div>
            <div className="field is-grouped mt-2">
              <div className="control is-full-height">
                <button
                  className={classNames('button', 'is-primary', {
                    'is-loading': isLoading,
                    'is-warning': value !== oldValue,
                  })}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (handleAcceptNewValue) {
                      handleAcceptNewValue();
                    }
                  }}
                  type="submit"
                  tabIndex={-1}
                  data-role="save-button"
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </div>
              <div className="control is-full-height">
                <button
                  className="button"
                  disabled={isLoading}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (handleCancelClick) {
                      handleCancelClick();
                    }
                  }}
                  type="button"
                  tabIndex={-1}
                  data-role="cancel-button"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
