import classNames from 'classnames';
import { SingleValue } from 'react-select';
import SingleSelectDropdown from '../single-select-dropdown/SingleSelectDropdown';
import { UserPermission } from '../../auth/UserPermission';

interface ReactSelectOption {
  label: string;
  value: string;
}

interface SingleValueInputProps {
  dataRole?: string;
  label?: string;
  onChange: (values: string) => void;
  placeholder?: string;
  value?: string;
  requiredPermission: UserPermission;
  options: ReactSelectOption[];
  isRequired: boolean;
  isDisabled?: boolean;
}

export default function SingleValueInput({
  dataRole,
  label,
  onChange,
  placeholder,
  value,
  requiredPermission,
  options,
  isRequired,
  isDisabled,
}: SingleValueInputProps) {
  const handleChange = (option: SingleValue<ReactSelectOption>) => {
    onChange(option?.value ?? '');
  };

  const selectValue = options.find((aRole) => aRole.value === value);

  return (
    <div className="field">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control">
        <SingleSelectDropdown
          dataRole={dataRole}
          disabled={isDisabled}
          options={options}
          requiredPermission={requiredPermission}
          onChange={handleChange}
          placeholder={placeholder}
          value={selectValue}
        />
      </div>
    </div>
  );
}
