import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { mapStatesToOptions } from '../../models/operation/TicketFunctions';
import { TicketQuery } from '../../models/operation/TicketQuery';
import { useGetStates } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import MultiSelectDropdown from '../multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../search-dropdown/SearchDropdown';

interface StateFilterProps {
  label?: string;
  selectedStates: (string | null)[] | null | undefined;
  onChange: (name: keyof TicketQuery, value: string[]) => void;
  size?: BulmaSize;
}

function formatOptionLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export default function StateFilter({ label, selectedStates, onChange, size = 'is-small' }: StateFilterProps) {
  const { t } = useTranslationText('tickets');

  const { data: states, isLoading: isStateLoading, isError: isStateError } = useGetStates();

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'states',
      selectedOptions.map((value) => value.value),
    );
  };

  const mappedValues = mapStatesToOptions(
    t,
    states?.filter((state) => selectedStates?.includes(state.key)),
  );

  return (
    <MultiSelectDropdown
      dataRole="states-select"
      isError={isStateError}
      isLoading={isStateLoading}
      onChange={handleOnChange}
      options={mapStatesToOptions(t, states)}
      openMenuOnClick
      isSearchable={false}
      placeholder={t('selectTicketState')}
      mappedValues={mappedValues}
      formatOptionLabel={formatOptionLabel}
      label={label}
      size={size}
    />
  );
}
