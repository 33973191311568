import parse from 'html-react-parser';
import './RichTextContent.scss';
import { TextInput as AosTextInput } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';

interface RichTextContentProps {
  value: string;
  dataRole?: string;
}

export default function RichTextContent({ value, dataRole }: RichTextContentProps) {
  const { t } = useTranslationText('tickets');
  return (
    <>
      {value ? (
        <div className="rte-content-wrapper aos-textarea field" data-role={dataRole}>
          {parse(value ?? '')}
        </div>
      ) : (
        <AosTextInput placeholder={t('descriptionPlaceholder')} />
      )}
    </>
  );
}
