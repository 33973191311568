import React from 'react';
import { TicketQuery } from '../../models/operation/TicketQuery';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';

interface TicketFavoritesFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TicketQuery, value: any) => void;
  secondLabel?: string;
}

function TicketFavoritesFilter({
  isChecked = false,
  onChange,
  secondLabel,
}: TicketFavoritesFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <SwitchInput
      id="ticketFavoritesFilter"
      checked={isChecked}
      onChange={(value) => onChange('onlyFavorites', value || undefined)}
      label={t('favoriteTickets')}
      secondLabel={secondLabel}
      isSmall
    />
  );
}

export default TicketFavoritesFilter;
