import { ObjectSchema } from 'yup';
import { YupType } from '../../translation/YupLocal';
import { isDateAfter, isTimeAfter } from '../dates/dateFunctions';
import { DayOfWeek } from './ServiceTimeModel';
import { CreateContractModel, PatchContractModel } from './ContractManagementModel';

const supportContactPersonsValidation = (validation: YupType, t: (key: string) => string) =>
  validation
    .array()
    .of(
      validation.object({
        companyName: validation.string().trim().max(255).required(),
        phoneNumber: validation.string().trim().max(255).optional().nullable(),
        mobileNumber: validation.string().trim().max(255).optional().nullable(),
        telefax: validation.string().trim().max(255).optional().nullable(),
        email: validation.string().trim().max(255).email(t('invalidEmailError')).optional().nullable(),
        role: validation.string().required(),
        notifyForNewTask: validation.boolean().required(),
      })
    )
    .optional();

export const patchSupportContactPersonsValidation = (validation: YupType, t: (key: string) => string) =>
  validation.object().shape({
    supportContactPersons: supportContactPersonsValidation(validation, t),
  }) as ObjectSchema<PatchContractModel>;

const serviceTimesValidation = (validation: YupType, t: (key: string) => string) =>
  validation
    .array()
    .of(
      validation.object().shape({
        days: validation
          .array(validation.mixed<DayOfWeek>().oneOf(Object.values(DayOfWeek)).required())
          .ensure()
          .required()
          .min(1, t('serviceTimesDaysError')),
        begin: validation
          .string()
          .required()
          .test('is-begin-before-end', t('beginServiceTimeIsAfterEndServiceTime'), (begin, context) => {
            const { end } = context.parent;
            if (begin && end && !isTimeAfter(end, begin)) {
              return false;
            }
            return true;
          }),
        end: validation
          .string()
          .required()
          .test('is-end-after-begin', t('endServiceTimeIsBeforeBeginServiceTime'), (end, context) => {
            const { begin } = context.parent;
            if (begin && end && !isTimeAfter(end, begin)) {
              return false;
            }
            return true;
          }),
        publicHolidaysIncluded: validation.boolean().required(),
      })
    )
    .required();

export const patchServiceTimesValidation = (validation: YupType, t: (key: string) => string) =>
  validation.object().shape({
    serviceTimes: serviceTimesValidation(validation, t),
  }) as ObjectSchema<PatchContractModel>;

const contractValidationSchema = (validation: YupType, t: (key: string) => string) =>
  validation
    .object({
      internalNumber: validation.string().trim().max(255).required(),
      externalNumber: validation.string().trim().max(255).optional(),
      contractualObject: validation.string().trim().required(),
      startDate: validation
        .date()
        .required()
        .test('is-startDate-before-endDate', t('startDateAfterEndDateError'), function test(startDate, context) {
          let endDate;
          if (this.options.context?.endDate) {
            // get end date from this.options.context if this is used on ContractDetails
            endDate = new Date(this.options.context?.endDate);
          } else if (context.parent.endDate) {
            // get end date from context.parent if this is used on CreateContract
            endDate = context.parent.endDate;
          }

          if (startDate && endDate && !isDateAfter(endDate, startDate)) {
            return false;
          }
          return true;
        }),

      endDate: validation
        .date()
        .optional()
        .nullable()
        .test('is-endDate-after-startDate', t('endDateBeforeStartDateError'), function test(endDate, context) {
          let startDate;
          if (this.options.context?.startDate) {
            // get start date from this.options.context if this is used on ContractDetails
            startDate = new Date(this.options.context?.startDate);
          } else if (context.parent.startDate) {
            // get start date from context.parent if this is used on CreateContract
            startDate = context.parent.startDate;
          }

          if (endDate && startDate && !isDateAfter(endDate, startDate)) {
            return false;
          }
          return true;
        }),
      warrantyPeriod: validation.date().optional().nullable(),
      type: validation.string().trim().max(255).optional().nullable(),
      supportBeginHours: validation.string().trim().optional().nullable(),
      responseHours: validation.string().trim().optional().nullable(),
      recoveryHours: validation.string().trim().optional().nullable(),
      penalty: validation.string().trim().max(255).optional().nullable(),
      description: validation.string().trim().optional().nullable(),
      supportContactPersons: supportContactPersonsValidation(validation, t),
      serviceTimes: serviceTimesValidation(validation, t),
    })
    .required() as ObjectSchema<CreateContractModel>;

export { contractValidationSchema };
