import './WidgetWrapper.scss';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslationText } from '../../translation/TranslationHooks';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';

interface WidgetWrapperProps {
  title: string;
  isLoading?: boolean;
  isError?: boolean;
  onEdit?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  dataRole?: string;
  noContent: React.ReactNode;
}

export default function WidgetWrapper({
  title,
  isLoading,
  isError,
  onEdit,
  children,
  dataRole,
  noContent,
}: WidgetWrapperProps) {
  const { t } = useTranslationText('knowledgeBases');

  const isEmptyList = !!children && Array.isArray(children) && children.length === 0;

  const content = children && !isEmptyList ? children : <div className="widget-no-content">{noContent}</div>;

  return (
    <div className="widget-wrapper" data-role={dataRole}>
      <div className="widget-header">
        <span>{title}</span>
        {onEdit && (
          <button type="button" className="edit-icon" onClick={() => onEdit()}>
            <span className="icon">
              <FontAwesomeIcon icon={faPencil} />
            </span>
          </button>
        )}
      </div>
      <LoadingSpinner isLoading={isLoading ?? false}>
        {isError ? (
          <div className="widget-no-content">{t('errorWidgetData')}</div>
        ) : (
          <div className="widget-content">{content}</div>
        )}
      </LoadingSpinner>
    </div>
  );
}
