import {ReactElement} from "react";
import Collapsible from "../ui/collapsible/Collapsible";
import {useGetChangelog} from "../react-query/GetChangelogApi";

function convertMarkdownSignsToHTMLSigns(part: string) {
  if (part.startsWith('###')) {
    return <h1>{part.substring(3, part.length)}</h1>
  }
  if (part.startsWith('##')) {
    return <h2>{part.substring(2, part.length)}</h2>
  }
  return <p>{part}</p>
}

function removeWhitespaces(text: string): string[] {
  return text.split('\n').filter(part => part.trim().length)
}

function reconnectSentences(parts: string[]): string[] {
  return parts.map((elem, idx, arr) =>
    arr[idx + 1]?.startsWith(' ') ? `${elem.trim()} ${arr[idx + 1].trim()}` : elem.trim()
  ).filter((elem, idx, arr) => !arr[idx - 1]?.includes(elem))
}

function removeEmptySections(parts: string[]): string[] {
  return parts.filter((elem, idx, arr) =>
    !elem.includes('###') || (elem.includes('###') && !arr[idx + 1]?.includes('###'))
  ).filter((elem, idx, arr) => (arr.length - 1) === idx ? !elem.includes('###') : true)
}

function markdownToHtml(text: string): ReactElement {
  const partsAsJSX = removeEmptySections(
    reconnectSentences(
      removeWhitespaces(
        text
      )
    )
  ).map(part => convertMarkdownSignsToHTMLSigns(part))
  return <>{...partsAsJSX}</>
}

function Changelog() {
  const { data: changelog } = useGetChangelog();

  return (
    <>
      <Collapsible
        title={`Changelog ${changelog?.version}`}
        collapsibleKey="changelog"
        initialExpanded={false}
      >
        {markdownToHtml(changelog?.patchnotes || '')}
      </Collapsible>
    </>
  )
}

export default Changelog