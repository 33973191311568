import { StrapiFile } from '../strapi/StrapiFile';
import { Actor } from '../actor/Actor';

export interface HasCreationDate {
  createdAt: string;
}

export interface BaseTicket {
  id: number;
  title: string;
}

export interface Ticket extends HasCreationDate, BaseTicket {
  state: string;
  description: string;
  lastModified: string;
  severity: string;
  assigneeId: string;
  assignedComponent: TicketComponent | null;
  media: StrapiFile[];
  author: Actor;
  favorite: boolean;
  reportableProcess: boolean;
  type: string;
  observerIds: string[];
}

export interface TicketComponent {
  id: string;
  displayName: string;
  componentTypeId: string;
  isFavorite?: boolean;
}

export interface HistoryModel extends HasCreationDate {
  eventTranslationKey: string;
  actor: Actor;
  payload: any;
  testId: number;
}

export function keyForTicketHistoryModel(model: HistoryModel) {
  return `${model.actor}@${model.createdAt}`;
}

export interface TicketCommentModel extends HasCreationDate {
  readonly author: string;
  readonly text: string;
  readonly lastModified: string;
  readonly type: string;
  readonly taskId: number | null;
}

export function keyForTicketCommentModel(model: TicketCommentModel) {
  return `${model.author}@${model.createdAt}`;
}
