import { ComponentItem } from '../../models/operation/ComponentModel';
import Collapsible from '../../ui/collapsible/Collapsible';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import ComponentContractItem from '../component-contract-item/ComponentContractItem';
import { useGetAncestorComponents } from '../../react-query/ComponentApi';

interface ComponentContractsProps {
  title: string;
  component: ComponentItem;
  onSubmit: (
    newValue: string | Date | undefined,
    property: keyof ComponentItem,
    isFormValid?: boolean,
    saveCompleted?: (endEditing: boolean) => void
  ) => void;
  renderElement: (ancestor: ComponentItem) => JSX.Element;
}

export function ComponentContracts(props: ComponentContractsProps) {
  const { title, component, onSubmit, renderElement } = props;

  const { data: ancestors, isLoading: areAncestorsLoading } = useGetAncestorComponents(component.id, 'part-of');
  const ancestorsWithContracts = (ancestors || []).filter((ancestor) => !!ancestor.assignedContractId);

  return (
    <Collapsible
      collapsibleKey="collapsible-contracts"
      title={title}
      badgeCount={(component.assignedContractId ? 1 : 0) + ancestorsWithContracts.length}
      initialExpanded
    >
      <ComponentContractItem onSubmit={onSubmit} sourceComponent={component} currentComponent={component} />
      <LoadingSpinner isLoading={areAncestorsLoading}>{ancestorsWithContracts.map(renderElement)}</LoadingSpinner>
    </Collapsible>
  );
}
