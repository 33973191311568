import { Icon, Tag } from '@aos/styleguide-react';

export enum Criticality {
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
  CRITICAL = 'CRITICAL',
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
}

export function mapCriticalityToTag(criticality: Criticality) {
  switch (criticality) {
    case Criticality.CRITICAL:
      return (
        <Tag color="is-danger">
          <Icon name="circle-exclamation" size="is-small" /> {criticality}
        </Tag>
      );
    case Criticality.MAJOR:
      return (
        <Tag color="is-warning">
          <Icon name="circle-plus" size="is-small" /> {criticality}
        </Tag>
      );
    case Criticality.MINOR:
      return (
        <Tag color="is-primary">
          <Icon name="circle-minus" size="is-small" /> {criticality}
        </Tag>
      );
    case Criticality.UNKNOWN:
      return (
        <Tag color="is-default">
          <Icon name="circle-question" size="is-small" /> {criticality}
        </Tag>
      );
    default:
      return <Tag color="is-default"> {criticality}</Tag>;
  }
}
