import { MultiValue, SingleValue } from 'react-select';
import { useEffect, useState } from 'react';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import SingleSelectDropdown from '../../../ui/single-select-dropdown/SingleSelectDropdown';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { RuleAction } from '../../../models/operation/AutomationRuleModel';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import './SendEmailMessageAction.scss';
import MultiSelectCreatableDropdown, {
  Option,
} from '../../../ui/multi-select-creatable-dropdown/MultiSelectCreatableDropdown';
import TextInput from '../../../ui/text-input/TextInput';
import TextArea from '../../../ui/text-area/TextArea';

const mailTemplates = [
  { value: 'Ticket verändert', label: 'Das Ticket wurde geändert' },
  { value: 'Ticket erstellt', label: 'Das Ticket wurde erstellt' },
  { value: 'Kommentar hinzugefügt', label: 'Das Ticket wurde kommentiert' },
];

export interface SendEmailConfiguration extends RuleAction {
  type: 'sendEmailMessage';
  mailTemplateName: string | null;
  subject: string | null;
  body: string | null;
  sendToCreator: boolean;
  sendToAssignee: boolean;
  emailAddresses: string[];
}

const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const defaultConfiguration = {
  mailTemplateName: null,
  sendToCreator: false,
  sendToAssignee: false,
  emailAddresses: [],
  body: null,
  subject: null,
  type: 'sendEmailMessage',
} as SendEmailConfiguration;

function mailToOption(mail: string): Option {
  return {
    label: mail,
    value: mail,
  };
}

export default function SendEmailMessageAction({
  configuration,
  handleConfigurationUpdate,
}: GenericActionConfigurationProps<SendEmailConfiguration>) {
  const [actionConfiguration, setActionConfiguration] = useState(configuration ?? defaultConfiguration);
  const [useTemplate, setUseTemplate] = useState(actionConfiguration.mailTemplateName !== null);
  const { t } = useTranslationText('automations');

  const [isEmailError, setIsEmailError] = useState<boolean>(false);

  const isValid = (config: SendEmailConfiguration) => {
    const hasReceiver = config.sendToAssignee || config.sendToCreator || config.emailAddresses.length > 0;
    const hasTemplateOrSubjectAndBody = config.mailTemplateName || (config.subject && config.body);
    return hasReceiver && hasTemplateOrSubjectAndBody;
  };

  useEffect(() => {
    if (isValid(actionConfiguration)) {
      handleConfigurationUpdate(actionConfiguration);
    }
  }, [actionConfiguration, handleConfigurationUpdate]);

  const handleSelectTemplateChange = (option: SingleValue<ReactSelectOption>) => {
    const mailTemplateName = option?.value ?? null;
    setActionConfiguration((prevState) => ({ ...prevState, mailTemplateName }));
  };

  const handleSwitchSendToCreator = (checked: boolean) => {
    setActionConfiguration((prevState) => ({ ...prevState, sendToCreator: checked }));
  };

  const handleSwitchSendToAssignee = (checked: boolean) => {
    setActionConfiguration((prevState) => ({ ...prevState, sendToAssignee: checked }));
  };

  const handleCreateMail = (email: string) => {
    if (!emailRegexp.test(email)) {
      setIsEmailError(true);
      return;
    }
    setIsEmailError(false);
    setActionConfiguration((prevState) => ({ ...prevState, emailAddresses: [...prevState.emailAddresses, email] }));
  };

  const handleChangeMail = (newValues: MultiValue<Option>) => {
    setActionConfiguration((prevState) => ({ ...prevState, emailAddresses: newValues.map((it) => it.value) }));
  };

  const handleSwitchUseTemplate = (checked: boolean) => {
    if (!checked) {
      setActionConfiguration((prevState) => ({ ...prevState, mailTemplateName: null }));
    } else {
      setActionConfiguration((prevState) => ({ ...prevState, subject: null, body: null }));
    }
    setUseTemplate(checked);
  };

  const handleSubjectChange = (newValue: string) => {
    setActionConfiguration((prevState) => ({ ...prevState, subject: newValue }));
  };

  const handleBodyChange = (newValue: string) => {
    setActionConfiguration((prevState) => ({ ...prevState, body: newValue }));
  };

  return (
    <div className="send-email-message">
      <SwitchInput
        id="sendToCreator"
        checked={actionConfiguration.sendToCreator}
        onChange={handleSwitchSendToCreator}
        label={t('sendToCreator_label')}
      />
      <SwitchInput
        id="sendToAssignee"
        checked={actionConfiguration.sendToAssignee}
        onChange={handleSwitchSendToAssignee}
        label={t('sendToAssignee_label')}
      />
      <MultiSelectCreatableDropdown
        createLabel={() => t('newReceivers_label')}
        onChange={handleChangeMail}
        onCreateOption={handleCreateMail}
        noOptionsMessage={null}
        values={actionConfiguration.emailAddresses.map((it) => mailToOption(it))}
        label={t('additionalReceivers_label')}
        hideDropdown
        isError={isEmailError}
        errorMessage={t('emailValidationError')}
      />
      <SwitchInput
        id="toggleTemplateSelection"
        checked={useTemplate}
        label={t('toggleTemplate_label')}
        onChange={handleSwitchUseTemplate}
      />
      {useTemplate && (
        <SingleSelectDropdown
          dataRole="rule-template-dropdown"
          onChange={handleSelectTemplateChange}
          options={mailTemplates}
          requiredPermission={UserResourcePermissions.Rule.CreateOrUpdate}
          value={
            actionConfiguration.mailTemplateName
              ? mailTemplates.find((it) => it.value === actionConfiguration.mailTemplateName)
              : null
          }
          label={t('fieldMailTemplate')}
        />
      )}
      {!useTemplate && (
        <>
          <TextInput
            label={t('sendMailSubject_label')}
            value={actionConfiguration.subject ?? ''}
            onValueChange={handleSubjectChange}
            dataRole="sendMailSubject"
          />
          <TextArea
            label={t('sendMailBody_label')}
            value={actionConfiguration.body ?? ''}
            onValueChange={handleBodyChange}
            dataRole="sendMailBody"
          />
        </>
      )}
    </div>
  );
}
