import { Link } from 'react-router-dom';
import { useTranslationText } from '../../../translation/TranslationHooks';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import Collapsible from '../../../ui/collapsible/Collapsible';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { useGetSimilarKnowledgeBaseArticle } from '../../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import ArticleCard from '../article-card/ArticleCard';
import './SimiliarArticlesWidget.scss';

interface SimiliarArticlesWidgetProps {
  articleId: string;
  widgetKey: string;
  collapsibleTitle?: string;
  hideWidgetIfEmpty?: boolean;
}

export default function SimiliarArticlesWidget({
  articleId,
  collapsibleTitle,
  widgetKey,
  hideWidgetIfEmpty,
}: SimiliarArticlesWidgetProps) {
  const { t } = useTranslationText('knowledgeBases');
  const { data: articles, isLoading, isError } = useGetSimilarKnowledgeBaseArticle(articleId);

  if (hideWidgetIfEmpty && (articles === undefined || articles.length === 0)) {
    return <></>;
  }

  return (
    <div className="article-widget" data-role={widgetKey}>
      <UserRoleCheck requiredPermission={UserResourcePermissions.Article.Read}>
        <LoadingSpinner isLoading={isLoading} errors={isError}>
          <Collapsible
            title={collapsibleTitle ?? t('suggestedKnowledgeBaseArticles')}
            collapsibleKey={`article-widget-${widgetKey}`}
            initialExpanded
          >
            {articles && articles.length > 0 ? (
              articles.map((article) => (
                <div key={article.id}>
                  <ArticleCard article={article} />
                  <div className="list-separator" />
                </div>
              ))
            ) : (
              <Link to={NavigationRoutes.KnowledgeBaseArticle} className="no-articles">
                {t('noArticlesFound')}
              </Link>
            )}
          </Collapsible>
        </LoadingSpinner>
      </UserRoleCheck>
    </div>
  );
}
