import { useState } from 'react';
import { InputActionMeta, SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import {
  TicketRelationDto,
  CreateRelationDto,
  RelationRefDto,
  RelationSpec,
} from '../../../models/operation/RelationTypeModel';
import { Ticket, BaseTicket } from '../../../models/operation/TicketModel';
import SearchDropdown, { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { MutationKey, MutationPath, usePostMutation } from '../../../react-query/MutationQueries';
import { mapRelationSpecsToOptions, mapRelationSpecToOption } from '../../../models/operation/RelationTypeFunctions';
import { mapOptionToTicket, mapTicketsToOptions, mapTicketToOption } from '../../../models/operation/TicketFunctions';
import { useShortcut, useShortcutEventHandler } from '../../../custom-hooks/Shortcuts';
import KeyCombination from '../../../custom-hooks/KeyCombination';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useRelationTypeTranslations, useTranslationText } from '../../../translation/TranslationHooks';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import useGetTicketSuggestions from '../../GetTicketSuggestions';

interface CreateTicketRelationProps {
  relationSpecs: RelationSpec[];
  currentTicket: Ticket;
  onCancel?: () => void;
  onSuccess?: (ticketRelation: TicketRelationDto) => void;
}

function CreateTicketRelation({ relationSpecs, currentTicket, onCancel, onSuccess }: CreateTicketRelationProps) {
  const { translateRelation } = useRelationTypeTranslations();
  const { t } = useTranslationText('tickets');
  const { t: tErrorTexts } = useTranslationText('errorTexts');

  const {
    data: ticketsPaged,
    isLoading: isTicketLoading,
    isError: isTicketError,
    setTicketQuery,
  } = useGetTicketSuggestions();

  const [relation, setRelation] = useState<RelationSpec>();
  const [otherTicket, setOtherTicket] = useState<BaseTicket | null>(null);

  const mapOptionToRelationSpec = (option: SingleValue<ReactSelectOption>): RelationSpec | undefined =>
    relationSpecs.find((e) => e.identifier === option?.value) ?? undefined;

  const { mutate, isPending: isCreating } = usePostMutation<CreateRelationDto, TicketRelationDto>(
    MutationKey.PostTicketRelation,
    {
      onSuccess: onSuccess ? (createdRelation) => onSuccess(createdRelation) : () => {},
      onError: () => {
        toast.error(tErrorTexts('createTicketRelationError'));
      },
    },
  );

  const handleInputChange = (inputValue: string, action?: InputActionMeta) => {
    if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
      setTicketQuery(inputValue);
    }
  };

  const apply = () => {
    if (otherTicket && relation) {
      const isForward = relation.direction === 'forward';
      const currentId = isForward ? currentTicket.id : otherTicket.id;
      const targetRef: RelationRefDto = {
        id: isForward ? otherTicket.id.toString() : currentTicket.id.toString(),
      } as RelationRefDto;
      const newRelation = {
        target: targetRef,
        type: relation.type.identifier,
      } as CreateRelationDto;

      mutate({
        body: newRelation,
        path: MutationPath.CreateTicketRelation(currentId.toString()),
      });
    }
  };

  useShortcut(KeyCombination.CancelAction, onCancel);

  return (
    <ListItemWrapper
      field="create-ticket-relation"
      onAccept={apply}
      onCancel={onCancel}
      mode="update"
      isLoading={isCreating}
      deletePermission={UserResourcePermissions.TicketRelation.Create}
    >
      <div className="ticket-relation-list">
        <div>
          <SearchDropdown<RelationSpec | undefined>
            mapValueToSelectOption={mapRelationSpecToOption(translateRelation)}
            onChange={(opt) => setRelation(mapOptionToRelationSpec(opt))}
            value={relation}
            options={mapRelationSpecsToOptions(translateRelation)(relationSpecs)}
            dataRole="relation-type-select"
            autoFocus
            noOptionsMessage={t('noRelationType')}
            isLoadingMessage={t('relationTypesAreLoading')}
            placeholder={t('selectRelationType')}
            requiredPermission={UserResourcePermissions.RelationType.Read}
            isClearable
            isSmall
          />
        </div>
        <div
          onKeyUp={useShortcutEventHandler(KeyCombination.Submit, () =>
            otherTicket && relation ? apply() : undefined,
          )}
        >
          <SearchDropdown<BaseTicket | null>
            dataRole="target-ticket-select"
            isError={isTicketError}
            onChange={(value) => setOtherTicket(mapOptionToTicket(value))}
            onInputChange={handleInputChange}
            isLoading={isTicketLoading}
            options={mapTicketsToOptions(ticketsPaged?.content)}
            mapValueToSelectOption={mapTicketToOption}
            value={otherTicket}
            noOptionsMessage={t('noTargetTicketSuggestion')}
            isLoadingMessage={t('targetTicketsAreLoading')}
            placeholder={t('selectTargetTicket')}
            requiredPermission={UserResourcePermissions.Ticket.Read}
            isClearable
            isSmall
          />
        </div>
      </div>
    </ListItemWrapper>
  );
}

export default CreateTicketRelation;
