import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { CreateContractModel, PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { EditSupportContactPerson } from './EditSupportContactPerson';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { ContractRole } from '../../../models/contract-management/ContractRole';
import { defaultSupportContactPerson } from '../../../models/contract-management/SupportContactPersonModel';

interface CreateSupportContactPersonFormCollapsibleProps {
  form: UseFormReturn<CreateContractModel>;
  contractRoles: ContractRole[] | undefined;
}

export function CreateSupportContactPersonFormCollapsible({
  form,
  contractRoles,
}: CreateSupportContactPersonFormCollapsibleProps) {
  const { control } = form;
  const { append, fields, remove } = useFieldArray({ control, name: `supportContactPersons` });
  const { t } = useTranslationText('contractManagements');

  return (
    <Collapsible
      collapsibleKey="supportContactPersons"
      initialExpanded
      title={t('supportContactPerson')}
      badgeCount={fields.length}
      addBtnClicked={() => append(defaultSupportContactPerson)}
      hasNoItems={fields.length === 0}
      addPermission={UserResourcePermissions.Contract.Create}
      dataRole="support-contact-persons"
    >
      {fields.map((field, index: number) => (
        <EditSupportContactPerson
          // TODO: Leider bisher keine bessere Lösung als diese type assertion gefunden und für besser befunden als 2 Komponenten, die bis auf den UseFormReturn-Typ identisch sind
          form={form as UseFormReturn<PatchContractModel>}
          key={field.id}
          index={index}
          onRemove={() => remove(index)}
          contractRoles={contractRoles}
        />
      ))}
    </Collapsible>
  );
}
