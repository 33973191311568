import { useState } from 'react';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { mapComponentToOption, mapOptionToComponent } from '../../../models/operation/TicketFunctions';
import { loadComponentsOptions } from '../../../react-query/ComponentApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import useCustomFetch from '../../../react-query/CustomFetch';
import JSXSearchDropdownPaginated from '../../../ui/jsx-search-dropdown-paginated/JSXSearchDropdownPaginated';
import { BaseComponentItem } from '../../../models/operation/ComponentModel';
import { JSXSelectOption } from '../../../ui/jsx-search-dropdown/JSXSearchDropdown';
import { useCurrentTenant } from '../../../user/tenant-context/CurrentTenantContext';

export interface ComponentSuggestion {
  id: string;
  displayName: string;
  componentTypeId: string;
}

interface SingleComponentSelectProps {
  onChange: (component: BaseComponentItem | null) => void;
  value: BaseComponentItem | null;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export default function SingleComponentSelect({
  onChange,
  value,
  label,
  isRequired,
  isDisabled = false,
}: SingleComponentSelectProps) {
  const { t } = useTranslationText('tickets');
  const [isError, setIsError] = useState(false);
  const customFetch = useCustomFetch();
  const [inputValue, setInputValue] = useState<string>();

  const { currentTenant } = useCurrentTenant();

  const loadOptions = async (searchInput: string, prev?: JSXSelectOption[]) =>
    loadComponentsOptions(searchInput, prev, setIsError, customFetch);

  return (
    <JSXSearchDropdownPaginated<BaseComponentItem>
      dataRole="assigned-component-select"
      onChange={(newValue) => {
        onChange(mapOptionToComponent(newValue));
      }}
      noOptionsMessage={t('noSuggestionsAssignedComponents')}
      isLoadingMessage={t('componentsAreLoading')}
      mapValueToSelectOption={mapComponentToOption}
      value={value ?? undefined}
      placeholder={t('addAssignedComponent')}
      requiredPermission={UserResourcePermissions.Ticket.Create}
      hideDropDownButton
      isClearable
      label={label ?? t('assignedComponent')}
      loadOptions={loadOptions}
      isError={isError}
      inputValue={inputValue}
      onInputChange={setInputValue}
      isRequired={isRequired}
      isDisabled={isDisabled}
      cacheUniqs={[currentTenant]}
    />
  );
}
