import { useCallback } from 'react';
import { FieldPath, Controller, ControllerRenderProps, FieldError, UseFormReturn } from 'react-hook-form';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextArea from '../../ui/text-area/TextArea';
import TextInput from '../../ui/text-input/TextInput';

interface AttributeValueSelectorFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
  queryError: FieldError | undefined;
  queryDataRole?: string;
  query: FieldPath<ComponentTypeModel>;
  labelError: FieldError | undefined;
  labelDataRole?: string;
  label: FieldPath<ComponentTypeModel>;
}

export default function AttributeValueSelectorForm({
  form,
  queryError,
  queryDataRole,
  query,
  labelError,
  labelDataRole,
  label,
}: AttributeValueSelectorFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control } = form;

  const QueryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, any> }) => (
      <FormFieldWrapper error={queryError}>
        <TextArea
          placeholder={t('attributeValueSelectorPlaceholder')}
          dataRole={queryDataRole}
          onValueChange={field.onChange}
          value={field.value}
          error={queryError}
          label={t('attributeValueSelectorLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [queryDataRole, queryError, t]
  );

  const LabelInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, any> }) => (
      <FormFieldWrapper error={labelError}>
        <TextInput
          placeholder={t('attributeValueLabelPlaceholder')}
          dataRole={labelDataRole}
          onValueChange={field.onChange}
          value={field.value}
          error={labelError}
          label={t('attributeValueLabelLabel')}
        />
      </FormFieldWrapper>
    ),
    [labelDataRole, labelError, t]
  );

  return (
    <>
      <Controller name={query} control={control} render={QueryInput} />
      <Controller name={label} control={control} render={LabelInput} />
    </>
  );
}
