import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
  AutomationRule,
  AutomationRuleWithOptionalId,
  CreateAutomationRule,
} from '../../models/operation/AutomationRuleModel';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import RuleEditor from '../RuleEditor';

export default function CreateRule() {
  const navigate = useNavigate();
  const { t: tError } = useTranslationText('errorTexts');

  const { mutate, isPending } = usePostMutation<CreateAutomationRule, AutomationRule>(MutationKey.PostRule, {
    onSuccess: () => navigate(-1),
    onError: () => {
      toast.error(tError('ruleCouldNotBeSaved'));
    },
  });

  const onSubmit = (createRuleModel: AutomationRuleWithOptionalId) => {
    mutate({
      body: {
        name: createRuleModel.name,
        description: createRuleModel.description,
        active: createRuleModel.active,
        actions: createRuleModel.actions,
        conditions: createRuleModel.conditions,
        trigger: createRuleModel.trigger!,
        createdBy: '',
        lastModified: '',
      },
      path: MutationPath.CreateRule,
    });
  };

  return <RuleEditor permission={UserResourcePermissions.Rule.Create} onSubmit={onSubmit} isLoading={isPending} />;
}
