import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import {
  getSeverityIcon,
  mapOptionToSeverity,
  mapSeveritiesToOptions,
  mapSeverityToOption,
} from '../../../models/operation/TicketFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { useGetSeverities } from '../../../react-query/TicketingSystemApi';
import SingleSelectDropdown from '../../../ui/single-select-dropdown/SingleSelectDropdown';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { TicketSeverity } from '../../../models/operation/TicketSeveritiesModel';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { RuleAction } from '../../../models/operation/AutomationRuleModel';

export interface ChangeTicketSeverityConfiguration extends RuleAction {
  type: 'changeTicketSeverity';
  severity: string;
}

function formatedOptionLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="severity-icon-wrapper">{getSeverityIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

export default function ChangeTicketSeverityAction({
  configuration,
  handleConfigurationUpdate,
}: GenericActionConfigurationProps<ChangeTicketSeverityConfiguration>) {
  const [severity, setSeverity] = useState<TicketSeverity | null>();
  const { data: severities, isLoading, isError } = useGetSeverities();
  const { t } = useTranslationText('tickets');

  useEffect(() => {
    const key = configuration?.severity;
    if (key) {
      setSeverity({ key, translationKey: severities?.find((s) => s.key === key)?.translationKey ?? '' });
    } else {
      setSeverity(null);
    }
  }, [configuration, severities]);

  const handleChange = (option: SingleValue<ReactSelectOption>) => {
    setSeverity(mapOptionToSeverity(severities, option));
    if (option?.value) {
      handleConfigurationUpdate({
        type: 'changeTicketSeverity',
        severity: option.value,
      } as ChangeTicketSeverityConfiguration);
    }
  };

  return (
    <SingleSelectDropdown
      dataRole="rule-severities-dropdown"
      isLoading={isLoading}
      isError={isError}
      onChange={handleChange}
      options={mapSeveritiesToOptions(t, severities)}
      requiredPermission={UserResourcePermissions.Rule.CreateOrUpdate}
      placeholder={t('selectSeverities')}
      value={severity ? mapSeverityToOption(t, severity) : null}
      label={t('fieldSeverity')}
      formatOptionLabel={formatedOptionLabel}
    />
  );
}
