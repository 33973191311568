import React from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { MutationKey, MutationPath, useDeleteMutation } from '../../../react-query/MutationQueries';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { invalidateAllContractors } from '../../../react-query/InvalidationQueries';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import ActionBar from '../../../ui/action-bar/ActionBar';
import { Dropdown } from '../../../ui/dropdown/Dropdown';
import { useGetContractorById, useGetContractorHasContracts } from '../../../react-query/ContractManagementSystemApi';
import Modal from '../../../ui/modal/Modal';
import useToggle from '../../../custom-hooks/useToggle';
import { useAuthUser } from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import useParamId from '../../../custom-hooks/UseParamId';
import ContractorEdit from './ContractorEdit';
import './ContractorDetails.scss';

export default function ContractorDetails() {
  const { id: contractorId } = useParamId();
  const { data: hasContracts, isLoading: isLoadingHasContracts, isError } = useGetContractorHasContracts(contractorId);
  const { data: contractor, isLoading: isLoadingContractor, refetch } = useGetContractorById(contractorId);
  const { t } = useTranslationText('contractManagements');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isConfirmationModalVisible, toggleConfirmationModal] = useToggle(false);
  const { hasPermission } = useAuthUser();

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteContractor, {
    onSuccess: async () => {
      await invalidateAllContractors(queryClient).then(() => navigate(NavigationRoutes.AllContractors));
      toast.success(tSuccess('deleteContractor'));
    },
    onError: () => {
      toast.error(`${tError('contractorDeleteError')}`);
    },
  });

  const checkHasContracts = () => {
    if (!isLoadingHasContracts && hasContracts) {
      toast.error(`${tError('contractorHasContractsError')}`);
    } else toggleConfirmationModal();
  };

  const onDelete = () => {
    deleteMutate({ path: MutationPath.DeleteContractor(contractorId) });
  };

  const onChange = async () => {
    await refetch();
  };

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Contractor.Delete)) {
      renderItems.push(
        <button
          className="dropdown-item delete-contractor-button"
          type="button"
          data-role="delete-contractor-button"
          onClick={checkHasContracts}
          key="delete-contractor-button"
          disabled={isLoadingHasContracts}
        >
          {t('deleteContractor')}
        </button>,
      );
    }

    return renderItems;
  };

  return (
    <LoadingSpinner
      isLoading={isLoadingHasContracts || isLoadingContractor}
      errors={isError ? <Trans i18nKey="errorTexts.404_contract.text" values={{ id: contractorId }} /> : undefined}
    >
      <ActionBar right={<Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />} />
      {contractor && <ContractorEdit contractor={contractor} onChange={onChange} />}

      <Modal
        isVisible={isConfirmationModalVisible}
        title={tCommon('deleteContractor')}
        confirmBtnText={tCommon('delete')}
        cancelBtnText={tCommon('back')}
        onCancel={() => toggleConfirmationModal()}
        onClose={() => toggleConfirmationModal()}
        onConfirm={onDelete}
        data-role="contractor-modal"
      >
        <p>{tCommon('warningContractorDelete')}</p>
      </Modal>
    </LoadingSpinner>
  );
}
