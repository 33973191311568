import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import AlertingRulesForm from './AlertingRulesForm';
import { Criticality } from '../../components/active-alert-overview/Criticality';

interface AlertingRulesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function AlertingRulesFormCollapsible({ form }: AlertingRulesFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `alertingRules`,
  });

  return (
    <Collapsible
      collapsibleKey="componentTypesAlertingRules"
      initialExpanded
      title={t('alertingRules')}
      badgeCount={fields.length}
      addBtnClicked={() =>
        append({
          id: undefined,
          query: '',
          status: 'HEALTHY',
          criticality: Criticality.NONE,
          title: '',
          description: '',
          notifyAfterSeconds: undefined,
          ticketGenerationEnabled: true,
        })
      }
      hasNoItems={fields.length === 0}
      addPermission={UserResourcePermissions.ComponentType.Create}
      dataRole="component-type-alerting-rules"
    >
      {fields.map((field, index: number) => (
        <AlertingRulesForm form={form} key={field.id} index={index} onRemove={() => remove(index)} />
      ))}
    </Collapsible>
  );
}
