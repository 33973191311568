import React from 'react';
import './StatusTable.scss';
import StreetLoadingSpinner from '../ui/street-loading-spinner/StreetLoadingSpinner';
import { useTranslationInit } from '../translation/TranslationHooks';
import StatusTable from './StatusTable';
import Header from '../ui/header/Header';

export default function StatusPage() {
  const [isI18nInit, isLoadingI18] = useTranslationInit();

  return (
    <StreetLoadingSpinner isLoading={isLoadingI18 || !isI18nInit}>
      <Header />
      <StatusTable />
    </StreetLoadingSpinner>
  );
}
