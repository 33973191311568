import { Trans } from 'react-i18next';
import { Ticket } from '../../models/operation/TicketModel';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Collapsible from '../../ui/collapsible/Collapsible';
import { useGetAllTickets } from '../../react-query/TicketingSystemApi';

interface ComponentAssociatedTicketsProps {
  id: string;
  title: string;
  renderElement: (ticket: Ticket, index: number) => JSX.Element;
}

export function ComponentAssociatedTickets({ id, title, renderElement }: ComponentAssociatedTicketsProps) {
  const {
    data: associatedTickets,
    isLoading: isTicketsLoading,
    isError: isGetAllTicketsError,
  } = useGetAllTickets({}, { components: [id], states: ['TODO', 'WORKING', 'WAIT'] });

  return (
    <LoadingSpinner
      isLoading={isTicketsLoading}
      errors={
        isGetAllTicketsError ? <Trans i18nKey="errorTexts.404_ticketingSystem.text" values={{ id }} /> : undefined
      }
    >
      {!!associatedTickets?.totalElements && (
        <Collapsible collapsibleKey="collapsible-associated-tickets" title={title} initialExpanded>
          <div className="tickets">{associatedTickets.content.map(renderElement)}</div>
        </Collapsible>
      )}
    </LoadingSpinner>
  );
}
