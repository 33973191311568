import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CreateContractorModel } from '../../../models/contract-management/ContractorModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import {
  AssigneeIdsInput,
  CityInput,
  CompanyNameInput,
  EmailsInput,
  FaxNumberInput,
  HouseNumberInput,
  PhoneNumberInput,
  PostalCodeInput,
  StreetInput,
} from './CreateContractorInputs';

interface CreateContractorFormProps {
  form: UseFormReturn<CreateContractorModel, object>;
}

export function CreateContractorForm({ form }: CreateContractorFormProps) {
  const { t } = useTranslationText('contractManagements');
  const { control, formState } = form;

  return (
    <>
      <div className="column is-6">
        <Controller
          name="companyName"
          control={control}
          defaultValue=""
          render={({ field }) => <CompanyNameInput field={field} t={t} formState={formState} />}
        />
        <div className="horizontal-wrapper">
          <div className="three-quarters">
            <Controller
              name="street"
              control={control}
              defaultValue=""
              render={({ field }) => <StreetInput field={field} t={t} formState={formState} />}
            />
          </div>
          <div className="one-quarter">
            <Controller
              name="houseNumber"
              control={control}
              defaultValue=""
              render={({ field }) => <HouseNumberInput field={field} t={t} formState={formState} />}
            />
          </div>
        </div>
        <div className="horizontal-wrapper">
          <div className="one-quarter">
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => <PostalCodeInput field={field} t={t} formState={formState} />}
            />
          </div>
          <div className="three-quarters">
            <Controller
              name="city"
              control={control}
              render={({ field }) => <CityInput field={field} t={t} formState={formState} />}
            />
          </div>
        </div>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => <PhoneNumberInput field={field} t={t} formState={formState} />}
        />
        <Controller
          name="faxNumber"
          control={control}
          render={({ field }) => <FaxNumberInput field={field} t={t} formState={formState} />}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => <EmailsInput field={field} t={t} formState={formState} />}
        />
      </div>
      <div className="column is-6">
        <Controller
          name="assigneeIds"
          control={control}
          defaultValue={[]}
          render={({ field }) => <AssigneeIdsInput field={field} t={t} formState={formState} />}
        />
      </div>
    </>
  );
}
