import queryString from 'query-string';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { HistoryModel, Ticket, TicketCommentModel } from '../models/operation/TicketModel';
import { TicketSeverities } from '../models/operation/TicketSeveritiesModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import { TicketStates } from '../models/operation/TicketStatesModel';
import { UserFilterModel, UserModel } from '../models/operation/UserModel';
import { queryStringFromObject } from './QueryUtils';
import { Page } from '../models/pagination/Pagination';
import { TicketQuery } from '../models/operation/TicketQuery';
import { MediaUrlBuilders } from '../models/media/Media';
import { TaskDto } from '../models/operation/TaskDto';
import { TaskQuery } from '../models/tasks/TaskQuery';
import { StrapiFile } from '../models/strapi/StrapiFile';
import { TaskStates } from '../models/operation/TaskStateModel';
import { AssociatedAlertQuery } from '../models/operation/AssociatedAlertQuery';
import { ServiceTime } from '../models/operation/ServiceTime';
import { TicketTypes } from '../models/operation/TicketTypesModel';
import { TicketFilterModel } from '../models/operation/TicketFilterModel';

export const TicketingApiPrefix = '/api/ticketing';

export function useGetSeverities(options?: QueryOptions<TicketSeverities>) {
  return useGetQuery<TicketSeverities>(
    ServerStateKeysEnum.TicketSeverities,
    `${TicketingApiPrefix}/ticket/severity`,
    options,
  );
}

export function useGetStates(options?: QueryOptions<TicketStates>) {
  return useGetQuery<TicketStates>(ServerStateKeysEnum.TicketStates, `${TicketingApiPrefix}/ticket/state`, options);
}

export function useGetTypes(options?: QueryOptions<TicketTypes>) {
  return useGetQuery<TicketTypes>(ServerStateKeysEnum.TicketTypes, `${TicketingApiPrefix}/ticket/type`, options);
}

export function useGetTasksByTicketId(ticketId: number, options?: QueryOptions<TaskDto[]>) {
  return useGetQuery<TaskDto[]>(
    [ServerStateKeysEnum.TicketTask, ticketId],
    `${TicketingApiPrefix}/ticket/${ticketId}/task`,
    options,
  );
}

export function useGetTicket(ticketId: number, options?: QueryOptions<Ticket>) {
  return useGetQuery<Ticket>(
    [ServerStateKeysEnum.TicketDetails, ticketId],
    `${TicketingApiPrefix}/ticket/${ticketId}`,
    options,
  );
}

export function pathForAllTicketsWithFilter(filter?: Partial<TicketQuery>) {
  const queryParams = filter ? `?${queryString.stringify(filter)}` : '';
  return `${TicketingApiPrefix}/ticket${queryParams}`;
}

export function useGetAllTickets(options?: QueryOptions<Page<Ticket>>, query?: Partial<TicketQuery>) {
  const path = pathForAllTicketsWithFilter(query);
  return useGetQuery<Page<Ticket>>([ServerStateKeysEnum.TicketAll, query], path, options);
}

export function useGetTicketHistory(
  ticketId: number,
  lastHistoryUpdate: number,
  options?: QueryOptions<HistoryModel[]>,
) {
  return useGetQuery<HistoryModel[]>(
    [ServerStateKeysEnum.TicketHistory, ticketId, lastHistoryUpdate],
    `${TicketingApiPrefix}/ticket/${ticketId}/history`,
    options,
  );
}

export function useGetAssociatedAlertIds(
  ticketId: number,
  query?: Partial<AssociatedAlertQuery>,
  options?: QueryOptions<Page<string>>,
) {
  const queryParams = query ? `?${queryString.stringify(query)}` : '';
  return useGetQuery<Page<string>>(
    [ServerStateKeysEnum.TicketAssociatedAlerts, ticketId, query],
    `${TicketingApiPrefix}/ticket/${ticketId}/alert${queryParams}`,
    options,
  );
}

export function useGetTaskHistory(taskId: number, options?: QueryOptions<HistoryModel[]>) {
  return useGetQuery<HistoryModel[]>(
    [ServerStateKeysEnum.TaskHistory, taskId],
    `${TicketingApiPrefix}/task/${taskId}/history`,
    options,
  );
}

export function useGetTicketComments(ticketId: number, options?: QueryOptions<TicketCommentModel[]>) {
  return useGetQuery<TicketCommentModel[]>(
    [ServerStateKeysEnum.TicketComments, ticketId],
    `${TicketingApiPrefix}/ticket/${ticketId}/comment`,
    options,
  );
}

export function useGetTaskComments(taskId: number, options?: QueryOptions<TicketCommentModel[]>) {
  return useGetQuery<TicketCommentModel[]>(
    [ServerStateKeysEnum.TaskComments, taskId],
    `${TicketingApiPrefix}/task/${taskId}/comment`,
    options,
  );
}

export function pathForAllTasksWithFilter(filter?: Partial<TaskQuery>) {
  const queryParams = filter ? `?${queryString.stringify(filter)}` : '';
  return `${TicketingApiPrefix}/task${queryParams}`;
}

export function useGetAllTasks(options?: QueryOptions<Page<TaskDto>>, query?: TaskQuery) {
  const path = pathForAllTasksWithFilter(query);
  return useGetQuery<Page<TaskDto>>([ServerStateKeysEnum.Tasks, query], path, options);
}

export function useGetTaskStates(options?: QueryOptions<TaskStates>) {
  return useGetQuery<TaskStates>(ServerStateKeysEnum.TaskStates, `${TicketingApiPrefix}/task/state`, options);
}

export function useGetTaskById(taskId: number, options?: QueryOptions<TaskDto>) {
  return useGetQuery<TaskDto>([ServerStateKeysEnum.Task, taskId], `${TicketingApiPrefix}/task/${taskId}`, options);
}

export function useGetTaskMediaByTicketId(ticketId: number, options?: QueryOptions<StrapiFile[]>) {
  return useGetQuery<StrapiFile[]>(
    [ServerStateKeysEnum.TaskMedia, ticketId],
    `${TicketingApiPrefix}/taskMedia/${ticketId}`,
    options,
  );
}

export function useGetUsers(options?: QueryOptions<Page<UserModel>>, filter?: UserFilterModel) {
  const queryParams = filter ? `?${queryStringFromObject(filter)}` : '';
  const path = `${TicketingApiPrefix}/user${queryParams}`;
  return useGetQuery<Page<UserModel>>(
    [ServerStateKeysEnum.TicketUserAll, queryStringFromObject(filter)],
    path,
    options,
  );
}

export function useGetUser(userId: string, options?: QueryOptions<UserModel>) {
  const path = `${TicketingApiPrefix}/user/${userId}`;
  return useGetQuery<UserModel>([ServerStateKeysEnum.TicketUser, userId], path, options);
}

export function useTicketQuickSearch(searchValue: string, size: number, options?: QueryOptions<Page<Ticket>>) {
  const path = `${TicketingApiPrefix}/ticket/search?query=${searchValue}&size=${size}`;
  return useGetQuery<Page<Ticket>>([ServerStateKeysEnum.TicketSearch, searchValue, size.toString()], path, {
    ...options,
    enabled: searchValue.length > 0,
  });
}

export function useGetReactionTimeForTask(taskId: number, options?: QueryOptions<ServiceTime>) {
  const path = `${TicketingApiPrefix}/task/${taskId}/service-time/reaction-time`;
  return useGetQuery<ServiceTime>([ServerStateKeysEnum.ServiceTimeReaction, taskId], path, options);
}

export function useGetRestorationTimeForTask(taskId: number, options?: QueryOptions<ServiceTime>) {
  const path = `${TicketingApiPrefix}/task/${taskId}/service-time/restoration-time`;
  return useGetQuery<ServiceTime>([ServerStateKeysEnum.ServiceTimeRestoration, taskId], path, options);
}

export function pathForAllFilter(filter?: Partial<TicketFilterModel>) {
  const queryParams = filter ? `?${queryString.stringify(filter)}` : '';
  return `${TicketingApiPrefix}/filter${queryParams}`;
}

export function useGetTicketFilter(options?: QueryOptions<TicketFilterModel[]>, query?: TicketFilterModel) {
  const path = pathForAllFilter(query);
  return useGetQuery<TicketFilterModel[]>([ServerStateKeysEnum.TicketFilter, query], path, options);
}

export const TicketMediaUrlBuilders: MediaUrlBuilders = {
  mediaSource: (f) => `${TicketingApiPrefix}/ticketMedia/${f.id}/data`,
  mediaThumbnail: (f) => `${TicketingApiPrefix}/ticketMedia/${f.id}/thumbnail/data`,
  mediaDownload: (f) => `${TicketingApiPrefix}/ticketMedia/${f.id}/download`,
};
export const TaskMediaUrlBuilders: MediaUrlBuilders = {
  mediaSource: (f) => `${TicketingApiPrefix}/taskMedia/${f.id}/data`,
  mediaThumbnail: (f) => `${TicketingApiPrefix}/taskMedia/${f.id}/thumbnail/data`,
  mediaDownload: (f) => `${TicketingApiPrefix}/taskMedia/${f.id}/download`,
};
