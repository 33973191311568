/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import MultiValueInput from '../../ui/multi-value-input/MultiValueInput';
import TextArea from '../../ui/text-area/TextArea';

interface ComponentSelectorFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function ComponentSelectorForm({ form }: ComponentSelectorFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control, formState } = form;

  const QueryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'componentSelector.query'> }) => (
      <FormFieldWrapper error={formState.errors?.componentSelector?.query}>
        <TextArea
          placeholder={t('componentSelectorQueryPlaceholder')}
          dataRole="component-type-component-selector-query"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.componentSelector?.query}
          label={t('componentSelectorQueryLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors.componentSelector?.query]
  );

  const LabelsInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'componentSelector.labels'> }) => (
      <FormFieldWrapper error={formState.errors?.componentSelector?.labels}>
        <MultiValueInput
          placeholder={t('componentSelectorLabelsPlaceholder')}
          dataRole="component-type-component-selector-labels"
          onChange={field.onChange}
          values={field.value}
          isError={formState.errors?.componentSelector?.labels !== undefined ?? false}
          label={t('componentSelectorLabelsLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors.componentSelector?.labels]
  );

  return (
    <Collapsible collapsibleKey="component-selector" initialExpanded title={t('componentSelector')}>
      <Controller name="componentSelector.query" control={control} render={QueryInput} />
      <Controller name="componentSelector.labels" control={control} render={LabelsInput} />
    </Collapsible>
  );
}
