import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Actor } from '../../models/actor/Actor';
import DateFormats from '../../models/date-formats/DateFormats';
import { BaseMaintenance } from '../../models/maintenance/Maintenance';
import { useGetUser } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../ui/text-input/TextInput';

interface InformationProps {
  form: UseFormReturn<BaseMaintenance>;
}

export default function Information({ form }: InformationProps) {
  const { t } = useTranslationText('maintenances');

  const { formState, control, getValues } = form;

  const author: Actor | undefined = getValues('author');

  const { data: authorUser } = useGetUser(author?.id ?? '', {
    enabled: !!author?.id,
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  const ActionNumberInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'actionNumber'> }) => (
      <FormFieldWrapper error={formState.errors?.actionNumber}>
        <TextInput label={t('actionNumber')} value={field.value} onValueChange={field.onChange} readOnly />
      </FormFieldWrapper>
    ),
    [formState.errors?.actionNumber, t],
  );

  const AuthorInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'author'> }) => (
      <FormFieldWrapper error={formState.errors?.author}>
        <TextInput
          label={t('author')}
          value={`${authorUser?.firstName} ${authorUser?.lastName}`}
          onValueChange={field.onChange}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.author, authorUser, t],
  );

  const CreatedAtInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'createdAt'> }) => (
      <FormFieldWrapper error={formState.errors?.createdAt}>
        <TextInput
          label={t('createdAt')}
          value={dayjs(field.value).format(DateFormats.FULLDATE_HOURS_MINS)}
          onValueChange={field.onChange}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.createdAt, t],
  );
  const StatusInput = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'status'> }) => (
      <FormFieldWrapper error={formState.errors?.status}>
        <TextInput label={t('status')} value={t(field.value ?? '')} onValueChange={field.onChange} readOnly />
      </FormFieldWrapper>
    ),
    [formState.errors?.status, t],
  );

  return (
    <Collapsible collapsibleKey="information" initialExpanded title={t('information')}>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="actionNumber" control={control} render={ActionNumberInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="author" control={control} render={AuthorInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="createdAt" control={control} render={CreatedAtInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="status" control={control} render={StatusInput} />
        </div>
      </div>
    </Collapsible>
  );
}
