import { Table as AosTable } from '@aos/styleguide-react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetKnowledgeBaseArticles } from '../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import ActionBar from '../../ui/action-bar/ActionBar';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import './KnowledgeBaseTable.scss';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { getNextSortState } from '../../ui/table-sort/TableSort';
import { ArticleSort } from '../../models/knowledge-base/ArticleQuery';

const tableColumns: TableColumn[] = [
  { property: 'title', translationKey: 'title', width: '25%' },
  { property: 'descriptionShort', translationKey: 'descriptionShort' },
  { property: 'author', translationKey: 'author' },
];

export default function KnowledgeBaseTable() {
  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');

  const [query, setQuery] = useQueryParams({
    sort: withDefault(StringParam, ArticleSort.TitleDesc),
  });

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  const { data: knowledgeBaseArticles = [], isLoading, isError } = useGetKnowledgeBaseArticles(query);

  return (
    <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_articles') : undefined}>
      <div className="knowledge-base" data-role="knowledge-base">
        <ActionBar
          right={
            <UserRoleCheck requiredPermission={UserResourcePermissions.Article.Create}>
              <Link className="button is-primary" to="create">
                {t('createNewArticle')}
              </Link>
            </UserRoleCheck>
          }
        />
        {knowledgeBaseArticles.length ? (
          <AosTable>
            <TableHeader
              columns={tableColumns}
              onSortChange={onSortChange}
              translationSection="knowledgeBases"
              currentSort={query.sort || undefined}
            />
            <AosTable.Body>
              {knowledgeBaseArticles.map((article) => (
                <AosTable.Row className="is-clickable" key={article.id} data-id={article.id} data-role="article-item">
                  <LinkTableCell to={article.id.toString()} dataRole="titel-cell">
                    {article.title}
                  </LinkTableCell>
                  <LinkTableCell to={article.id.toString()} dataRole="shortdesc-cell">
                    {article.descriptionShort}
                  </LinkTableCell>
                  <LinkTableCell to={article.id.toString()} dataRole="content-cell">
                    {article.author}
                  </LinkTableCell>
                </AosTable.Row>
              ))}
            </AosTable.Body>
          </AosTable>
        ) : (
          <>{t('noArticles')}</>
        )}
      </div>
    </LoadingSpinner>
  );
}
