import { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import { ActionMeta, MenuPlacement, MultiValueGenericProps, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './SingleSelectCreatableDropdown.scss';

interface ReactSelectOption<T> {
  label: string;
  value: T;
}

interface SingleSelectCreatableDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  createLabel: (inputValue: string) => ReactNode;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string;
  onChange: (newValue: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) => void;
  onCreateOption: (inputValue: string) => void;
  placeholder?: string;
  value?: ReactSelectOption<T> | null;
  options: ReactSelectOption<T>[];
  menuPlacement?: MenuPlacement | undefined;
  isValidNewOption?: (inputValue: string, options: readonly ReactSelectOption<T>[]) => boolean;
  isRequired?: boolean;
}

export default function SingleSelectCreatableDropdown<T>({
  dataRole,
  isError,
  createLabel,
  isLoading,
  label,
  noOptionsMessage,
  onChange,
  onCreateOption,
  placeholder,
  value,
  options,
  menuPlacement,
  isValidNewOption,
  isRequired = false,
}: SingleSelectCreatableDropdownProps<T>) {
  const FormatCreateLabel = useCallback(
    (inputValue: string) => <div data-role="single-select-create-button">{createLabel(inputValue)}</div>,
    [createLabel],
  );

  const MultiValueLabel = useCallback(
    ({ innerProps, data }: MultiValueGenericProps<ReactSelectOption<T>>) => (
      <div {...innerProps} data-role={`label-${data.label}`}>
        {data.label}
      </div>
    ),
    [],
  );

  return (
    <div className="field" data-role={dataRole}>
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control">
        <CreatableSelect
          className={classNames({
            'react-select-container': true,
            'react-select-container-error': isError,
          })}
          classNamePrefix="react-select"
          components={{ MultiValueLabel }}
          formatCreateLabel={FormatCreateLabel}
          isClearable
          isLoading={isLoading}
          isMulti={false}
          isValidNewOption={isValidNewOption ? (inputValue) => isValidNewOption(inputValue, options) : undefined}
          options={options}
          noOptionsMessage={() => noOptionsMessage}
          onChange={onChange}
          onCreateOption={onCreateOption}
          placeholder={placeholder}
          value={value}
          inputId="creatable-select-input"
          menuPlacement={menuPlacement}
        />
      </div>
    </div>
  );
}
