import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { mapContractItemsToOptions } from '../../../models/contract-management/ContractFunctions';
import { TaskQuery } from '../../../models/tasks/TaskQuery';
import { useGetAllContracts } from '../../../react-query/ContractManagementSystemApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';

interface ContractFilterProps {
  label?: string;
  selectedContracts: (string | null)[] | null | undefined;
  onChange: (name: keyof TaskQuery, value: string[]) => void;
  size?: BulmaSize;
}

function formatOptionLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export default function ContractFilter({ label, selectedContracts, onChange, size = 'is-small' }: ContractFilterProps) {
  const { t } = useTranslationText('tickets');

  const { data: contracts, isLoading: isLoadingContracts, isError: isContractError } = useGetAllContracts();

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'contractIds',
      selectedOptions.map((value) => value.value),
    );
  };

  const getSelectedContracts = () => contracts?.content.filter(({ id }) => selectedContracts?.includes(id));

  return (
    <MultiSelectDropdown
      dataRole="contract-select"
      onChange={handleOnChange}
      openMenuOnClick
      isSearchable
      options={mapContractItemsToOptions(contracts?.content)}
      isLoading={isLoadingContracts}
      isError={isContractError}
      placeholder={t('selectContract')}
      mappedValues={mapContractItemsToOptions(getSelectedContracts())}
      formatOptionLabel={formatOptionLabel}
      label={label}
      size={size}
    />
  );
}
