import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './Collapsible.scss';
import { faAngleDown, faAngleUp, faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '../badge/Badge';
import { useTranslationText } from '../../translation/TranslationHooks';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';

export interface CollapsibleProps {
  children: React.ReactNode;
  title: string;
  collapsibleKey: string;
  initialExpanded: boolean;
  badgeCount?: number;
  addBtnClicked?: () => void;
  saveBtnClicked?: () => void;
  isSaving?: boolean;
  isValid?: boolean;
  discardBtnClicked?: () => void;
  dirtyState?: boolean;
  addPermission?: UserPermission;
  hasNoItems?: boolean;
  noItemText?: string;
  dataRole?: string;
  className?: string;
  addButtonDisabled?: boolean;
}

export default function Collapsible({
  title,
  initialExpanded = true,
  children,
  badgeCount,
  collapsibleKey,
  saveBtnClicked,
  isSaving,
  isValid,
  discardBtnClicked,
  dirtyState = false,
  addBtnClicked,
  hasNoItems,
  noItemText,
  addPermission,
  dataRole,
  className,
  addButtonDisabled,
}: CollapsibleProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);
  const BadgeElement = badgeCount !== undefined && <Badge value={badgeCount} />;
  const { t } = useTranslationText('commons');

  useEffect(() => {
    setIsExpanded(initialExpanded);
  }, [initialExpanded]);

  const showNoItemText = () => {
    if (addBtnClicked) {
      return t('noItemsFoundAdd');
    }
    return noItemText ?? t('noItemsFound');
  };

  return (
    <div
      className={classNames('collapsible', 'container', className, {
        'is-dirty': dirtyState,
        'is-invalid': isValid === false,
      })}
    >
      <div
        className={classNames('collapsible-header', {
          expanded: isExpanded,
        })}
      >
        <button
          id={`${collapsibleKey}-button`}
          type="button"
          className="header-button collapse-button"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <span className="titleWithBadge">
            <div className="buttonToggle" aria-label="more options">
              {!isExpanded && <FontAwesomeIcon icon={faAngleDown} className="toggle" />}
              {isExpanded && <FontAwesomeIcon icon={faAngleUp} className="toggle" />}
            </div>
            <span>{title}</span>
            {BadgeElement}
          </span>
        </button>
      </div>
      <div id={`${collapsibleKey}-content`} className={classNames('collapsible-content', { collapsed: !isExpanded })}>
        {hasNoItems ? <span className="no-items-text">{showNoItemText()}</span> : children}

        <div className="collapsible-footer">
          <div />
          <div className="collapsible-add">
            {addBtnClicked && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    addBtnClicked();
                  }}
                  type="button"
                  data-role={`add-${dataRole}`}
                  className="add-item-btn"
                  disabled={addButtonDisabled}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="pl-2">{t('add')}</span>
                </button>
              </UserRoleCheck>
            )}
          </div>
          <div className="collapsible-action">
            {saveBtnClicked && dirtyState && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    saveBtnClicked();
                  }}
                  type="button"
                  data-role={`save-${dataRole}`}
                  className={classNames('button', 'footer-button', 'action-btn', 'save-btn', {
                    highlight: isExpanded && hasNoItems,
                    'is-loading': !!isSaving,
                  })}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </UserRoleCheck>
            )}
            {discardBtnClicked && dirtyState && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    discardBtnClicked();
                  }}
                  type="button"
                  data-role={`discard-${dataRole}`}
                  className={classNames('footer-button', 'action-btn ', {
                    highlight: isExpanded && hasNoItems,
                  })}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </UserRoleCheck>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Collapsible.defaultProps = {
  badgeCount: undefined,
};
