import { ObjectSchema } from 'yup';
import { LinkTypes, SystemModel, UpdateSystemModel } from '../models/operation/SystemModel';
import { YupType } from '../translation/YupLocal';

const isUniqueName = (arr: any[] | undefined) => {
  if (!arr) return true
  const names = arr.map((obj: { name: any }) => obj.name);
  const uniqueNames = new Set(names);
  return names.length === uniqueNames.size;
};

const systemValidationSchema = (validation: YupType, t: (key: string) => string) =>
  validation
    .object({
      name: validation.string().required().max(255).trim(),
      supportContactPerson: validation.string().required().max(255).trim(),
      supportContactAddress: validation.string().required().max(255).trim(),
      description: validation.string().max(255).trim(),
      integrationsscheinNaming: validation.string().max(255).trim(),
      stages: validation
        .array()
        .of(
          validation.object({
            name: validation.string().required().max(255).trim(),
            version: validation.string().max(255).trim(),
            lastUpdated: validation.date(),
            integrationsscheinNaming: validation.string().max(255).trim(),
            links: validation.array().of(
              validation.object({
                url: validation.string().required().max(255).trim(),
                linkType: validation.string().required().oneOf(LinkTypes),
              })
            ),
          })
        )
        .test('unique-name', t('environmentNamesMustBeUniquePerSystem'), (value) => isUniqueName(value)),
    })
    .required() as ObjectSchema<UpdateSystemModel | SystemModel>;

export default systemValidationSchema;
