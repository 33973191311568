import { useEffect } from 'react';

/* eslint-disable @typescript-eslint/no-unused-vars, no-console */
interface TooltipProps {
  anchorId: string;
  content: string;
  delayShow?: number;
}

// TODO ADB-235: Tooltips evaluieren, aktuell haben wir einen Quickfix.
export default function Tooltip({ anchorId, content, delayShow = 500 }: TooltipProps) {
  useEffect(() => {
    const element = document.getElementById(anchorId);
    if (element == null) {
      console.warn('Anchor element not found!');
    } else {
      element.setAttribute('title', content);
    }
  }, [anchorId, content]);

  return null;
}
