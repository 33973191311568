import { Table as AosTable } from '@aos/styleguide-react';
import { Link } from 'react-router-dom';
import { ArrayParam, StringParam, withDefault } from 'use-query-params';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import UserRoleCheck from '../../auth/UserRoleCheck';
import {
  MaintenanceQuery,
  maintenanceQueryParamConfigMap,
  MaintenanceSort,
} from '../../models/maintenance/MaintenanceQuery';
import { MaintenanceStatus } from '../../models/maintenance/MaintenanceStatus';
import useGetAllUsers from '../../react-query/getUsers';
import { useGetAllMaintenanceRequests } from '../../react-query/MaintenanceApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import MaintenanceRequestRow from './MaintenanceRequestRow';
import './MaintenanceRequests.scss';
import useQueryParamsWithPageReset from '../../custom-hooks/useQueryParamsWithPageReset';

export default function MaintenanceRequests() {
  const { t } = useTranslationText('maintenances');
  const { t: tError } = useTranslationText('errorTexts');

  const noMaintenanceRequestsFound = <p>{t('noMaintenanceRequestsFound')}</p>;

  const [query] = useQueryParamsWithPageReset({
    ...maintenanceQueryParamConfigMap,
    sort: withDefault(StringParam, MaintenanceSort.CreatedAtAsc),
    status: withDefault(ArrayParam, [MaintenanceStatus.REQUEST]),
  });

  const { data: maintenanceRequests, isLoading, isError } = useGetAllMaintenanceRequests({}, query as MaintenanceQuery);

  const { data: users, isError: isGetUserError } = useGetAllUsers(
    maintenanceRequests?.content?.map((maintenance) => maintenance.author?.id ?? '').filter((value) => value !== ''),
  );

  const addMaintenanceRequestButton = (
    <UserRoleCheck requiredPermission={UserResourcePermissions.MaintenanceRequest.Create}>
      <Link className="button is-primary" to="create">
        {t('createNewMaintenanceRequest')}
      </Link>
    </UserRoleCheck>
  );

  const tableColumns: TableColumn[] = [
    { property: 'actionNumber', translationKey: 'actionNumber', disableSort: true },
    { property: 'title', translationKey: 'title', disableSort: true },
    { property: 'author', translationKey: 'author', disableSort: true },
    { property: 'startOfAction', translationKey: 'startOfAction', disableSort: true },
    { property: 'lengthOfMaintenance', translationKey: 'lengthOfMaintenance', disableSort: true },
    { property: 'createdAt', translationKey: 'createdAt', disableSort: true },
  ];

  return (
    <>
      <ActionBar right={<>{addMaintenanceRequestButton}</>} />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_maintenance_requests') : undefined}>
        <AosTable>
          <TableHeader columns={tableColumns} translationSection="maintenances" currentSort={undefined} />
          <AosTable.Body>
            {maintenanceRequests?.content?.length
              ? maintenanceRequests.content.map((maintenance) => (
                  <MaintenanceRequestRow
                    key={maintenance.id}
                    maintenance={maintenance}
                    users={users}
                    isGetUserError={isGetUserError}
                  />
                ))
              : noMaintenanceRequestsFound}
          </AosTable.Body>
        </AosTable>
      </LoadingSpinner>
    </>
  );
}
