import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormState } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { Agent } from '../../../models/monitoring/Agent';
import { useTranslationText } from '../../../translation/TranslationHooks';
import ActionBar from '../../../ui/action-bar/ActionBar';

interface AgentDetailsActionBarProps {
  formState: FormState<Agent>;
  isLoading: boolean;
}

export function AgentDetailsActionBar({ formState, isLoading }: AgentDetailsActionBarProps) {
  const { t } = useTranslationText('agents');
  return (
    <ActionBar
      right={
        <UserRoleCheck requiredPermission={UserResourcePermissions.Agent.Update}>
          <button
            className={classNames('button is-primary text-icon-button', { 'is-loading': isLoading })}
            type="submit"
            aria-label="save-button"
            data-role="agent-save-button"
            disabled={!formState.isValid || !formState.isDirty}
          >
            <span>
              {t('update')}
              <FontAwesomeIcon icon={faSave} />
            </span>
          </button>
        </UserRoleCheck>
      }
    />
  );
}
