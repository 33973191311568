import './ActiveAlertFilter.scss';
import { MultiValue } from 'react-select';
import dayjs from 'dayjs';
import { DecodedValueMap } from 'use-query-params';
import CollapsableSideCard from '../../ui/collapsable-side-card/CollapsableSideCard';
import FilterResetButton from '../../ui/filter/FilterResetButton';
import { ActiveAlertFilterModel } from '../../models/operation/ActiveAlertFilterModel';
import ComponentTypeFilter from '../../ui/filter/component/ComponentTypeFilter';
import DatePicker from '../../ui/date-picker/DatePicker';
import ContractFilter from '../../ticketing/all-tasks/filter/ContractFilter';
import MultiSelectDropdown, { ReactSelectOption } from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ActiveAlertColumn } from '../../models/operation/AlertModel';
import FilterDropdown from '../../generic-components/filter-dropdown/FilterDropdown';
import { ActiveAlertQueryParamConfigModel } from '../../models/operation/ActiveAlertQuery';
import { useGetActiveAlertFilter } from '../../react-query/AlertApi';
import { MutationKey, MutationPath } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import { Criticality } from '../active-alert-overview/Criticality';
import { useColumnConfigContext } from '../active-alert-overview/ColumnConfig';
import CreateFilterModal from '../../generic-components/CreateFilterModal';

interface ActiveAlertTextFilterProps {
  label: string;
  selection: string | undefined;
  onChange: (key: string, value: string, remove: boolean) => void;
}

function ActiveAlertTextFilter({ label, selection, onChange }: ActiveAlertTextFilterProps) {
  return (
    <div>
      <label className="label" htmlFor={label}>
        {label}
      </label>
      <div className="control search-input-wrapper is-flex-grow-1">
        <input
          value={selection ?? ''}
          className="input search-input"
          placeholder={label}
          onChange={(e) => onChange(label, e.target.value, !e.target.value.length)}
        />
      </div>
    </div>
  );
}

interface ActiveAlertTimeStampFilterProps {
  label: string;
  handleFilterChange: (key: string, value: string, remove: boolean) => void;
  value: { after: string; until: string } | undefined;
}

function ActiveAlertTimeStampFilter({ label, handleFilterChange, value }: ActiveAlertTimeStampFilterProps) {
  return (
    <div>
      <DatePicker
        enableTime
        label={`${label} (von)`}
        value={value?.after ? dayjs(value.after).toDate() : undefined}
        onValueChange={(newDateTime) =>
          handleFilterChange(`${label}After`, dayjs(newDateTime).toISOString(), !newDateTime)
        }
        placeholder={`${label} (von)`}
      />
      <DatePicker
        enableTime
        label={`${label} (bis)`}
        value={value?.until ? dayjs(value.until).toDate() : undefined}
        onValueChange={(newDateTime) =>
          handleFilterChange(`${label}Until`, dayjs(newDateTime).toISOString(), !newDateTime)
        }
        placeholder={`${label} (bis)`}
      />
    </div>
  );
}

interface CriticalityFilterProps {
  label: string;
  selectedCriticalities: Criticality[];
  onChange: (criticalities: Criticality[]) => void;
}

function CriticalityFilter({ label, selectedCriticalities, onChange }: CriticalityFilterProps) {
  function mapToOption(criticalities: Criticality[]): ReactSelectOption<Criticality>[] {
    return (
      criticalities?.map((criticality) => ({
        label: criticality.valueOf(),
        value: criticality,
      })) ?? []
    );
  }

  const handleOnChange = (newSelectedCriticalities: MultiValue<ReactSelectOption<Criticality>>) =>
    onChange(newSelectedCriticalities.map((criticality) => criticality.value));

  return (
    <MultiSelectDropdown
      label={label}
      dataRole="column-select"
      options={mapToOption(Object.values(Criticality))}
      onChange={handleOnChange}
      mappedValues={mapToOption(Object.values(selectedCriticalities))}
      isSearchable
      openMenuOnClick
      placeholder={label}
    />
  );
}

interface ActiveAlertFilterElementProps {
  activeAlertFilter: ActiveAlertFilterModel;
  activeAlertColumn: ActiveAlertColumn;
  handleFilterChange: (key: string, value: string | string[], remove: boolean) => void;
}

function ActiveAlertFilterElement({
  activeAlertFilter,
  activeAlertColumn,
  handleFilterChange,
}: ActiveAlertFilterElementProps) {
  switch (activeAlertColumn.headerText) {
    case 'Komponententyp': {
      return (
        <div>
          <ComponentTypeFilter
            onChange={(newSelectedComponentTypes) =>
              handleFilterChange(
                activeAlertColumn.headerText,
                newSelectedComponentTypes,
                !newSelectedComponentTypes.length,
              )
            }
            label={activeAlertColumn.headerText}
            selectedComponentTypeIds={
              activeAlertFilter ? (activeAlertFilter[activeAlertColumn.headerText] as string[]) : null
            }
          />
        </div>
      );
    }
    case 'Zeitstempel': {
      return (
        <ActiveAlertTimeStampFilter
          label={activeAlertColumn.headerText}
          handleFilterChange={handleFilterChange}
          value={
            activeAlertFilter
              ? {
                  after: activeAlertFilter[`${activeAlertColumn.headerText}After`] as string,
                  until: activeAlertFilter[`${activeAlertColumn.headerText}Until`] as string,
                }
              : undefined
          }
        />
      );
    }
    case 'Vertrag': {
      return (
        <div>
          <ContractFilter
            selectedContracts={activeAlertFilter ? (activeAlertFilter[activeAlertColumn.headerText] as string[]) : null}
            onChange={(_, selectedContracts) =>
              handleFilterChange(activeAlertColumn.headerText, selectedContracts, !selectedContracts.length)
            }
            label={activeAlertColumn.headerText}
          />
        </div>
      );
    }
    case 'Kritikalität': {
      return (
        <div>
          <CriticalityFilter
            selectedCriticalities={
              activeAlertFilter
                ? ((activeAlertFilter[activeAlertColumn.headerText] as string[]) ?? [])
                    .map((criticality) => criticality as Criticality)
                    .flat()
                : []
            }
            label={activeAlertColumn.headerText}
            onChange={(newSelectedComponentTypes) =>
              handleFilterChange(
                activeAlertColumn.headerText,
                newSelectedComponentTypes,
                !newSelectedComponentTypes.length,
              )
            }
          />
        </div>
      );
    }
    default: {
      return (
        <ActiveAlertTextFilter
          key={`${activeAlertColumn.headerText}_filter`}
          label={activeAlertColumn.headerText}
          onChange={handleFilterChange}
          selection={activeAlertFilter ? (activeAlertFilter[activeAlertColumn.headerText] as string) : undefined}
        />
      );
    }
  }
}

const renderAvailableFilter = (
  availableFilter: ActiveAlertColumn[],
  excludedColumns: string[] | undefined,
  handleFilterChange: (key: string, value: string | string[], remove: boolean) => void,
  activeAlertQuery: DecodedValueMap<ActiveAlertQueryParamConfigModel>,
) =>
  availableFilter
    .filter((componentFilter) => !excludedColumns?.includes(componentFilter.headerText))
    .map((aFilter) => (
      <ActiveAlertFilterElement
        key={`${aFilter.headerText}_filter`}
        activeAlertColumn={aFilter}
        handleFilterChange={handleFilterChange}
        activeAlertFilter={activeAlertQuery.appliedFilter}
      />
    ));

interface ActiveAlertFilterProps {
  activeAlertQuery: DecodedValueMap<ActiveAlertQueryParamConfigModel>;
  isFilterHidden: boolean;
  resetActiveAlertFilter: () => void;
  hideFilter: () => void;
  availableFilter: ActiveAlertColumn[];
  handleFilterChange: (key: string, value: string | string[], remove: boolean) => void;
  excludedColumns?: string[];
  applySelectedFilter: (filter: ActiveAlertFilterModel) => void;
  handleUnselectFilter: () => void;
}

export default function ActiveAlertFilter({
  activeAlertQuery,
  isFilterHidden,
  resetActiveAlertFilter,
  hideFilter,
  availableFilter,
  handleFilterChange,
  excludedColumns,
  applySelectedFilter,
  handleUnselectFilter,
}: ActiveAlertFilterProps) {
  const { t } = useTranslationText('components');
  const { columnConfig } = useColumnConfigContext();
  const hasFilter = !!activeAlertQuery.appliedFilter && !!Object.keys(activeAlertQuery.appliedFilter).length;

  function parseQueryToFilter(name: string, query: DecodedValueMap<ActiveAlertQueryParamConfigModel>) {
    return {
      name,
      columnConfig,
      appliedFilter: { ...query.appliedFilter },
    };
  }

  const filterHeader = () => (
    <>
      <div className="filter-title">{t('moreFilters')}</div>
      {hasFilter && <FilterResetButton handleReset={resetActiveAlertFilter} />}
    </>
  );

  return (
    <div className="filter-menu">
      <CollapsableSideCard isHidden={isFilterHidden} header={filterHeader()} onHideToggle={() => hideFilter()}>
        <div className="active-alert-filter">
          <FilterDropdown
            query={activeAlertQuery}
            useGetAllFilter={useGetActiveAlertFilter}
            applySelectedFilter={(filter) => applySelectedFilter(filter as ActiveAlertFilterModel)}
            resetFilterQuery={resetActiveAlertFilter}
            handleUnselectFilter={handleUnselectFilter}
          />
          {renderAvailableFilter(availableFilter, excludedColumns, handleFilterChange, activeAlertQuery)}
          <CreateFilterModal
            query={activeAlertQuery}
            hasFilter={hasFilter}
            filterForm={
              <>{renderAvailableFilter(availableFilter, excludedColumns, handleFilterChange, activeAlertQuery)}</>
            }
            createOrUpdateFilterMutationKey={MutationKey.CreateOrUpdateActiveAlertFilter}
            putFilterMutationPath={MutationPath.PutActiveAlertFilter()}
            useGetAllFilter={useGetActiveAlertFilter}
            parseQueryToFilter={parseQueryToFilter}
            applySelectedFilter={(filter) => applySelectedFilter(filter as ActiveAlertFilterModel)}
          />
        </div>
      </CollapsableSideCard>
    </div>
  );
}
