import React from 'react';
import { useParams } from 'react-router';
import ErrorMessage from '../ui/error-message/ErrorMessage';
import { useTranslationText } from '../translation/TranslationHooks';

interface UseParamIdResult {
  id: string;
  ErrorPage?: JSX.Element;
}

export default function useParamId(): UseParamIdResult {
  const { id } = useParams();
  const { t } = useTranslationText('commons');
  const ErrorPage =
    id === undefined || id === null ? (
      <ErrorMessage title={t('noIdFoundTitle')} message={t('noIdFoundTitle')} />
    ) : undefined;
  return { id: id ?? '', ErrorPage };
}

interface UseLongParamIdResult {
  id: number;
  ErrorPage?: JSX.Element;
}

export function useLongParamId(): UseLongParamIdResult {
  const { id } = useParams();
  const { t } = useTranslationText('commons');
  const longId = Number(id);

  const ErrorPage =
    id === undefined || id === null || Number.isNaN(longId) ? (
      <ErrorMessage title={t('noIdFoundTitle')} message={t('noIdFoundTitle')} />
    ) : undefined;
  return { id: longId, ErrorPage };
}
