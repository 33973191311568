/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Dispatch } from 'react';
import { DecodedValueMap, encodeQueryParams } from 'use-query-params';
import { Response } from 'react-select-async-paginate';
import queryString from 'query-string';
import { GroupBase } from 'react-select';
import {
  ComponentItem,
  ComponentStatus,
  LocatedComponent,
  SummarizedComponent,
} from '../models/operation/ComponentModel';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { QueryOptions, useGetQuery } from './GetQuery';
import { MonitoringApiPrefix } from './MonitoringApi';
import { DEFAULT_PAGE_SIZE_DROPDOWN, Page } from '../models/pagination/Pagination';
import {
  ComponentFilter,
  ComponentFilterQueryParamConfigMap,
  componentFilterQueryParamConfigMap,
  ComponentSort,
  DescendentComponentStats,
} from '../models/operation/ComponentQuery';
import { mapComponentsToOptions } from '../models/operation/TicketFunctions';
import { JSXSelectOption } from '../ui/jsx-search-dropdown/JSXSearchDropdown';
import { ComponentTypeIcon } from '../models/operation/ComponentTypeModel';

export type ComponentFilterQuery = Partial<DecodedValueMap<ComponentFilterQueryParamConfigMap>>;

export function pathForAllComponentsWithFilter(query: ComponentFilterQuery | undefined) {
  const queryParams = query
    ? `?${queryString.stringify(encodeQueryParams(componentFilterQueryParamConfigMap, query))}`
    : '';
  return `${MonitoringApiPrefix}/component${queryParams}`;
}

export default function useGetComponentsSummarized(
  options?: QueryOptions<Page<SummarizedComponent>>,
  query?: ComponentFilterQuery,
) {
  const path = pathForAllComponentsWithFilter(query);
  return useGetQuery<Page<SummarizedComponent>>([ServerStateKeysEnum.ComponentsAll, query], path, options);
}

export function useGetComponent(componentId: string, options?: QueryOptions<ComponentItem>) {
  return useGetQuery<ComponentItem>(
    [ServerStateKeysEnum.Component, componentId],
    `${MonitoringApiPrefix}/component/${componentId}`,
    options,
  );
}

export function useGetLocatedComponents(options?: QueryOptions<LocatedComponent[]>) {
  return useGetQuery<LocatedComponent[]>(
    [ServerStateKeysEnum.ComponentsLocated],
    `${MonitoringApiPrefix}/component/located`,
    options,
  );
}

export function useGetComponentTypeIcon(
  componentTypeId: string,
  componentStatus: ComponentStatus,
  size?: number,
  options?: QueryOptions<ComponentTypeIcon>,
) {
  return useGetQuery<ComponentTypeIcon>(
    [ServerStateKeysEnum.ComponentTypeIcon, componentTypeId, componentStatus, size],
    size
      ? `${MonitoringApiPrefix}/componentType/${componentTypeId}/icon?status=${componentStatus}&size=${size}`
      : `${MonitoringApiPrefix}/componentType/${componentTypeId}/icon?status=${componentStatus}`,
    options,
  );
}

export function useGetAncestorComponents(
  componentId: string,
  relationType: string,
  options?: QueryOptions<ComponentItem[]>,
) {
  return useGetQuery<ComponentItem[]>(
    [ServerStateKeysEnum.ComponentAncestors, componentId, relationType],
    `${MonitoringApiPrefix}/component/${componentId}/ancestors?relationType=${relationType}`,
    options,
  );
}

export function useGetDescendentComponentStats(
  componentId: string,
  relationType: string,
  options?: QueryOptions<DescendentComponentStats>,
) {
  return useGetQuery<DescendentComponentStats>(
    [ServerStateKeysEnum.DescendentComponentStats, componentId, relationType],
    `${MonitoringApiPrefix}/component/${componentId}/descendents/stats?relationType=${relationType}`,
    options,
  );
}

export function useComponentQuickSearch(
  searchValue: string,
  size: number,
  options?: QueryOptions<Page<ComponentItem>>,
) {
  const path = `${MonitoringApiPrefix}/component/search?query=${searchValue}&size=${size}`;
  return useGetQuery<Page<ComponentItem>>([ServerStateKeysEnum.ComponentSearch, searchValue, size.toString()], path, {
    enabled: searchValue.length > 0,
    ...options,
  });
}

export function pathForAllFilter(filter?: Partial<ComponentFilter>) {
  const queryParams = filter ? `?${queryString.stringify(filter)}` : '';
  return `${MonitoringApiPrefix}/component-filter${queryParams}`;
}

export function useGetComponentFilter(options?: QueryOptions<ComponentFilter[]>, query?: ComponentFilter) {
  const path = pathForAllFilter(query);
  return useGetQuery<ComponentFilter[]>([ServerStateKeysEnum.ComponentFilter, query], path, options);
}

export async function loadComponentsOptions(
  displayName: string,
  prev: JSXSelectOption[] | undefined,
  setIsError: Dispatch<React.SetStateAction<boolean>>,
  customFetch: (path: string, init?: RequestInit | undefined) => Promise<any>,
  pageSize: number = DEFAULT_PAGE_SIZE_DROPDOWN,
) {
  setIsError(false);

  if (displayName === '') {
    return { options: [] };
  }

  const page = prev ? Math.trunc(prev.length / pageSize) : 0;
  const filter: ComponentFilterQuery = {
    displayName,
    page,
    size: pageSize,
    sort: ComponentSort.DisplayNameAsc,
  };
  const path = `${MonitoringApiPrefix}/component?${queryString.stringify(filter)}`;

  const componentItemPage = await customFetch(path);

  if (componentItemPage === null) {
    setIsError(true);
    return { options: [] };
  }

  return {
    options: mapComponentsToOptions(componentItemPage.content),
    hasMore: !componentItemPage.last,
  } as Response<JSXSelectOption, GroupBase<JSXSelectOption>, any>;
}
