import classNames from 'classnames';
import React, { ComponentType, useEffect, useState } from 'react';
import { ActionMeta, GroupBase, MultiValue, MultiValueRemoveProps } from 'react-select';
import { AsyncPaginate, Response } from 'react-select-async-paginate';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Control, Field, Label } from '@aos/styleguide-react';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

export interface ReactSelectOption {
  readonly label: string;
  readonly value: string;
}

interface MultiSearchDropdownProps<T> {
  dataRole: string;
  isError?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoading?: boolean;
  isLoadingMessage?: string;
  onChange: (selectedOptions: MultiValue<ReactSelectOption>, action: ActionMeta<ReactSelectOption>) => void;
  onBlur?: () => void;
  placeholder?: string;
  values?: T[];
  onInputChange?: (value: string) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T[]) => MultiValue<ReactSelectOption>;
  requiredPermission: UserPermission;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  loadOptions: (searchInput: string) => Promise<Response<ReactSelectOption, GroupBase<ReactSelectOption>, any>>;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  size?: BulmaSize;
}

export default function MultiSearchDropdown<T>({
  dataRole,
  isError,
  label,
  noOptionsMessage,
  isLoading,
  isLoadingMessage,
  onChange,
  placeholder,
  values,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  isSearchable,
  openMenuOnClick = false,
  loadOptions,
  onBlur,
  disabled,
  hideSelectedOptions = false,
  size = 'is-normal',
}: MultiSearchDropdownProps<T>): React.ReactElement<MultiSearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<MultiValue<ReactSelectOption>>([]);
  const { hasPermission } = useAuthUser();

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  function handleChange(selectedOptions: MultiValue<ReactSelectOption>, action: ActionMeta<ReactSelectOption>) {
    onChange(selectedOptions, action);
  }

  useEffect(() => {
    if (values && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(values));
    } else {
      setSelectOption([]);
    }
  }, [mapValueToSelectOption, values]);

  return (
    <Field data-role={dataRole}>
      {label && <Label htmlFor={label}>{label}</Label>}
      <Control>
        <AsyncPaginate<ReactSelectOption, GroupBase<ReactSelectOption>, any, true>
          className={classNames('aos-multiple-select', size, {
            'is-error': isError,
            'is-closed': menuIsClosed,
          })}
          classNamePrefix="react-select"
          loadingMessage={() => isLoadingMessage}
          onInputChange={onInputChange}
          isLoading={isLoading}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebbaren Fehler
          onChange={handleChange as any}
          onBlur={onBlur}
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={selectOption}
          isDisabled={disabled || !hasPermission(requiredPermission)}
          loadOptions={loadOptions}
          isMulti
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          hideSelectedOptions={hideSelectedOptions}
          unstyled
          components={{
            MultiValueRemove: createCustomMultiValueRemove(size) as
              | ComponentType<MultiValueRemoveProps<ReactSelectOption, true, GroupBase<ReactSelectOption>>>
              | undefined,
            DropdownIndicator,
          }}
          styles={multiStyles}
          classNames={multiClassNames<ReactSelectOption>()}
        />
      </Control>
    </Field>
  );
}
