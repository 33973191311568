import TextArea from '../text-area/TextArea';

interface TextAreaLabelProps {
  label?: string;
  placeholder?: string;
  value?: string;
  dataRole?: string;
  isUpdated?: boolean;
  autosizeOnLoad?: boolean;
}

export default function TextAreaLabel({
  label,
  placeholder,
  value,
  dataRole,
  isUpdated,
  autosizeOnLoad,
}: TextAreaLabelProps) {
  return (
    <TextArea
      label={label}
      placeholder={placeholder}
      readOnly
      value={value}
      dataRole={dataRole}
      isUpdated={isUpdated}
      autosizeOnLoad={autosizeOnLoad}
    />
  );
}
