import { TitleBarMenu } from "@aos/styleguide-react";
import { useTheme } from "./useTheme";
import {useTranslationText} from "../../translation/TranslationHooks";

export function ThemeMenu() {
  const { t } = useTranslationText('commons');
  const { theme, setTheme } = useTheme();

  return (
    <TitleBarMenu.Item icon="circle-half-stroke" text={t('TitleBar-Theme')}>
      <TitleBarMenu.SubItem
        text={t('TitleBar-Browser')}
        icon="window-maximize"
        isActive={theme === "browser"}
        onClick={() => setTheme("browser")}
      />
      <TitleBarMenu.SubItem
        text={t('TitleBar-Light')}
        icon="sun"
        isActive={theme === "light"}
        onClick={() => setTheme("light")}
      />
      <TitleBarMenu.SubItem
        text={t('TitleBar-Dark')}
        icon="moon"
        isActive={theme === "dark"}
        onClick={() => setTheme("dark")}
      />
    </TitleBarMenu.Item>
  );
}