import './TicketingBaseInformation.scss';
import { Ticket } from '../../models/operation/TicketModel';
import { getSeverityIcon, getTypeIcon } from '../../models/operation/TicketFunctions';
import FavoriteStar from '../../ui/favorite-star/FavoriteStar';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetTasksByTicketId } from '../../react-query/TicketingSystemApi';
import TaskOpenQuestion from '../../ui/task-open-question/TaskOpenQuestion';
import Tooltip from '../../ui/tooltip/Tooltip';
import { formatDateDifferenceFromNow } from '../../utils/dateFormatting';

export interface TicketBaseProps {
  ticket: Ticket;
}
export function TicketingBaseInformation({ ticket }: TicketBaseProps) {
  const { t } = useTranslationText('tickets');
  const { data: taskData } = useGetTasksByTicketId(ticket.id, { enabled: !!ticket.id });

  return (
    <div className="ticket-base-information">
      <div className="severity-icon-wrapper">{getSeverityIcon(ticket.severity)}</div>
      <span className="ticket-id">{ticket.id}</span>
      <div className="ticket-title-container">
        <span className="ticket-desc" id={ticket.id.toString()}>
          {ticket.title}
        </span>
        <Tooltip anchorId={ticket.id.toString()} content={ticket.title} />
        <div className="ticket-type-and-date-wrapper">
          <div className="type-icon-wrapper">{getTypeIcon(ticket.type)}</div>
          <span className="ticket-created-at">{formatDateDifferenceFromNow(ticket.createdAt)}</span>
        </div>
      </div>
      {(taskData?.filter((task) => task.hasOpenQuestions)?.length ?? 0) > 0 ? <TaskOpenQuestion /> : ''}
      {ticket.favorite && (
        <span className="ticket-favorite">
          <FavoriteStar tooltipText={t('favorite')} />
        </span>
      )}
    </div>
  );
}
