import { ServiceTimeModel } from '../../../models/contract-management/ServiceTimeModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DetailInfoField from '../../../ui/detail-info-field/DetailInfoField';
import { DaysOfWeekPicker } from '../../../ui/days-of-week-picker/DaysOfWeekPicker';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import './ServiceTimeDetails.scss';

function parseTimeString(time?: string): string | undefined {
  return time?.substring(0, 5);
}

interface ServiceTimeDetailsProps {
  serviceTime: ServiceTimeModel;
  index: number;
}

export function ServiceTimeDetails({ serviceTime, index }: ServiceTimeDetailsProps) {
  const { t } = useTranslationText('contractManagements');

  return (
    <div className="service-time-details" data-role={`service-time-${index}`}>
      <DaysOfWeekPicker selectedDays={serviceTime.days} label={t('days')} disabled />
      <DetailInfoField
        label={t('serviceTime_begin')}
        value={parseTimeString(serviceTime.begin)}
        dataRole={`contract-service-times-begin-${index}`}
      />
      <DetailInfoField
        label={t('serviceTime_end')}
        value={parseTimeString(serviceTime.end)}
        dataRole={`contract-service-times-end-${index}`}
      />
      <SwitchInput
        checked={serviceTime.publicHolidaysIncluded}
        label={t('serviceTime_publicHolidaysIncluded')}
        disabled
        isSmall
        id={`contract-service-times-${serviceTime.id}-public-holidays-switch`}
      />
    </div>
  );
}
