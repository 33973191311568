import React from 'react';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { German } from 'flatpickr/dist/l10n/de';
import './timePicker.scss';
import { FLATPICKR_TIME_FORMAT } from '../../models/dates/dateConstants';

interface TimePickerProps {
  dataRole?: string;
  isError?: boolean;
  label?: string;
  onValueChange?: (newDate: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  time?: string;
  isRequired?: boolean;
}

export function parseTimeStringToDate(time?: string): Date | undefined {
  if (time) {
    const values = time.split(':');
    if (time.length >= 2) {
      const hours = parseInt(values[0], 10);
      const minutes = parseInt(values[1], 10);

      const date = new Date();
      date.setHours(hours, minutes);
      return date;
    }
  }
  return undefined;
}

export function TimePicker({
  dataRole,
  isError,
  label,
  onValueChange,
  placeholder,
  readOnly,
  time,
  isRequired = false,
}: TimePickerProps) {
  function handleOnChange(day: Date) {
    onValueChange?.(day?.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }));
  }

  return (
    <div className="field is-fullwidth">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control is-fullwidth" data-role={dataRole}>
        <Flatpickr
          className={classNames('input', {
            'is-danger': isError,
          })}
          onChange={([newDate]) => handleOnChange(newDate)}
          placeholder={placeholder}
          readOnly={readOnly}
          value={parseTimeStringToDate(time)}
          options={{
            enableTime: true,
            noCalendar: true,
            allowInput: true,
            dateFormat: FLATPICKR_TIME_FORMAT,
            locale: German,
          }}
        />
      </div>
    </div>
  );
}
