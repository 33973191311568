import i18n, { Resource, ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios, { AxiosResponse } from 'axios';
import { TranslationItem } from '../models/translation/TranslationItem';

export interface Locale {
  id: number;
  name: string;
  code: string;
  isDefault: boolean;
}

const getLangCodes = (): Promise<Locale[]> =>
  axios('/i18n/locales').then((result: AxiosResponse<Locale[]>) => result.data);

/**
 * Convert TranslationData to an i18next readable Object
 * @param translationData
 */
function convertToI18n(translationData: any): Promise<Resource> {
  const collections = translationData ? Object.keys(translationData) : [];
  const i18nResource = {} as any; // create empty object from Type Resource
  return getLangCodes()
    .then((languages) => {
      // for each language key we create a sub-object
      languages.forEach((locale) => {
        i18nResource[locale.code] = { translation: {} as ResourceLanguage };
        collections.forEach((collection) => {
          const resourceLanguage = {} as ResourceLanguage;
          // get TranslationData for the current language
          const langData = translationData[collection].filter(
            (x: TranslationItem) => x.locale === locale.code
          ) as TranslationItem[];
          Object.values(langData).forEach((data) => {
            // extend object here, if more properties are defined in TranslationData
            resourceLanguage[data.key] = {
              ...data,
              text: data.text,
              key: data.key,
              locale: data.locale,
            } as TranslationItem;
          });
          // "translation" is our current default namespace from i18next
          i18nResource[locale.code].translation[collection] = resourceLanguage;
        });
      });
      return i18nResource;
    })
    .finally(() => i18nResource);
}

export default function initI18n(data: any) {
  return convertToI18n(data).then((resources) => {
    i18n
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        resources,
        fallbackLng: 'de',
        debug: false,
        interpolation: { escapeValue: false },
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
    return true;
  });
}
