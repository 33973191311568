import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { Agent } from '../../../models/monitoring/Agent';
import { defaultCreateAgentModule } from '../../../models/monitoring/AgentModule';
import { useTranslationText } from '../../../translation/TranslationHooks';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { AgentModuleForm } from './AgentModuleForm';

interface AgentModulesProps {
  form: UseFormReturn<Agent, object>;
}

export function AgentModules({ form }: AgentModulesProps) {
  const { t } = useTranslationText('agents');
  const { control, watch } = form;
  watch('modules');

  const {
    append,
    fields: agentModules,
    remove,
  } = useFieldArray({
    control,
    name: 'modules',
  });

  return (
    <div className="pt-5">
      <Collapsible
        title={t('modules')}
        collapsibleKey="agent-modules"
        initialExpanded
        addPermission={UserResourcePermissions.Agent.Update}
        addBtnClicked={() => append(defaultCreateAgentModule)}
        badgeCount={agentModules.length}
      >
        <div>
          {agentModules.map((field, index) => (
            <AgentModuleForm form={form} index={index} onRemove={() => remove(index)} key={field.id} />
          ))}
        </div>
      </Collapsible>
    </div>
  );
}
