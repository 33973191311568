import { Trans } from 'react-i18next';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetUserSetting } from '../../react-query/GetUserSettings';
import { NotificationChannelEnum } from '../../models/user/NotificationChannelEnum';
import { MutationKey, MutationPath, usePutMutation } from '../../react-query/MutationQueries';
import { UserNotificationSetting } from '../../models/user/UserNotificationSetting';
import { MailSectionEnum } from '../../models/user/MailSectionEnum';
import { invalidateSetting } from '../../react-query/InvalidationQueries';
import { SwitchInput } from '../../ui/switch/SwitchInput';

interface UserSettingsItemProps {
  section: MailSectionEnum;
  condition: string;
  channels: NotificationChannelEnum[];
  smsEnabled: boolean;
}

export default function UserSettingsItem({ section, condition, channels, smsEnabled }: UserSettingsItemProps) {
  const [updatingChannel, setUpdatingChannel] = useState<NotificationChannelEnum>();
  const { t } = useTranslationText('configurations');
  const { data, isLoading: settingLoading } = useGetUserSetting(section, condition);
  const { t: tError } = useTranslationText('errorTexts');
  const queryClient = useQueryClient();
  const { mutate, isPending: updateLoading } = usePutMutation<UserNotificationSetting, UserNotificationSetting>(
    MutationKey.PutUserSetting,
    {
      onSuccess: async () => {
        await invalidateSetting(section, condition, queryClient);
        setUpdatingChannel(undefined);
      },
      onError: () => {
        toast.error(tError('updateSettingError'));
        setUpdatingChannel(undefined);
      },
    },
  );

  const isChecked = useCallback(
    (channel: NotificationChannelEnum) => {
      switch (channel) {
        case NotificationChannelEnum.EMAIL:
          return data?.mailReceiver;
        case NotificationChannelEnum.SMS:
          return data?.smsReceiver;
        default:
          return false;
      }
    },
    [data],
  );

  const update = (isCheck: boolean, channel: NotificationChannelEnum, id?: string) => {
    setUpdatingChannel(channel);
    mutate({
      body: {
        id,
        section,
        condition,
        mailReceiver: channel === NotificationChannelEnum.EMAIL ? isCheck : data?.mailReceiver ?? false,
        smsReceiver: channel === NotificationChannelEnum.SMS ? isCheck : data?.smsReceiver ?? false,
      },
      path: MutationPath.UpdateSetting,
    });
  };

  return (
    <li>
      <div className="options">
        <span className="is-font-medium">
          <Trans
            i18nKey="configurations.whenIam.text"
            values={{
              subject: t(condition),
            }}
          />
        </span>
        <div className="checkboxes">
          {channels.map((channel) => (
            <SwitchInput
              key={section + condition + channel}
              id={section + condition + channel}
              checked={isChecked(channel) ?? false}
              onChange={(toggleActive) => update(toggleActive, channel, data?.id)}
              label={t(channel)}
              isLoading={settingLoading || (updateLoading && updatingChannel === channel)}
              disabled={!smsEnabled && channel === NotificationChannelEnum.SMS}
            />
          ))}
        </div>
      </div>
    </li>
  );
}
