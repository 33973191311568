import classNames from 'classnames';
import { useState } from 'react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import TextArea from '../../../ui/text-area/TextArea';
import './DeclineMaintenanceRequest.scss';

interface DeclineMaintenanceRequestProps {
  show: boolean;
  setShow: () => void;
  onSubmit: (reason: string) => void;
  isLoading: boolean;
}

export default function DeclineMaintenanceRequest({
  show,
  setShow,
  onSubmit,
  isLoading,
}: DeclineMaintenanceRequestProps) {
  const { t } = useTranslationText('maintenances');
  const [text, setText] = useState('');

  return (
    <div className={classNames('maintennace-request-decline-container', { hide: !show })}>
      <TextArea onValueChange={setText} value={text} label={t('declineReason')} isRequired />
      <div className="is-fluid is-flex is-justify-content-space-between is-align-items-center is-fullwidth is-flex-column-mobile">
        <button
          className="button is-danger is-outlined mt-2 is-fullwidth-mobile"
          type="button"
          onClick={() => {
            setShow();
          }}
        >
          {t('abort')}
        </button>
        <button
          className={classNames('button is-primary', { 'is-loading': isLoading })}
          type="button"
          onClick={() => onSubmit(text)}
          disabled={text === ''}
        >
          {t('declineRequest')}
        </button>
      </div>
    </div>
  );
}
