import React from 'react';
import { RelationTemplate } from '../../../models/operation/ComponentTypeModel';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { useTranslationText } from '../../../translation/TranslationHooks';
import './RelationDetails.scss';
import TextLabel from '../../../ui/text-label/TextLabel';
import TextAreaLabel from '../../../ui/text-area-label/TextAreaLabel';
import { SwitchInput } from '../../../ui/switch/SwitchInput';

interface RelationDetailsProps {
  relations: RelationTemplate[];
}

export function RelationDetails({ relations }: RelationDetailsProps) {
  const { t } = useTranslationText('componentTypes');

  return (
    <Collapsible
      title={t('componentTypeRelationTemplates')}
      collapsibleKey="collapsible-relation-templates"
      initialExpanded={relations.length > 0}
      badgeCount={relations.length}
    >
      <div className="relations">
        {relations.map((relation) => (
          <div className="relation" key={relation.id}>
            <TextLabel label={t('relationTemplateName')} value={relation.name} />
            <div className="horizontal-wrapper">
              <div className="three-quarters">
                <TextLabel label={t('relationTemplateRelationType')} value={relation.relationType} />
              </div>
              <div className="one-quarter">
                <SwitchInput
                  id={`relation-template-isReverse-${relation.id}`}
                  label={t('relationTemplateIsReverse')}
                  checked={relation.isReverse}
                  disabled
                />
              </div>
            </div>
            <TextAreaLabel
              label={t('componentSelectorQueryLabel')}
              value={relation.componentSelector!.query}
              autosizeOnLoad
            />
            <label className="label" htmlFor={t('componentSelectorLabelsLabel')}>
              {t('componentSelectorLabelsLabel')}
            </label>
            <div className="component-selector-labels">
              {relation.componentSelector?.labels.map((label) => (
                <li key={label} className="tag is-light mr-1">
                  {label}
                </li>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Collapsible>
  );
}
