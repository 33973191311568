import React, { useRef } from 'react';
import classNames from 'classnames';
import Select, { ActionMeta, createFilter, SelectInstance, SingleValue } from 'react-select';
import './SingleSelectDropdown.scss';
import { Field, Label } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import { singleClassNames, singleStyles } from '../single-select-dropdown-utils/SingleSelectDropdownUtils';

export interface ReactSelectOption<T> {
  readonly label: string;
  readonly value: T;
}

interface SingleSelectDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  options: ReactSelectOption<T>[];
  onChange: (selectedOption: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) => void;
  placeholder?: string;
  value?: SingleValue<ReactSelectOption<T>>;
  tabIndex?: number;
  autoFocus?: boolean;
  formatOptionLabel?: any;
  requiredPermission: UserPermission;
  disabled?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  size?: BulmaSize;
}

export default function SingleSelectDropdown<T>({
  dataRole,
  isError,
  isLoading,
  label,
  options,
  onChange,
  placeholder,
  value,
  tabIndex,
  autoFocus,
  requiredPermission,
  formatOptionLabel,
  disabled,
  isRequired,
  isClearable,
  size = 'is-normal',
}: SingleSelectDropdownProps<T>) {
  const { hasPermission } = useAuthUser();

  const selectInputRef = useRef<SelectInstance<ReactSelectOption<T>>>(null);

  const isDisabled = disabled || (requiredPermission && !hasPermission(requiredPermission));

  const onClear = () => {
    selectInputRef.current?.clearValue();
  };

  function handleChange(newValue: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) {
    if (newValue?.value === value?.value) {
      onClear();
      return;
    }
    onChange(newValue, actionMeta);
  }

  return (
    <Field data-role={dataRole}>
      {label && (
        <Label className={classNames('label', { 'is-required': isRequired })} htmlFor={label} size={size}>
          {label}
        </Label>
      )}
      <Select
        ref={selectInputRef}
        className={classNames({
          'is-error': isError,
        })}
        classNamePrefix="react-select"
        options={options}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={handleChange as any}
        placeholder={placeholder}
        inputId="select-input"
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        value={value}
        formatOptionLabel={formatOptionLabel}
        filterOption={createFilter({ stringify: (option) => option.label })}
        isClearable={isClearable}
        unstyled
        styles={singleStyles}
        classNames={singleClassNames<ReactSelectOption<T>>(size)}
        isMulti={false}
      />
    </Field>
  );
}
