import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Table as AosTable } from '@aos/styleguide-react';
import { useTranslationText } from '../translation/TranslationHooks';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import NavigationRoutes from '../routing/NavigationRoutes';
import './ComponentTypes.scss';
import ActionBar from '../ui/action-bar/ActionBar';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import UserRoleCheck, { useAuthUser } from '../auth/UserRoleCheck';
import { useGetComponentTypes } from '../react-query/ComponentTypeApi';
import { Dropdown } from '../ui/dropdown/Dropdown';

const tableColumns: TableColumn[] = [
  {
    translationKey: 'name',
    property: 'name',
    disableSort: true,
  },
  {
    translationKey: 'description',
    property: 'description',
    disableSort: true,
  },
];

export default function ComponentTypes() {
  const { t } = useTranslationText('componentTypes');
  const { t: tError } = useTranslationText('errorTexts');
  const { isLoading, data: componentTypes, isError } = useGetComponentTypes({});
  const { hasPermission } = useAuthUser();

  const getItems = () => {
    const renderItems: JSX.Element[] = [];
    if (hasPermission(UserResourcePermissions.ComponentType.Create)) {
      renderItems.push(
        <Link className="dropdown-item" type="button" to="import">
          {t('importComponentType')}
        </Link>,
      );
    }
    return renderItems;
  };

  return (
    <>
      <ActionBar
        right={
          <>
            <UserRoleCheck requiredPermission={UserResourcePermissions.ComponentType.Create}>
              <Link className="button is-primary" to="create">
                {t('createNewComponentType')}
              </Link>
            </UserRoleCheck>
            <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
          </>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_component') : undefined}>
        <div data-role="component-types-container">
          <AosTable>
            <TableHeader columns={tableColumns} translationSection="componentTypes" currentSort={undefined} />
            <AosTable.Body>
              {componentTypes?.content.map((componentType) => {
                const routeToDetail = NavigationRoutes.ComponentTypeId(componentType.id);
                return (
                  <AosTable.Row
                    className="is-clickable"
                    key={componentType.id}
                    data-id={componentType.id}
                    data-role="component-type-item"
                  >
                    <LinkTableCell className="component-type-name" to={routeToDetail}>
                      {componentType.name}
                    </LinkTableCell>
                    <LinkTableCell to={routeToDetail}>
                      <div className="component-type-description">{componentType.description}</div>
                    </LinkTableCell>
                  </AosTable.Row>
                );
              })}
            </AosTable.Body>
          </AosTable>
        </div>
      </LoadingSpinner>
    </>
  );
}
