import { ChangeEvent } from 'react';
import './SwitchInput.scss';
import classNames from 'classnames';
import { Field, Label, Switch as AosSwitch } from '@aos/styleguide-react';

interface SwitchInputProps {
  id: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  secondLabel?: string;
  isError?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  isSmall?: boolean;
  isLoading?: boolean;
}

export function SwitchInput({
  id,
  checked,
  onChange = () => {},
  label,
  secondLabel,
  isError,
  disabled,
  isRequired = false,
  isSmall = false,
  isLoading,
}: SwitchInputProps) {
  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return (
    <Field>
      {label && (
        <Label
          className={classNames({ 'is-required': isRequired, disabled })}
          size={isSmall ? 'is-small' : 'is-normal'}
          htmlFor={id}
        >
          {label}
        </Label>
      )}
      <AosSwitch
        label={secondLabel ?? ''}
        isSmall={isSmall}
        disabled={disabled || isLoading}
        required={isRequired || disabled}
        checked={checked}
        className={classNames({ 'is-danger': isError })}
        name="switch"
        id={id}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={handleOnChange}
      />
    </Field>
  );
}
