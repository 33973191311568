import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faTriangleExclamation,
  faClipboard,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleValue } from 'react-select';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { BaseTicket } from './TicketModel';
import { TicketSeverities, TicketSeverity } from './TicketSeveritiesModel';
import { TicketState, TicketStates } from './TicketStatesModel';
import { UserModel, userToDisplayName, userToDisplayNameWithMail } from './UserModel';
import { TTranslateFn } from '../../translation/TranslationHooks';
import { TicketType, TicketTypes } from './TicketTypesModel';
import FavoriteStar from '../../ui/favorite-star/FavoriteStar';
import { JSXSelectOption } from '../../ui/jsx-search-dropdown/JSXSearchDropdown';
import { BaseComponentItem, SummarizedComponent } from './ComponentModel';
import { KnowledgeBaseComponent } from '../knowledge-base/KnowledgeBaseModel';

export const mapSeveritiesToOptions = (t: TTranslateFn, severities?: TicketSeverities) =>
  severities?.map((element) => ({
    value: element.key,
    label: t(element.translationKey),
  })) ?? [];

export const mapSeverityToOption = (
  t: TTranslateFn,
  severity: TicketSeverity | null | undefined,
): SingleValue<ReactSelectOption> => ({
  value: severity?.key ?? '',
  label: severity ? t(severity?.translationKey) : '',
});

export const mapOptionToSeverity = (
  severities: TicketSeverities | undefined,
  option: SingleValue<ReactSelectOption>,
): TicketSeverity | null => {
  if (option !== null) {
    return {
      key: option.value,
      translationKey: severities?.find((severity) => severity.key === option.value)?.translationKey ?? '',
    };
  }
  return null;
};

export const mapStatesToOptions = (t: TTranslateFn, ticketStates?: TicketStates) =>
  ticketStates?.map((state) => ({
    value: state.key,
    label: t(state.translationKey),
  })) ?? [];

export const mapStateToOption = (
  t: TTranslateFn,
  ticketState: TicketState | null | undefined,
): SingleValue<ReactSelectOption> => ({
  value: ticketState?.key ?? '',
  label: ticketState ? t(ticketState?.translationKey) : '',
});

export const mapTypesToOptions = (t: TTranslateFn, ticketTypes?: TicketTypes) =>
  ticketTypes?.map((type) => ({
    value: type.key,
    label: t(type.translationKey),
  })) ?? [];

export const mapTypeToOption = (
  t: TTranslateFn,
  ticketType: TicketType | null | undefined,
): SingleValue<ReactSelectOption> => ({
  value: ticketType?.key ?? '',
  label: ticketType ? t(ticketType?.translationKey) : '',
});

export const mapOptionToState = (
  states: TicketStates | undefined,
  ticketState: SingleValue<ReactSelectOption>,
): TicketSeverity | null => {
  if (ticketState !== null) {
    return {
      key: ticketState.value,
      translationKey: states?.find((state) => state.key === ticketState.value)?.translationKey ?? '',
    };
  }
  return null;
};

export const mapOptionToType = (
  types: TicketTypes | undefined,
  ticketType: SingleValue<ReactSelectOption>,
): TicketType | null => {
  if (ticketType !== null) {
    return {
      key: ticketType.value,
      translationKey: types?.find((type) => type.key === ticketType.value)?.translationKey ?? '',
    };
  }
  return null;
};

export const mapComponentsToOptions = (
  components?: SummarizedComponent[] | KnowledgeBaseComponent[],
): JSXSelectOption[] =>
  components?.map((component) => ({
    value: component,
    label: component?.displayName ? <div className="is-flex">{component.displayName}</div> : <></>,
    labelText: component?.displayName ?? '',
  })) ?? [];

export const mapTicketsToOptions = (tickets?: BaseTicket[]): ReactSelectOption[] =>
  tickets?.map((ticket) => ({
    value: ticket,
    label: ticket.title,
  })) ?? [];

export const mapOptionToComponent = (option: SingleValue<JSXSelectOption>): BaseComponentItem | null => option?.value;

export const mapOptionToTicket = (option: SingleValue<ReactSelectOption>): BaseTicket | null => option?.value;

export const mapComponentToOption = (component: BaseComponentItem | null): SingleValue<JSXSelectOption> => ({
  value: component,
  label: component?.displayName ? (
    <div className="is-flex">
      {component.isFavorite && (
        <div className="mr-2">
          <FavoriteStar />
        </div>
      )}
      {component.displayName}
    </div>
  ) : (
    <></>
  ),
  labelText: component?.displayName ?? '',
});

export const mapTicketToOption = (ticket: BaseTicket | null): SingleValue<ReactSelectOption> => ({
  value: ticket,
  label: ticket?.title ?? '',
});

export const mapUserToOption = (user: UserModel | null): SingleValue<ReactSelectOption> => ({
  value: user?.id ?? '',
  label: user ? userToDisplayNameWithMail(user) : '',
});

export const mapUsersToOptions = (users?: UserModel[]): ReactSelectOption[] =>
  users?.map((user) => ({
    value: user?.id ?? '',
    label: user ? userToDisplayNameWithMail(user) : '',
  })) ?? [];

export const mapUserToSimpleOption = (user: UserModel): ReactSelectOption => ({
  value: user?.id ?? '',
  label: user ? userToDisplayName(user) : '',
});

export const mapUsersToSimpleOptions = (users?: UserModel[]): ReactSelectOption[] =>
  users?.map(mapUserToSimpleOption) ?? [];

export const getSeverityIcon = (severity: string) => {
  switch (severity) {
    case 'LOW':
    case 'Niedrig':
    case 'severityLow':
      return <FontAwesomeIcon icon={faAngleDoubleDown} className="severity-low" id="severity-low" />;
    case 'MEDIUM':
    case 'Mittel':
    case 'severityMedium':
      return <FontAwesomeIcon icon={faMinus} className="severity-medium" id="severity-medium" />;
    case 'HIGH':
    case 'Hoch':
    case 'severityHigh':
      return <FontAwesomeIcon icon={faAngleDoubleUp} className="severity-high" id="severity-high" />;
    default:
      return <></>;
  }
};

export const getTypeIcon = (type: string) => {
  switch (type) {
    case 'DISTURBANCE':
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="type-disturbance"
          id="type-disturbance"
          color="#FF6A13"
        />
      );
    case 'ASSIGNMENT':
      return <FontAwesomeIcon icon={faClipboard} className="type-assignment" id="type-assignment" color="#009681" />;
    default:
      return <></>;
  }
};
