import { SwitchInput } from '../../switch/SwitchInput';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface ComponentWithoutContractFilterProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function ComponentWithoutContractFilter({ isChecked, onChange }: ComponentWithoutContractFilterProps) {
  const { t } = useTranslationText('components');

  return (
    <SwitchInput
      checked={isChecked}
      onChange={onChange}
      label={t('withoutContract')}
      id="without-contract"
    />
  );
}
