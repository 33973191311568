import { RefObject, useCallback, useEffect, useState } from 'react';
import { MultiSelect } from '../MultiSelectContext';
import { getItemsInDocumentOrder, updatedSelectedItems } from './SelectionHelperFunctions';

interface MultiSelectContainerProps<T> {
  context: React.Context<MultiSelect<T> | undefined>;
  children: React.ReactNode;
}

export default function MultiSelectContainer<T>({ context, children }: MultiSelectContainerProps<T>) {
  const [selected, setSelected] = useState<T[]>([]);
  const [elementOrder, setElementOrder] = useState<Map<RefObject<HTMLElement | null>, T>>(new Map());
  const [multiSelect, setMultiSelect] = useState<MultiSelect<T>>();
  const [lastSelectedElement, setLastSelectedElement] = useState<T | undefined>(undefined);

  useEffect(() => {
    setMultiSelect({
      selected,
      lastSelectedElement,
      clear() {
        setElementOrder(new Map());
        setLastSelectedElement(undefined);
        setSelected([]);
      },
      isSelected(element: T): boolean {
        return selected.some((e) => e === element);
      },
      setSelected(element: T, isSelected: boolean, rangeSelection: boolean = false) {
        setSelected((currentSelection) => {
          const ordering = getItemsInDocumentOrder(elementOrder);
          const lastSelectionIndex = lastSelectedElement
            ? ordering.findIndex((e) => e.item === lastSelectedElement)
            : -1;
          const currentSelectionIndex = ordering.findIndex((e) => e.item === element);

          setLastSelectedElement(element);

          if (!rangeSelection || lastSelectionIndex === -1 || currentSelectionIndex === -1) {
            return updatedSelectedItems(currentSelection, isSelected, element);
          }

          const min = Math.min(lastSelectionIndex, currentSelectionIndex);
          const max = Math.max(lastSelectionIndex, currentSelectionIndex);
          const elementsInSection = [...ordering.slice(min, max + 1)].map((e) => e.item);
          return updatedSelectedItems(currentSelection, isSelected, ...elementsInSection);
        });
      },
      register(element: RefObject<HTMLElement | null>, item: T) {
        setElementOrder((elements) => elements.set(element, item));
        return () => {
          setElementOrder((elements) => {
            elements.delete(element);
            return elements;
          });
        };
      },
    });
  }, [selected, lastSelectedElement, elementOrder]);

  const handleKey = useCallback(
    (event: globalThis.KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }
      if (event.key === 'Escape') {
        setSelected([]);
      } else if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        event.stopPropagation();
        setSelected(Array.from(elementOrder).map((e) => e[1]));
      }
    },
    [elementOrder]
  );

  useEffect(() => {
    const eventHandler = handleKey;
    document.addEventListener('keydown', eventHandler);
    return () => {
      document.removeEventListener('keydown', eventHandler);
    };
  });

  useEffect(() => {
    if (selected.length === 0) {
      setLastSelectedElement(undefined);
    }
  }, [selected]);

  return <context.Provider value={multiSelect}>{children}</context.Provider>;
}
