import React from 'react';
import { MultiValue } from 'react-select';
import { Contractor } from '../../../models/contract-management/ContractorModel';
import { useGetAllContractors } from '../../../react-query/ContractManagementSystemApi';
import { ReactSelectOption } from '../../search-dropdown/SearchDropdown';
import MultiSelectDropdown from '../../multi-select-dropdown/MultiSelectDropdown';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface ComponentContractorsFilterProps {
  onChange: (contractors: string[]) => void;
  selectedContractors: (string | null)[] | null | undefined;
}

function mapToOption(contractorsList?: Contractor[]): ReactSelectOption[] {
  return (
    contractorsList?.map((contractor) => ({
      value: contractor.id,
      label: contractor.companyName,
    })) ?? []
  );
}

export default function ComponentContractorsFilter({ onChange, selectedContractors }: ComponentContractorsFilterProps) {
  const { data: contractors, isLoading: areContractorsLoading, isError: isContractorsError } = useGetAllContractors();
  const { t } = useTranslationText('components');

  const handleOnChange = (newSelectedContractors: MultiValue<ReactSelectOption>) =>
    onChange(newSelectedContractors.map((contractor) => contractor.value));

  const getMappedValues = () => mapToOption(contractors?.content.filter(({ id }) => selectedContractors?.includes(id)));

  return (
    <MultiSelectDropdown
      dataRole="search-dropdown-contractor"
      onChange={handleOnChange}
      options={mapToOption(contractors?.content)}
      label={t('contractorsLabel')}
      mappedValues={getMappedValues()}
      placeholder={t('contractorsPlaceholder')}
      isError={isContractorsError}
      isLoading={areContractorsLoading}
      openMenuOnClick
    />
  );
}
