import React from 'react';
import { createRoot } from 'react-dom/client';
import 'dayjs/locale/de';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { AppWithAuthentication } from './AppWithAuthentication';
import { ReactQueryProvider } from './ReactQueryProvider';

dayjs.locale('de');

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ReactQueryProvider>
          <AppWithAuthentication />
        </ReactQueryProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
