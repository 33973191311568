import { useCallback, useEffect } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Contractor, CreateContractorModel } from '../../../models/contract-management/ContractorModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import SingleSelectCreatableDropdown from '../../../ui/single-select-creatable-dropdown/SingleSelectCreatableDropdown';
import {
  CityInput,
  EmailsInput,
  FaxNumberInput,
  HouseNumberInput,
  PhoneNumberInput,
  PostalCodeInput,
  StreetInput,
} from '../../contractor/CreateContractor/CreateContractorInputs';

interface CreateOrSelectContractorFormProps {
  contractors: Contractor[] | undefined;
  form: UseFormReturn<CreateContractorModel, keyof CreateContractorModel>;
  onSelectedContractorChange: (contractor: Contractor | undefined) => void;
  selectedContractor: Contractor | undefined;
}

function companyNameLabel(companyName: string) {
  return (
    <Trans
      i18nKey="contractManagements.createNewContractorLabel.text"
      values={{
        companyName,
      }}
    />
  );
}

export default function CreateOrSelectContractorForm({
  contractors,
  form,
  onSelectedContractorChange,
  selectedContractor,
}: CreateOrSelectContractorFormProps) {
  const { t } = useTranslationText('contractManagements');
  const { control, formState, setValue, trigger } = form;

  useEffect(() => {
    setValue('street', selectedContractor?.street || '');
    setValue('houseNumber', selectedContractor?.houseNumber || '');
    setValue('postalCode', selectedContractor?.postalCode || '');
    setValue('city', selectedContractor?.city || '');
    setValue('phoneNumber', selectedContractor?.phoneNumber || '');
    setValue('faxNumber', selectedContractor?.faxNumber || '');
    setValue('email', selectedContractor?.email || '');
    if (formState.isDirty) {
      trigger().catch((error) => console.error(`Fehler beim Trigger CreateOrSelectContractorForm FromState: ${error}`));
    }
    // TODO: ADB-226 Schauen ob es eine Alternative zum useEffect gibt
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractor]);

  const CompanyNameSelectInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<CreateContractorModel, 'companyName'> }) => (
      <FormFieldWrapper error={formState.errors?.companyName}>
        <SingleSelectCreatableDropdown
          placeholder={t('companyNameSelectPlaceholder')}
          dataRole="contractor-company-name"
          onChange={(selectedOption) => {
            field.onChange(selectedOption?.value?.companyName);
            onSelectedContractorChange(selectedOption?.value);
          }}
          value={field.value ? { label: field.value, value: selectedContractor } : undefined}
          isError={formState.errors?.companyName != null}
          label={t('companyNameLabel')}
          // Franz Osterkamp -> hier ist ein useCallback nicht umsetzbar
          // eslint-disable-next-line react/no-unstable-nested-components
          createLabel={companyNameLabel}
          onCreateOption={(inputValue) => {
            field.onChange(inputValue);
            onSelectedContractorChange(undefined);
          }}
          options={contractors?.map((contractor) => ({ label: contractor.companyName, value: contractor })) || []}
          noOptionsMessage={t('noContractors')}
          isValidNewOption={(inputValue, options) =>
            inputValue.trim().length > 0 &&
            !options.map((option) => option.label.toUpperCase()).includes(inputValue.trim().toUpperCase())
          }
          isRequired
        />
      </FormFieldWrapper>
    ),
    [contractors, formState.errors?.companyName, onSelectedContractorChange, selectedContractor, t]
  );

  const inputsDisabled = selectedContractor != null;

  return (
    <>
      <Controller name="companyName" control={control} defaultValue="" render={CompanyNameSelectInput} />
      <div className="horizontal-wrapper">
        <div className="three-quarters">
          <Controller
            name="street"
            control={control}
            defaultValue=""
            render={({ field }) => <StreetInput field={field} t={t} formState={formState} disabled={inputsDisabled} />}
          />
        </div>
        <div className="one-quarter">
          <Controller
            name="houseNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <HouseNumberInput field={field} t={t} formState={formState} disabled={inputsDisabled} />
            )}
          />
        </div>
      </div>
      <div className="horizontal-wrapper">
        <div className="one-quarter">
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <PostalCodeInput field={field} t={t} formState={formState} disabled={inputsDisabled} />
            )}
          />
        </div>
        <div className="three-quarters">
          <Controller
            name="city"
            control={control}
            render={({ field }) => <CityInput field={field} t={t} formState={formState} disabled={inputsDisabled} />}
          />
        </div>
      </div>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => <PhoneNumberInput field={field} t={t} formState={formState} disabled={inputsDisabled} />}
      />
      <Controller
        name="faxNumber"
        control={control}
        render={({ field }) => <FaxNumberInput field={field} t={t} formState={formState} disabled={inputsDisabled} />}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => <EmailsInput field={field} t={t} formState={formState} disabled={inputsDisabled} />}
      />
    </>
  );
}
