import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { useTranslationText } from '../../../translation/TranslationHooks';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { ContactPersonForm } from './ContactPersonForm';

interface ContactPersonFormCollapisbleProps {
  index: number;
  form: UseFormReturn<BaseMaintenance>;
  readonly: boolean;
}

export function ContactPersonFormCollapisble({ index, form, readonly: readOnly }: ContactPersonFormCollapisbleProps) {
  const { t } = useTranslationText('maintenances');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({ control, name: `involvedContractors.${index}.contactPersons` });

  return (
    <Collapsible
      collapsibleKey={`contactPersons${index}`}
      initialExpanded
      title={t('contactPersons')}
      badgeCount={fields.length}
      hasNoItems={fields.length === 0}
      addBtnClicked={() =>
        append({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        })
      }
      addPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      addButtonDisabled={readOnly}
    >
      {fields.map((field, contactPersonIndex: number) => (
        <ContactPersonForm
          key={field.id}
          contractorIndex={index}
          contactPersonIndex={contactPersonIndex}
          onRemove={() => remove(contactPersonIndex)}
          form={form}
          readonly={readOnly}
        />
      ))}
    </Collapsible>
  );
}
