import './TicketingItem.scss';
import { Link, useLocation } from 'react-router-dom';
import { Tag } from '@aos/styleguide-react';
import { Ticket } from '../../models/operation/TicketModel';
import UserInfo from '../../ui/user-info/UserInfo';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { useGetUser } from '../../react-query/TicketingSystemApi';
import { TicketingBaseInformation } from './TicketingBaseInformation';
import TicketingItemMenu from './ticketing-item-menu/TicketingItemMenu';
import { TicketStates } from '../../models/operation/TicketStatesModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { MultiSelectItem } from '../../ui/multiselect/multiselect-item/MultiSelectItem';
import TicketMultiSelectContext from '../TicketMultiSelectContext';
import AutogeneratedTicketInformation from '../ticket-autogenerated/AutogeneratedTicketInformation';

export interface TicketProps {
  index: number;
  ticket: Ticket;
  statesForMenu?: TicketStates;
  showState?: boolean;
}

export function TicketingItem({ index, ticket, statesForMenu = undefined, showState = false }: TicketProps) {
  const { t } = useTranslationText('tickets');
  const { data: user, isError: isGetUserError } = useGetUser(ticket?.assigneeId!, { enabled: !!ticket?.assigneeId, suppressErrorToast: true });
  const { pathname } = useLocation();
  const ticketDetailPath = pathname.includes('tickets')
    ? ticket.id.toString()
    : NavigationRoutes.TicketAllId(ticket.id);

  return (
    <MultiSelectItem context={TicketMultiSelectContext} item={ticket} requireKeyModifier>
      <Link to={ticketDetailPath} data-role="ticketing-item">
        <div
          data-role={`ticket-index-${index}`}
          className="ticketing-item"
          style={{ animationDelay: `${index * 30}ms` }}
        >
          <TicketingBaseInformation ticket={ticket} />
          <div className="autogenerated-ticket-information-wrapper">
            <AutogeneratedTicketInformation actorKind={ticket.author.kind} />
          </div>

          <div className="meta-data">
            {user && (
              <div className="user-info-wrapper">
                <UserInfo user={user} isError={isGetUserError} />
              </div>
            )}
            {showState && (
              <Tag color="is-secondary" className="mr-2">
                {t(ticket.state.toLowerCase())}
              </Tag>
            )}
          </div>

          <div className="user-action-wrapper">
            {statesForMenu && (
              <div className="action">
                <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Update}>
                  <TicketingItemMenu statesForMenu={statesForMenu} ticket={ticket} />
                </UserRoleCheck>
              </div>
            )}
          </div>
        </div>
      </Link>
    </MultiSelectItem>
  );
}

export default TicketingItem;
