import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

import './Pagination.scss';

interface PaginationProps {
  pageRangeDisplayed?: number | undefined;
  currentPage: number;
  totalPages: number;
  handleOnPageChange: (page: number) => void;
  size?: 'is-small' | 'is-medium' | 'is-large';
}

export default function Pagination({
  pageRangeDisplayed = 2,
  currentPage,
  totalPages,
  handleOnPageChange,
  size,
}: PaginationProps) {
  if (totalPages <= 1) {
    return <></>;
  }

  return (
    <nav className={classNames('aos-pagination', 'is-centered', size)} role="navigation" aria-label="pagination">
      <ReactPaginate
        pageRangeDisplayed={pageRangeDisplayed}
        renderOnZeroPageCount={() => null}
        forcePage={currentPage}
        disableInitialCallback
        pageCount={totalPages}
        marginPagesDisplayed={1}
        onPageChange={(item) => handleOnPageChange(item.selected)}
        containerClassName="list pagination-list"
        pageLinkClassName="item"
        activeLinkClassName="item is-current"
        disabledClassName="is-disabled"
        disabledLinkClassName="is-disabled"
        previousLinkClassName={classNames('aos-button', size)}
        nextLinkClassName={classNames('aos-button', size)}
        previousLabel={<span className="button-text">Zurück</span>}
        nextLabel={<span className="button-text">Weiter</span>}
        breakLabel={<div className="ellipsis">&hellip;</div>}
      />
    </nav>
  );
}
