import { UseFormReturn } from 'react-hook-form';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import AttributeValueSelectorForm from '../attribute-value-selector-form/AttributeValueSelectorForm';

interface DisplayNameSelectorFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function DisplayNameSelectorForm({ form }: DisplayNameSelectorFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { formState } = form;

  return (
    <Collapsible collapsibleKey="component-selector" initialExpanded title={t('displayNameSelector')}>
      <AttributeValueSelectorForm
        form={form}
        queryError={formState.errors.displayNameSelector?.query}
        queryDataRole="component-type-display-name-selector-query"
        query="displayNameSelector.query"
        labelError={formState.errors.displayNameSelector?.label}
        labelDataRole="component-type-display-name-selector-label"
        label="displayNameSelector.label"
      />
    </Collapsible>
  );
}
