import { useQueryClient } from '@tanstack/react-query';
import { Ticket } from '../../../models/operation/TicketModel';
import { invalidateTicketDetails } from '../../../react-query/InvalidationQueries';
import { usePutMutation, MutationKey, MutationPath, useDeleteMutation } from '../../../react-query/MutationQueries';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { SwitchInput } from '../../../ui/switch/SwitchInput';

interface FavoriteInputProps {
  ticket: Ticket;
}

function FavoriteInput({ ticket }: FavoriteInputProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslationText('tickets');

  const { mutate } = usePutMutation<undefined, number[]>(MutationKey.PutFavoriteTickets, {
    onSuccess: () => invalidateTicketDetails(queryClient, ticket.id),
  });

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteFavoriteTickets, {
    onSuccess: () => invalidateTicketDetails(queryClient, ticket.id),
  });

  function handleOnChange(value: boolean) {
    if (value) {
      mutate({
        body: undefined,
        path: MutationPath.PutOrDeleteFavoriteTickets(ticket.id.toString()),
      });
    } else {
      deleteMutate({
        path: MutationPath.PutOrDeleteFavoriteTickets(ticket.id.toString()),
      });
    }
  }

  return (
    <SwitchInput id="ticket-favorite" onChange={handleOnChange} checked={ticket.favorite} label={t('favoriteTicket')} />
  );
}

export default FavoriteInput;
