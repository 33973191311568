import { useAuth } from 'react-oidc-context';
import queryString from 'query-string';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';
import { StringMap } from '../translation/TranslationStringMap';
import { FetchError } from './FetchError';

export const jsonContentType = 'application/json';
const jsonCharsetContentType = `${jsonContentType}; charset=utf-8`;
const jsonHateoasContentType = 'application/hal+json';
const UUID_REGEX_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

function useDefaultHeaders() {
  const { user } = useAuth();
  const { currentTenant } = useCurrentTenant();
  const headers = new Headers();
  headers.set('Content-Type', jsonContentType);
  headers.set('Accept', jsonContentType);
  if (user) {
    headers.set('Authorization', `Bearer ${user.access_token}`);
    headers.set('Tenant', currentTenant);
  }
  return headers;
}

const hasJsonBody = (response: Response) => {
  const contentType = response.headers.get('Content-Type');
  return (
    contentType != null && [jsonContentType, jsonHateoasContentType, jsonCharsetContentType].indexOf(contentType) !== -1
  );
};

function translateErrorMessage(
  response: Response,
  path: string,
  t?: (key: string, options?: StringMap | undefined) => string,
) {
  if (!t) return 'Ein unerwarteter Fehler ist aufgetreten.';

  let errorMessage;

  if (response.status === 404) {
    const pathParts = path
      .substring(0, path.indexOf('?') > 0 ? path.indexOf('?') : path.length)
      .split('/')
      .filter((part) => part !== '');
    const requestedResource = pathParts[2];
    const requestedResourceId =
      !Number.isNaN(Number(pathParts[3])) || UUID_REGEX_PATTERN.test(pathParts[3]) ? pathParts[3] : undefined;
    const requestedSubResource = pathParts[4];
    errorMessage = t(
      `${response.status}_${requestedResource}${requestedSubResource ? `_${requestedSubResource}` : ''}${!requestedResourceId ? 's' : ''}`,
      {
        id: requestedResourceId,
      },
    );
  }

  if (!errorMessage) errorMessage = t('500');

  return errorMessage.startsWith('errorTexts') ? `${response.status} ${response.statusText}` : errorMessage;
}

export function addSelectedTenantsToPath(path: string, userSelectedTenants: string[]) {
  return path.includes('?')
    ? `${path}&${queryString.stringify({ selectedTenants: userSelectedTenants })}`
    : `${path}?${queryString.stringify({ selectedTenants: userSelectedTenants })}`;
}

export default function useCustomFetch(t?: (key: string, options?: StringMap | undefined) => string) {
  const headers = useDefaultHeaders();
  const { currentTenant } = useCurrentTenant();

  return async function customFetch(path: string, init?: RequestInit) {
    let response: Response;
    try {
      response = await fetch(addSelectedTenantsToPath(path, [currentTenant]), {
        headers,
        ...init,
      });
    } catch (err) {
      throw new FetchError(`Failed to make request`, true);
    }

    if (!response.ok) {
      throw new FetchError(translateErrorMessage(response, path, t), false, response.status, response.statusText);
    }
    if (hasJsonBody(response)) {
      return response.json();
    }
    return response;
  };
}
