import { QueryParamConfigMap, StringParam, ArrayParam, NumberParam, BooleanParam } from 'use-query-params';

import { FilterDropdownType } from '../../generic-components/filter-dropdown/FilterDropdownType';

export interface ComponentFilterQueryParamConfigMap extends QueryParamConfigMap {
  displayName: typeof StringParam;
  relation: typeof StringParam;
  componentsIds: typeof ArrayParam;
  excludedComponentIds: typeof ArrayParam;
  assignedContractId: typeof StringParam;
  componentTypeIds: typeof ArrayParam;
  status: typeof ArrayParam;
  size: typeof NumberParam;
  page: typeof NumberParam;
  sort: typeof StringParam;
  useRoot: typeof BooleanParam;
  contractors: typeof ArrayParam;
  withoutContract: typeof BooleanParam;
  loadDescendentComponentStats: typeof BooleanParam;
  ipAddress: typeof StringParam;
  onlyFavorites: typeof BooleanParam;
  name: typeof StringParam;
}

export interface ComponentFilter extends FilterDropdownType {
  displayName?: string | null;
  relationRoot?: string | null;
  relationParent?: string | null;
  assignedContractId?: string | null;
  componentTypeIds?: (string | null)[] | null;
  status?: (string | null)[] | null;
  useRoot?: boolean | null;
  size?: number | null;
  page?: number | null;
  sort?: string | null;
  contractors?: (string | null)[] | null;
  withoutContract?: boolean | null;
  loadDescendentComponentStats?: boolean | null;
  ipAddress?: string | null;
  onlyFavorites?: boolean | null;
}

export const componentFilterQueryParamConfigMap: ComponentFilterQueryParamConfigMap = {
  displayName: StringParam,
  relation: StringParam,
  excludedComponentIds: ArrayParam,
  componentsIds: ArrayParam,
  assignedContractId: StringParam,
  componentTypeIds: ArrayParam,
  status: ArrayParam,
  useRoot: BooleanParam,
  size: NumberParam,
  page: NumberParam,
  sort: StringParam,
  contractors: ArrayParam,
  withoutContract: BooleanParam,
  loadDescendentComponentStats: BooleanParam,
  ipAddress: StringParam,
  onlyFavorites: BooleanParam,
  name: StringParam,
};

export enum ComponentSort {
  DisplayNameDesc = 'displayName:desc',
  DisplayNameAsc = 'displayName:asc',
}

export interface DescendentComponentStats {
  readonly relationType: string;
  readonly healthy: number;
  readonly disrupted: number;
  readonly failed: number;
  readonly unknown: number;
}
