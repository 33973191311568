import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { useTranslationText } from '../../../translation/TranslationHooks';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { AlternativeTimesForm } from './AlternativeTimesForm';

interface AlternativeTimesCollapsibleProps {
  form: UseFormReturn<BaseMaintenance>;
  readOnly?: boolean;
}

export function AlternativeTimesCollapsible({ form, readOnly = false }: AlternativeTimesCollapsibleProps) {
  const { t } = useTranslationText('maintenances');
  const { control, formState } = form;
  const { append, fields, remove } = useFieldArray({ control, name: `alternativeTimes` });

  return (
    <Collapsible
      collapsibleKey="alternativeTimes"
      initialExpanded
      title={t('alternativeTimes')}
      badgeCount={fields.length}
      hasNoItems={fields.length === 0}
      addBtnClicked={() => {
        append({
          id: 0,
          startOfAction: new Date(),
          endOfAction: new Date(),
        });
        void form.trigger('alternativeTimes');
      }}
      addPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      addButtonDisabled={readOnly}
    >
      {fields.map((field, index: number) => (
        <AlternativeTimesForm
          key={field.id}
          form={form}
          onRemove={() => {
            remove(index);
            void form.trigger('alternativeTimes');
          }}
          index={index}
          readOnly={readOnly}
        />
      ))}
      {formState.errors.alternativeTimes?.message !== undefined && (
        <div className="help is-danger">{t('uniqueTimes')}</div>
      )}
    </Collapsible>
  );
}
