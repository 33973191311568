import DatePicker from '../date-picker/DatePicker';

interface DateLabelProps {
  label?: string;
  placeholder?: string;
  value: Date | undefined;
  dataRole?: string;
  isUpdated?: boolean;
  isRequired?: boolean;
}

function DateLabel({ label, placeholder, value, dataRole, isUpdated, isRequired }: DateLabelProps) {
  return (
    <DatePicker
      label={label}
      placeholder={placeholder}
      readOnly
      value={value}
      dataRole={dataRole}
      isUpdated={isUpdated}
      isRequired={isRequired}
    />
  );
}

export default DateLabel;
