import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { CreateSystemModel, defaultCreateSystemStage, UpdateSystemModel } from '../../models/operation/SystemModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import Collapsible from '../../ui/collapsible/Collapsible';
import { SystemStagesForm } from './SystemStageForm';

interface SystemStagesProps {
  form: UseFormReturn<CreateSystemModel | UpdateSystemModel, object>;
}

export function SystemStages({ form }: SystemStagesProps) {
  const { t } = useTranslationText('systems');

  const { control, watch } = form;

  const {
    append,
    fields: systemStages,
    remove,
  } = useFieldArray({
    control,
    name: 'stages',
  });

  return (
    <div className="columns is-align-items-flex-start">
      <div className="column is-12">
        <Collapsible
          title={t('stages')}
          collapsibleKey="system-stages"
          initialExpanded
          addPermission={UserResourcePermissions.System.CreateOrUpdate}
          addBtnClicked={() => append(defaultCreateSystemStage)}
          badgeCount={systemStages.length}
        >
          {systemStages.map((field, index) => {
            const name = watch(`stages.${index}.name`);
            return (
              <Collapsible
                title={name === undefined || name === '' ? t('newStage') : name}
                collapsibleKey="system-stages"
                initialExpanded
                addPermission={UserResourcePermissions.System.CreateOrUpdate}
                key={field.id}
              >
                <SystemStagesForm form={form} index={index} onRemove={() => remove(index)} />
              </Collapsible>
            );
          })}
        </Collapsible>
      </div>
    </div>
  );
}
