import dayjs from 'dayjs';
import useRuntimeConfig from '../../../runtime-config/RuntimeConfig';
import { useTranslationText } from '../../../translation/TranslationHooks';
import Collapsible from '../../../ui/collapsible/Collapsible';

interface ParallelMaintenancesProps {
  from?: Date;
  to?: Date;
  mainentenaceId: string;
}

export function ParallelMaintenances({ from, to, mainentenaceId }: ParallelMaintenancesProps) {
  const { t } = useTranslationText('maintenances');
  const [runtimeConfig] = useRuntimeConfig();
  const fromValue = dayjs(from).subtract(1, 'hour').valueOf();
  const toValue = dayjs(to).add(1, 'hour').valueOf();
  const queryString = `?kiosk=tv&theme=light&orgId=1&from=${fromValue}&to=${toValue}&var-maintenanceId=${mainentenaceId}`;

  return (
    <Collapsible collapsibleKey="collapsible-parallel-maintenances" title={t('parallelMaintenances')} initialExpanded>
      <div className="parallel-maintenances-container">
        {runtimeConfig && (
          <iframe
            title={t('parallelMaintenances')}
            className="component-attributes-metrics-panel"
            src={`${runtimeConfig.dashboardUrl}/Wartungskalender${queryString}`}
          />
        )}
      </div>
    </Collapsible>
  );
}
