import { faEllipsisH, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormState } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { UpdateSystemModel } from '../../models/operation/SystemModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import { Dropdown } from '../../ui/dropdown/Dropdown';

interface EditSystemActionBarProps {
  formState: FormState<UpdateSystemModel>;
  isLoading: boolean;
  toggleDeleteSystemModal: () => void;
}

export function EditSystemActionBar({ formState, isLoading, toggleDeleteSystemModal }: EditSystemActionBarProps) {
  const { t } = useTranslationText('systems');
  const { hasPermission } = useAuthUser();

  const getItems = (): JSX.Element[] => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.System.Delete)) {
      renderItems.push(
        <button className="dropdown-item" type="button" key="delete-system" onClick={toggleDeleteSystemModal}>
          {t('deleteSystem')}
        </button>
      );
    }

    return renderItems;
  };

  return (
    <ActionBar
      right={
        <>
          <UserRoleCheck requiredPermission={UserResourcePermissions.System.Update}>
            <button
              className={classNames('button is-primary text-icon-button', { 'is-loading': isLoading })}
              type="submit"
              aria-label="save-button"
              disabled={!formState.isValid || !formState.isDirty}
            >
              <span>
                {t('update')}
                <FontAwesomeIcon icon={faSave} />
              </span>
            </button>
          </UserRoleCheck>
          <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
        </>
      }
    />
  );
}
