import { useCallback, useState } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { Ticket } from '../models/operation/TicketModel';
import { Page } from '../models/pagination/Pagination';
import { useGetAllTickets } from '../react-query/TicketingSystemApi';
import { TicketQuery } from '../models/operation/TicketQuery';
import { FetchError } from '../react-query/FetchError';

export default function useGetTicketSuggestions(options?: UseQueryOptions<Page<Ticket>, FetchError>) {
  const [filter, setFilter] = useState<Partial<TicketQuery>>({});

  // convenience function to just set the name to search for
  const setTicketQuery = useCallback((title: string) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      title,
    }));
  }, []);

  const { data, isLoading, isError } = useGetAllTickets(
    {
      ...options,
      enabled: filter.title != null && filter.title.length > 0,
      gcTime: 0,
    },
    {
      ...filter,
      size: 5,
      page: 0,
    },
  );

  return { data, isLoading, isError, filter, setFilter, setTicketQuery };
}
