import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { mapOptionToState, mapStatesToOptions, mapStateToOption } from '../../../models/operation/TicketFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { useGetStates } from '../../../react-query/TicketingSystemApi';
import SingleSelectDropdown from '../../../ui/single-select-dropdown/SingleSelectDropdown';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { TicketState } from '../../../models/operation/TicketStatesModel';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';

export interface ChangeTicketStateConfiguration {
  type: 'changeTicketState';
  state: string;
}

export default function ChangeTicketStateAction({
  configuration,
  handleConfigurationUpdate,
}: GenericActionConfigurationProps<ChangeTicketStateConfiguration>) {
  const [state, setState] = useState<TicketState | null>();
  const { data: states, isLoading, isError } = useGetStates();
  const { t } = useTranslationText('tickets');

  useEffect(() => {
    const key = configuration?.state;
    if (key) {
      setState({ key, translationKey: states?.find((s) => s.key === key)?.translationKey ?? '' });
    } else {
      setState(null);
    }
  }, [configuration, states]);

  const handleChange = (option: SingleValue<ReactSelectOption>) => {
    setState(mapOptionToState(states, option));
    if (option?.value) {
      handleConfigurationUpdate({
        type: 'changeTicketState',
        state: option.value,
      } as ChangeTicketStateConfiguration);
    }
  };

  return (
    <SingleSelectDropdown
      dataRole="rule-state-dropdown"
      isLoading={isLoading}
      isError={isError}
      onChange={handleChange}
      options={mapStatesToOptions(t, states)}
      requiredPermission={UserResourcePermissions.Rule.CreateOrUpdate}
      placeholder={t('selectState')}
      value={state !== null ? mapStateToOption(t, state ?? states?.find((aState) => aState.key === 'TODO')) : null}
      label={t('fieldState')}
    />
  );
}
