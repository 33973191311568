import { UseQueryOptions, useQuery, QueryKey } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useApiErrorHandlers from '../custom-hooks/useApiErrorHandlers';
import useCustomFetch from './CustomFetch';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { useTranslationText } from '../translation/TranslationHooks';
import TenantEnum from '../user/tenant-context/TenantEnum';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';
import { FetchError } from './FetchError';

type ServerStateQueryKey = [ServerStateKeysEnum, ...Array<unknown>] | ServerStateKeysEnum | TenantEnum;

export type QueryOptions<ResponseBodyType> = Omit<
  UseQueryOptions<ResponseBodyType, FetchError>,
  'queryFn' | 'queryKey'
> & { suppressErrorToast?: boolean; customErrorMessage?: string };

export function useGetQuery<ResponseBodyType>(
  queryKey: ServerStateQueryKey,
  path: string,
  options?: QueryOptions<ResponseBodyType>,
) {
  const { t } = useTranslationText('errorTexts');
  const customFetch = useCustomFetch(t);
  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();

  const { currentTenant } = useCurrentTenant();

  const wrappedQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey];

  wrappedQueryKey.push(currentTenant);

  const queryResult = useQuery({
    queryKey: wrappedQueryKey as QueryKey,
    queryFn: async () => {
      const response = await customFetch(path);
      return (await response) as ResponseBodyType;
    },
    ...options,
  });

  useEffect(() => {
    if (queryResult.isError) {
      handleUnauthorizedError(queryResult.error);
      if (options?.suppressErrorToast) return;
      if (options?.customErrorMessage) {
        toast.error(options.customErrorMessage);
        return;
      }
      showErrorToasty(queryResult.error);
    }
  }, [
    queryResult.isError,
    queryResult.error,
    handleUnauthorizedError,
    showErrorToasty,
    options?.suppressErrorToast,
    options?.customErrorMessage,
  ]);

  return queryResult;
}
