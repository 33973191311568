import queryString from 'query-string';
import { ActionType, AutomationRule, ConditionType, TriggerType } from '../models/operation/AutomationRuleModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { AutomationRuleQuery } from '../models/operation/AutomationRuleQuery';

export const AutomationApiPrefix = '/api/automation';

export function pathForAutomationRulesWithFilter(filter?: Partial<AutomationRuleQuery>) {
  if (!filter?.name) {
    return `${AutomationApiPrefix}/rule`;
  }
  const queryParams = filter ? `?${queryString.stringify(filter)}` : '';
  return `${AutomationApiPrefix}/rule${queryParams}`;
}

export function useGetAutomationRules(query?: Partial<AutomationRuleQuery>, options?: QueryOptions<AutomationRule[]>) {
  const path = pathForAutomationRulesWithFilter(query);
  return useGetQuery<AutomationRule[]>([ServerStateKeysEnum.AutomationRuleAll, query], path, options);
}

export function useGetAutomationRuleById(ruleId: string, options?: QueryOptions<AutomationRule>) {
  return useGetQuery<AutomationRule>(
    [ServerStateKeysEnum.AutomationRule, ruleId],
    `${AutomationApiPrefix}/rule/${ruleId}`,
    options,
  );
}

export function useGetActionTypes(options?: QueryOptions<ActionType[]>) {
  return useGetQuery<ActionType[]>(ServerStateKeysEnum.AutomationRuleActions, `${AutomationApiPrefix}/action`, options);
}

export function useGetConditionTypes(options?: QueryOptions<ConditionType[]>) {
  return useGetQuery<ConditionType[]>(
    ServerStateKeysEnum.AutomationRuleConditions,
    `${AutomationApiPrefix}/condition`,
    options,
  );
}

export function useGetTriggerTypes(options?: QueryOptions<TriggerType[]>) {
  return useGetQuery<TriggerType[]>(
    ServerStateKeysEnum.AutomationRuleTriggers,
    `${AutomationApiPrefix}/trigger`,
    options,
  );
}
