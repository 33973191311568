import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import './checkboxInput.scss';

interface CheckboxInputProps {
  label?: string;
  labelRight?: boolean;
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  dataRole?: string;
  isError?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  size?: 'is-small' | 'is-medium' | 'is-large';
  preventDefault?: boolean;
}

export function CheckboxInput({
  label,
  onCheckedChange,
  dataRole,
  checked,
  isError,
  disabled,
  isRequired = false,
  labelRight = false,
  size = 'is-medium',
  preventDefault = false,
}: CheckboxInputProps) {
  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    onCheckedChange?.(e.target.checked);
  }

  return (
    <div className="field">
      {label && !labelRight && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className={classNames('control', 'checkbox-input', 'is-full-height', { right: labelRight })}>
        <input
          className={classNames('checkbox', size, { 'is-danger': isError })}
          type="checkbox"
          onClick={preventDefault ? (e) => e.preventDefault() : undefined}
          onChange={handleOnChange}
          checked={checked}
          disabled={disabled}
          data-role={dataRole}
        />
        {label && labelRight && (
          <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
}
