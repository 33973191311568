import '../../ui/filter/AdvancedFilter.scss';
import { DecodedValueMap } from 'use-query-params';
import { createRef } from 'react';
import { AdvancedFilter } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';
import { TicketQuery, TicketQueryParamConfigModel } from '../../models/operation/TicketQuery';
import StateFilter from '../../ui/filter/StateFilter';
import SeverityFilter from '../../ui/filter/SeverityFilter';
import ComponentFilter from '../../ui/filter/ComponentFilter';
import DateIntervalFilter from '../../ui/filter/DateIntervalFilter';
import TicketFavoritesFilter from '../../ui/filter/TicketFavoritesFilter';
import ComponentTypeFilter from '../../ui/filter/component/ComponentTypeFilter';
import TypeFilter from '../../ui/filter/TypeFilter';
import { TicketFilterModel } from '../../models/operation/TicketFilterModel';
import { useGetTicketFilter } from '../../react-query/TicketingSystemApi';
import { MutationKey, MutationPath } from '../../react-query/MutationQueries';
import FilterDropdown from '../../generic-components/filter-dropdown/FilterDropdown';
import CreateFilterModal from '../../generic-components/CreateFilterModal';
import AuthorUserFilter from '../../ui/filter/user-filter/AuthorUserFilter';
import AssigneeUserFilter from '../../ui/filter/user-filter/AssigneeUserFilter';
import TitleFilter from '../../ui/filter/TitleFilter';
import FilterResetButton from '../../ui/filter/FilterResetButton';

interface TicketFilterProps {
  ticketFilter: DecodedValueMap<TicketQueryParamConfigModel>;
  handleFilterChange: (name: keyof TicketQuery, value: string | string[] | undefined) => void;
  resetTicketFilter: () => void;
  applySelectedFilter: (filter: TicketFilterModel) => void;
  isFilterHidden: boolean;
  hideFilter: () => void;
  handleUnselectFilter: () => void;
}

export default function TicketFilter({
  ticketFilter,
  handleFilterChange,
  resetTicketFilter,
  applySelectedFilter,
  isFilterHidden,
  hideFilter,
  handleUnselectFilter,
}: TicketFilterProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');

  const filterDropdownRef = createRef<any>();

  const hasFilter =
    !!ticketFilter.states ||
    !!ticketFilter.severities ||
    !!ticketFilter.assigneeIds ||
    !!ticketFilter.components ||
    !!ticketFilter.componentTypeIds ||
    !!ticketFilter.authors ||
    !!ticketFilter.createdAfter ||
    !!ticketFilter.createdUntil ||
    !!ticketFilter.modifiedAfter ||
    !!ticketFilter.modifiedUntil ||
    !!ticketFilter.onlyFavorites ||
    !!ticketFilter.type ||
    !!ticketFilter.title;

  function parseQueryToFilter(name: string, query: DecodedValueMap<TicketQueryParamConfigModel>): TicketFilterModel {
    return {
      name,
      assigneeIds: (query.assigneeIds as string[]) ?? [],
      severities: (query.severities as string[]) ?? [],
      onlyFavorites: query.onlyFavorites ?? false,
      componentIds: (query.components as string[]) ?? [],
      componentTypeIds: (query.componentTypeIds as string[]) ?? [],
      states: (query.states as string[]) ?? [],
      authorIds: (query.authors as string[]) ?? [],
      type: query.type === null ? undefined : query.type,
      createdAfter: query.createdAfter ?? '',
      createdUntil: query.createdUntil ?? '',
      modifiedAfter: query.modifiedAfter ?? '',
      modifiedUntil: query.modifiedUntil ?? '',
      title: query.title ?? '',
    };
  }

  function handleResetFilter() {
    resetTicketFilter();
    filterDropdownRef.current?.resetSelectedFilter();
  }

  function handleComponentTypeOnChange(componentTypeId: string[]) {
    handleFilterChange('componentTypeIds', componentTypeId);
  }

  const filterElements = (
    <div className="filter-elements">
      <TitleFilter label={t('titleFilter')} value={ticketFilter.title} onChange={handleFilterChange} />
      <ComponentFilter
        label={t('assignedComponent')}
        selectedComponentIds={ticketFilter.components}
        onChange={handleFilterChange}
      />
      <div className="row">
        <StateFilter label={t('ticketState')} selectedStates={ticketFilter.states} onChange={handleFilterChange} />
        <SeverityFilter
          label={t('fieldSeverity')}
          selectedSeverities={ticketFilter.severities}
          onChange={handleFilterChange}
        />
      </div>
      <div className="row">
        <ComponentTypeFilter
          label={t('componentType')}
          selectedComponentTypeIds={ticketFilter.componentTypeIds}
          onChange={handleComponentTypeOnChange}
        />
        <TypeFilter label={t('fieldType')} selectedType={ticketFilter.type} onChange={handleFilterChange} />
      </div>
      <div className="row">
        <AuthorUserFilter
          selectedUserIds={(ticketFilter.authors?.filter((author) => !!author) as string[]) ?? []}
          onChange={handleFilterChange}
        />
        <AssigneeUserFilter
          selectedUserIds={(ticketFilter.assigneeIds?.filter((author) => !!author) as string[]) ?? []}
          onChange={handleFilterChange}
        />
      </div>
      <div className="row">
        <DateIntervalFilter
          label={t('created')}
          startDate={ticketFilter.createdAfter ? new Date(ticketFilter.createdAfter) : undefined}
          onStartDateChange={(startDate) => {
            handleFilterChange('createdAfter', startDate?.toISOString());
          }}
          endDate={ticketFilter.createdUntil ? new Date(ticketFilter.createdUntil) : undefined}
          onEndDateChange={(endDate) => {
            handleFilterChange('createdUntil', endDate?.toISOString());
          }}
          startDateInputId="ticket-created-at-start"
          endDateInputId="ticket-created-at-end"
          includeEndDay
        />
        <DateIntervalFilter
          label={t('lastEdited')}
          startDate={ticketFilter.modifiedAfter ? new Date(ticketFilter.modifiedAfter) : undefined}
          onStartDateChange={(startDate) => {
            handleFilterChange('modifiedAfter', startDate?.toISOString());
          }}
          endDate={ticketFilter.modifiedUntil ? new Date(ticketFilter.modifiedUntil) : undefined}
          onEndDateChange={(endDate) => {
            handleFilterChange('modifiedUntil', endDate?.toISOString());
          }}
          startDateInputId="ticket-modified-at-start"
          endDateInputId="ticket-modified-at-end"
          includeEndDay
        />
      </div>
      <TicketFavoritesFilter
        isChecked={!!ticketFilter.onlyFavorites}
        onChange={handleFilterChange}
        secondLabel={ticketFilter.onlyFavorites ? tCommons('showFavorites') : tCommons('showAll')}
      />
    </div>
  );

  return (
    <AdvancedFilter
      isOpen={isFilterHidden}
      header={<AdvancedFilter.Header headerText={t('filterHeader')} onClose={() => hideFilter()} />}
      reset={
        <div className="footer with-filter-sets">
          <CreateFilterModal
            query={ticketFilter}
            hasFilter={hasFilter}
            applySelectedFilter={applySelectedFilter}
            createOrUpdateFilterMutationKey={MutationKey.CreateOrUpdateTicketFilter}
            filterForm={filterElements}
            parseQueryToFilter={parseQueryToFilter}
            putFilterMutationPath={MutationPath.PutTicketFilter()}
            useGetAllFilter={useGetTicketFilter}
          />
          {hasFilter && (
            <>
              <div className="separator" />
              <FilterResetButton handleReset={handleResetFilter} />
            </>
          )}
        </div>
      }
      className="with-extra-styles"
    >
      <div className="filter-container">
        <div className="top">
          <div className="choose-filter-set">
            <FilterDropdown
              ref={filterDropdownRef}
              query={ticketFilter}
              useGetAllFilter={useGetTicketFilter}
              applySelectedFilter={applySelectedFilter}
              resetFilterQuery={resetTicketFilter}
              handleUnselectFilter={handleUnselectFilter}
            />
          </div>
          <div className="separator" />
          {filterElements}
        </div>
      </div>
    </AdvancedFilter>
  );
}
