/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import TextInput from '../../ui/text-input/TextInput';
import AttributeValueSelectorForm from '../attribute-value-selector-form/AttributeValueSelectorForm';

interface AttributesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
  index: number;
  onRemove: () => void;
}

export default function AttributesForm({ form, index, onRemove }: AttributesFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control, formState } = form;

  const NameInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `attributes.${typeof index}.name`> }) => (
      <FormFieldWrapper
        error={
          formState?.errors?.alertingRules !== undefined
            ? formState?.errors?.alertingRules?.at?.(index)?.query
            : undefined
        }
      >
        <TextInput
          placeholder={t('attributeNamePlaceholder')}
          dataRole="component-type-attribute-name"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.attributes?.at?.(index)?.name}
          label={t('attributeNameLabel')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    []
  );

  return (
    <ListItemWrapper
      mode="item"
      field="attributes"
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.ComponentType.Create}
    >
      <Controller name={`attributes.${index}.name`} control={control} render={NameInput} />
      <AttributeValueSelectorForm
        form={form}
        queryError={formState.errors?.attributes?.at?.(index)?.valueSelector?.query}
        query={`attributes.${index}.valueSelector.query`}
        labelError={formState.errors?.attributes?.at?.(index)?.valueSelector?.label}
        label={`attributes.${index}.valueSelector.label`}
      />
    </ListItemWrapper>
  );
}
