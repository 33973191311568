import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { getSeverityIcon, mapSeveritiesToOptions } from '../../models/operation/TicketFunctions';
import { TicketQuery } from '../../models/operation/TicketQuery';
import { useGetSeverities } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import MultiSelectDropdown from '../multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../search-dropdown/SearchDropdown';

interface SeverityFilterProps {
  label?: string;
  selectedSeverities: (string | null)[] | null | undefined;
  onChange: (name: keyof TicketQuery, value: string[]) => void;
  size?: BulmaSize;
}

function formatOptionLabel(opt: ReactSelectOption) {
  return (
    <div className="state-flex">
      <span className="severity-icon-wrapper">{getSeverityIcon(opt.value)}</span>
      <span className="state-label">{opt.label}</span>
    </div>
  );
}

export default function SeverityFilter({
  label,
  selectedSeverities,
  onChange,
  size = 'is-small',
}: SeverityFilterProps) {
  const { t } = useTranslationText('tickets');

  const { data: severities, isLoading: isSeverityLoading, isError: isSeverityError } = useGetSeverities();

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'severities',
      selectedOptions.map((value) => value.value),
    );
  };

  const mappedValues = mapSeveritiesToOptions(
    t,
    severities?.filter((severity) => selectedSeverities?.includes(severity.key)),
  );

  return (
    <MultiSelectDropdown
      dataRole="severity-select"
      isError={isSeverityError}
      isLoading={isSeverityLoading}
      onChange={handleOnChange}
      options={mapSeveritiesToOptions(t, severities)}
      openMenuOnClick
      isSearchable={false}
      placeholder={t('selectSeverities')}
      mappedValues={mappedValues}
      formatOptionLabel={formatOptionLabel}
      size={size}
      label={label}
    />
  );
}
