import { NumberParam, withDefault } from 'use-query-params';
import './TicketRelations.scss';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useGetTicketRelationTypes } from '../../react-query/RelationTypeApi';
import { Ticket } from '../../models/operation/TicketModel';
import { MutationKey, useDeleteMutation } from '../../react-query/MutationQueries';
import useGetRelations from '../../react-query/TicketRelationApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import CreateTicketRelation from './create-ticket-relation/CreateTicketRelation';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useShortcut } from '../../custom-hooks/Shortcuts';
import KeyCombination from '../../custom-hooks/KeyCombination';
import { toRelationSpecs } from '../../models/operation/RelationTypeFunctions';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { TicketingApiPrefix } from '../../react-query/TicketingApi';
import Collapsible from '../../ui/collapsible/Collapsible';
import Modal from '../../ui/modal/Modal';
import useToggle from '../../custom-hooks/useToggle';
import Pagination from '../../ui/pagination/Pagination';
import TicketRelationItem from './ticket-relation-item/TicketRelationItem';
import useQueryParamsWithPageReset from '../../custom-hooks/useQueryParamsWithPageReset';

interface TicketRelationsProps {
  ticket: Ticket;
  onRefresh: () => void;
}

function TicketRelations({ ticket, onRefresh }: TicketRelationsProps) {
  const { data: relationTypes } = useGetTicketRelationTypes();
  const [query, setQuery] = useQueryParamsWithPageReset({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
  });
  const {
    data: relations,
    isLoading,
    refetch: refreshRelations,
  } = useGetRelations(ticket.id.toString(), {
    size: query.size.toString(),
    page: query.page.toString(),
  });
  const { t } = useTranslationText('tickets');
  const { t: tErrorTexts } = useTranslationText('errorTexts');
  const { t: tCommon } = useTranslationText('commons');
  const relationSpecs = toRelationSpecs(relationTypes ?? []);

  const [isAddingTicket, setIsAddingTicket] = useState<boolean>(false);

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteTicketRelation, {
    onSuccess: async () => {
      await refreshRelations();
      onRefresh();
    },
    onError: () => {
      toast.error(tErrorTexts('deleteTicketRelationError'));
    },
  });

  useShortcut(KeyCombination.CreateNewRelation, () => setIsAddingTicket(true));

  const AddRelationButton = isAddingTicket && (
    <CreateTicketRelation
      relationSpecs={relationSpecs}
      currentTicket={ticket}
      onCancel={() => setIsAddingTicket(false)}
      onSuccess={async () => {
        setIsAddingTicket(false);
        await refreshRelations();
        onRefresh();
      }}
    />
  );

  const [relationToDeleteId, setRelationToDeleteId] = useState<string>();
  const [isConfirmationModalVisible, toggleConfirmationModal] = useToggle(false);

  const onDelete = () => {
    if (relationToDeleteId !== undefined) {
      deleteMutate({
        path: `${TicketingApiPrefix}/ticket/${ticket.id}/relation/${relationToDeleteId}`,
      });
      setRelationToDeleteId(undefined);
    }
    toggleConfirmationModal();
  };

  const handleDelete = (id: string) => {
    setRelationToDeleteId(id);
    toggleConfirmationModal();
  };

  const handlePageChange = (page: number) => {
    setQuery((latestValues) => ({ ...latestValues, page }));
  };

  return (
    <Collapsible
      collapsibleKey="ticket-relations"
      initialExpanded
      title={t('relations')}
      addBtnClicked={() => setIsAddingTicket(true)}
      addPermission={UserResourcePermissions.TicketRelation.Create}
      dataRole="ticket-relation"
      className="ticket-relations-collapsible"
    >
      <LoadingSpinner isLoading={isLoading}>
        <div className="ticket-relations-wrapper">
          <UserRoleCheck requiredPermission={UserResourcePermissions.TicketRelation.Read}>
            {AddRelationButton}
            <div className="ticket-relations-scroll-container">
              {(relations?.content ?? [])
                .map((relation, index) => (
                  <TicketRelationItem
                    index={index}
                    key={relation.id}
                    relation={relation}
                    onDelete={() => handleDelete(relation.id)}
                  />
                ))
                .reverse()}
            </div>
          </UserRoleCheck>
        </div>
        <Modal
          isVisible={isConfirmationModalVisible}
          title={tCommon('deleteTicketRelation')}
          confirmBtnText={tCommon('delete')}
          cancelBtnText={tCommon('back')}
          onCancel={() => toggleConfirmationModal()}
          onClose={() => toggleConfirmationModal()}
          onConfirm={onDelete}
        >
          <p>{tCommon('warningTicketRelationDelete')}</p>
        </Modal>
      </LoadingSpinner>
      <Pagination
        currentPage={query.page ?? 0}
        totalPages={relations?.totalPages ?? 0}
        handleOnPageChange={handlePageChange}
        size="is-small"
      />
    </Collapsible>
  );
}

export default TicketRelations;
