import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../models/maintenance/Maintenance';
import { RollbackComplexity } from '../../models/maintenance/RollbackComplexity';
import { Priority } from '../../models/maintenance/Priority';
import { TTranslateFn, useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import SingleSelectDropdown, { ReactSelectOption } from '../../ui/single-select-dropdown/SingleSelectDropdown';
import TextArea from '../../ui/text-area/TextArea';
import TextInput from '../../ui/text-input/TextInput';
import { MaintenanceTimes } from './maintenance-times/MaintenanceTimes';
import { TimeSlotPicker } from './time-slot-picker/TimeSlotPicker';
import { MaintenanceStatus } from '../../models/maintenance/MaintenanceStatus';

interface DetailsFormProps {
  form: UseFormReturn<BaseMaintenance>;
  readonly?: boolean;
  setTimeSlot?: (startDate: Date, endDate: Date) => void;
}

function valueToOption<T>(value: T, t: TTranslateFn): ReactSelectOption<T> {
  return {
    label: t(`${value}`),
    value,
  };
}

function prioritiesToOptions(t: TTranslateFn): ReactSelectOption<Priority>[] {
  return Object.values(Priority)
    .filter((v) => Number.isNaN(Number(v)))
    .map((it) => valueToOption(it as Priority, t));
}

function rollbackComplexityToOptions(t: TTranslateFn): ReactSelectOption<RollbackComplexity>[] {
  return Object.values(RollbackComplexity)
    .filter((v) => Number.isNaN(Number(v)))
    .map((it) => valueToOption(it as RollbackComplexity, t));
}

export default function DetailsForm({ form, readonly: readOnly = false, setTimeSlot }: DetailsFormProps) {
  const { t } = useTranslationText('maintenances');

  const { formState, control: formControl, watch } = form;

  const maintenanceStatus = watch('status');

  const TitleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'title'> }) => (
      <FormFieldWrapper error={formState.errors?.title}>
        <TextInput
          label={t('title')}
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.title, readOnly, t]
  );

  const PriorityInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'priority'> }) => (
      <FormFieldWrapper error={formState.errors?.priority}>
        <SingleSelectDropdown
          requiredPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
          label={t('priority')}
          onChange={(it) => field.onChange(it?.value)}
          options={prioritiesToOptions(t)}
          value={valueToOption(field.value, t)}
          isRequired
          disabled={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.priority, readOnly, t]
  );

  const PriorityReasonInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'priorityReason'> }) => (
      <FormFieldWrapper error={formState.errors?.priorityReason}>
        <TextArea value={field.value} onValueChange={field.onChange} label={t('priorityReason')} readOnly={readOnly} />
      </FormFieldWrapper>
    ),
    [formState.errors?.priorityReason, readOnly, t]
  );

  const SummaryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'summary'> }) => (
      <FormFieldWrapper error={formState.errors?.summary}>
        <TextArea value={field.value} onValueChange={field.onChange} isRequired label={t('summary')} />
      </FormFieldWrapper>
    ),
    [formState.errors?.summary, t]
  );

  const DetailedDescriptionInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'detailedDescription'> }) => (
      <FormFieldWrapper error={formState.errors?.detailedDescription}>
        <TextArea
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          label={t('detailedDescription')}
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.detailedDescription, readOnly, t]
  );

  const ExpectedInfluenceOnSystemsInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'expectedInfluenceOnSystems'> }) => (
      <FormFieldWrapper error={formState.errors?.expectedInfluenceOnSystems}>
        <TextArea
          value={field.value}
          onValueChange={field.onChange}
          label={t('expectedInfluenceOnSystems')}
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.expectedInfluenceOnSystems, readOnly, t]
  );

  const RollbackComplexityInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'rollbackComplexity'> }) => (
      <FormFieldWrapper error={formState.errors?.rollbackComplexity}>
        <SingleSelectDropdown
          requiredPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
          onChange={(it) => field.onChange(it?.value)}
          options={rollbackComplexityToOptions(t)}
          value={valueToOption(field.value, t)}
          label={t('rollbackComplexity')}
          isRequired
          disabled={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.rollbackComplexity, readOnly, t]
  );

  const RollbackPrecautionsInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'rollbackPrecautions'> }) => (
      <FormFieldWrapper error={formState.errors?.rollbackPrecautions}>
        <TextArea
          value={field.value}
          onValueChange={field.onChange}
          label={t('rollbackPrecautions')}
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.rollbackPrecautions, readOnly, t]
  );

  const AdditionalCommentsInput = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'additionalComments'> }) => (
      <FormFieldWrapper error={formState.errors?.additionalComments}>
        <TextArea
          value={field.value}
          onValueChange={field.onChange}
          label={t('additionalComments')}
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.additionalComments, readOnly, t]
  );

  return (
    <>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="title" control={formControl} render={TitleInput} />
        </div>
      </div>
      {setTimeSlot !== undefined && maintenanceStatus === MaintenanceStatus.REQUEST ? (
        <TimeSlotPicker form={form} setTimeSlot={setTimeSlot} />
      ) : (
        <MaintenanceTimes form={form} readOnly={readOnly} />
      )}
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="priority" control={formControl} render={PriorityInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="priorityReason" control={formControl} render={PriorityReasonInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="summary" control={formControl} render={SummaryInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="detailedDescription" control={formControl} render={DetailedDescriptionInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name="expectedInfluenceOnSystems"
            control={formControl}
            render={ExpectedInfluenceOnSystemsInput}
          />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="rollbackComplexity" control={formControl} render={RollbackComplexityInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="rollbackPrecautions" control={formControl} render={RollbackPrecautionsInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="additionalComments" control={formControl} render={AdditionalCommentsInput} />
        </div>
      </div>
    </>
  );
}
