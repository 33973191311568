import {
  ComponentRelationDto,
  RelationDirection,
  RelationSpec,
  RelationType,
  TicketRelationDto
} from './RelationTypeModel';

export const mapRelationSpecToOption = (rel: (spec: RelationSpec) => string) => (relSpec?: RelationSpec) => ({
  value: relSpec?.identifier ?? '',
  label: relSpec ? rel(relSpec) : '',
});

export const mapRelationSpecsToOptions = (rel: (spec: RelationSpec) => string) => (relSpec?: RelationSpec[]) =>
  (relSpec ?? []).map(mapRelationSpecToOption(rel));

export function newRelationSpec(type: RelationType, direction: RelationDirection) {
  return {
    identifier: `${type.identifier}/${direction}`,
    type,
    direction,
  };
}

export function toRelationSpec(relationDto: ComponentRelationDto | TicketRelationDto) {
  if (relationDto.source !== undefined) {
    return newRelationSpec({ identifier: relationDto.type }, 'reverse');
  }
  return newRelationSpec({ identifier: relationDto.type }, 'forward');
}

export function toRelationSpecs(typeOrTypes: RelationType | RelationType[]): RelationSpec[] {
  if (Array.isArray(typeOrTypes)) {
    return typeOrTypes.map((e) => toRelationSpecs(e)).reduce((a, b) => a.concat(b), []);
  }

  const type = typeOrTypes as RelationType;
  return [newRelationSpec(type, 'forward'), newRelationSpec(type, 'reverse')];
}
