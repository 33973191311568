import { useFieldArray, UseFormReturn } from 'react-hook-form';
import Collapsible from '../../ui/collapsible/Collapsible';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import RelationTemplateItem from './relation-template-item/RelationTemplateItem';
import { useGetComponentRelationTypes } from '../../react-query/RelationTypeApi';
import './RelationTemplates.scss';

interface RelationTemplatesProps {
  form: UseFormReturn<ComponentTypeModel>;
}

export default function RelationTemplates({ form }: RelationTemplatesProps) {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'relationTemplates',
  });
  const { t } = useTranslationText('componentTypes');

  const { data: relationTypes, isLoading: relationTypesLoading } = useGetComponentRelationTypes({ refetchOnWindowFocus: false });

  const addRelationTemplate = () => {
    append({
      name: '',
      relationType: relationTypes?.[0]?.identifier ?? 'part-of',
      componentSelector: {
        labels: [],
        query: '',
      },
      isReverse: false,
    });
  };

  return (
    <Collapsible
      title={t('componentTypeRelationTemplates')}
      collapsibleKey="collapsible-relation-templates"
      initialExpanded
      badgeCount={fields.length}
      addBtnClicked={addRelationTemplate}
      hasNoItems={fields.length === 0}
      addPermission={UserResourcePermissions.ComponentType.Update}
    >
      <div className="relation-templates">
        {fields.map((field, index) => (
          <RelationTemplateItem
            isReverse={field.isReverse}
            key={field.id}
            form={form}
            index={index}
            isLoading={relationTypesLoading}
            relationTypes={relationTypes}
            onRemove={() => remove(index)}
          />
        ))}
      </div>
    </Collapsible>
  );
}
