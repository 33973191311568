import TextInput from '../text-input/TextInput';

interface TextLabelProps {
  label?: string;
  placeholder?: string;
  value?: string | number;
  dataRole?: string;
  isUpdated?: boolean;
  isRequired?: boolean;
}

function TextLabel({ label, placeholder, value, dataRole, isUpdated, isRequired }: TextLabelProps) {
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      readOnly
      value={value}
      dataRole={dataRole}
      isUpdated={isUpdated}
      isRequired={isRequired}
    />
  );
}

export default TextLabel;
