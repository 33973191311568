import { ReactSelectOption } from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import { TTranslateFn } from '../../translation/TranslationHooks';

export enum UserOverviewColumnType {
  DISPLAY_NAME = 'DISPLAY_NAME',
  TYPE = 'TYPE',
  STATUS = 'STATUS',
  CHILD_STATUS = 'CHILD_STATUS',
  MAINTENANCE = 'MAINTENANCE',
}

export interface UserOverviewColumnConfig {
  type: UserOverviewColumnType;
  visible: boolean;
  orderIndex: number;
}

export interface UserOverviewConfig {
  columnConfigs: UserOverviewColumnConfig[];
}

export const defaultColumnConfig = new Map([
  [
    UserOverviewColumnType.DISPLAY_NAME,
    { type: UserOverviewColumnType.DISPLAY_NAME, visible: true, orderIndex: 0 } as UserOverviewColumnConfig,
  ],
  [
    UserOverviewColumnType.TYPE,
    { type: UserOverviewColumnType.TYPE, visible: true, orderIndex: 1 } as UserOverviewColumnConfig,
  ],
  [
    UserOverviewColumnType.STATUS,
    { type: UserOverviewColumnType.STATUS, visible: true, orderIndex: 2 } as UserOverviewColumnConfig,
  ],
  [
    UserOverviewColumnType.MAINTENANCE,
    { type: UserOverviewColumnType.MAINTENANCE, visible: true, orderIndex: 3 } as UserOverviewColumnConfig,
  ],
  [
    UserOverviewColumnType.CHILD_STATUS,
    { type: UserOverviewColumnType.CHILD_STATUS, visible: false, orderIndex: 4 } as UserOverviewColumnConfig,
  ],
]);

export function columnVisible(
  type: UserOverviewColumnType,
  config?: Map<UserOverviewColumnType, UserOverviewColumnConfig>
) {
  return config?.get(type)?.visible ?? true;
}

export const columnTypeToTranslationKey: Map<UserOverviewColumnType, string> = new Map([
  [UserOverviewColumnType.DISPLAY_NAME, 'component'],
  [UserOverviewColumnType.TYPE, 'componentType'],
  [UserOverviewColumnType.STATUS, 'status'],
  [UserOverviewColumnType.MAINTENANCE, 'maintenance'],
  [UserOverviewColumnType.CHILD_STATUS, 'statusDescendentComponents'],
]);

export function allColumnOptions(t: TTranslateFn) {
  return [
    {
      value: UserOverviewColumnType.DISPLAY_NAME,
      label: t(columnTypeToTranslationKey.get(UserOverviewColumnType.DISPLAY_NAME)!),
    },
    { value: UserOverviewColumnType.TYPE, label: t(columnTypeToTranslationKey.get(UserOverviewColumnType.TYPE)!) },
    { value: UserOverviewColumnType.STATUS, label: t(columnTypeToTranslationKey.get(UserOverviewColumnType.STATUS)!) },
    {
      value: UserOverviewColumnType.MAINTENANCE,
      label: t(columnTypeToTranslationKey.get(UserOverviewColumnType.MAINTENANCE)!),
    },
    {
      value: UserOverviewColumnType.CHILD_STATUS,
      label: t(columnTypeToTranslationKey.get(UserOverviewColumnType.CHILD_STATUS)!),
    },
  ];
}

export function mapColumnConfigsToOptions(
  config: Map<UserOverviewColumnType, UserOverviewColumnConfig>,
  t: TTranslateFn
): ReactSelectOption<UserOverviewColumnType>[] {
  return Array.from(config.keys() ?? [])
    .filter((it) => config.get(it)?.visible ?? false)
    .map(
      (it) =>
        ({
          label: t(columnTypeToTranslationKey.get(it)!),
          value: it,
        } as ReactSelectOption<UserOverviewColumnType>)
    );
}
