import { Table as AosTable } from '@aos/styleguide-react';
import { Link } from 'react-router-dom';
import { NumberParam, StringParam, withDefault } from 'use-query-params';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import NavigationRoutes from '../../routing/NavigationRoutes';
import ActionBar from '../../ui/action-bar/ActionBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetAllContractors } from '../../react-query/ContractManagementSystemApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../models/pagination/Pagination';
import {
  ContractorQuery,
  ContractorQueryParamConfigMap,
  ContractorSort,
} from '../../models/contract-management/ContractorQuery';
import { DebouncedSearchBar } from '../../ui/filter/search-bar/SearchBar';
import Pagination from '../../ui/pagination/Pagination';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { getNextSortState } from '../../ui/table-sort/TableSort';
import useQueryParamsWithPageReset from '../../custom-hooks/useQueryParamsWithPageReset';

const tableColumns: TableColumn[] = [
  {
    translationKey: 'contractorCompanyName',
    property: 'companyName',
  },
  {
    translationKey: 'cityLabel',
    property: 'city',
  },
  {
    translationKey: 'streetAndHouseNumber',
    property: 'street',
  },
  {
    translationKey: 'postalCodeLabel',
    property: 'postalCode',
  },
];

export function ContractorManagement() {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');

  const [query, setQuery] = useQueryParamsWithPageReset({
    ...ContractorQueryParamConfigMap,
    sort: withDefault(StringParam, ContractorSort.CompanyNameAsc),
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  });
  const { data: contractorsPaged, isLoading, isError } = useGetAllContractors({}, query as ContractorQuery);
  const needsPagination = contractorsPaged && contractorsPaged.totalPages > 1;

  const handleFilterChange = (name: keyof ContractorQuery, value: string | string[] | undefined) => {
    setQuery({ ...query, [name]: value, page: DEFAULT_PAGE });
  };

  const onPageChange = (page: number) => {
    setQuery({ ...query, page });
  };

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  return (
    <div className="has-pagination">
      <ActionBar
        center={
          <DebouncedSearchBar
            isLoading={isLoading}
            value={query.companyName ?? ''}
            onChangeDebounced={(newValue) => handleFilterChange('companyName', newValue)}
            placeholder={t('contractorSearchPlaceholder')}
            data-role="contractors-search-input"
          />
        }
        right={
          <UserRoleCheck requiredPermission={UserResourcePermissions.Contract.Create}>
            <Link className="button is-primary" to={NavigationRoutes.ContractorCreate}>
              {t('createNewContractor')}
            </Link>
          </UserRoleCheck>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_contractorManagement') : undefined}>
        <div className="is-full-height">
          {contractorsPaged && !contractorsPaged.empty ? (
            <AosTable>
              <TableHeader
                columns={tableColumns}
                translationSection="contractManagements"
                onSortChange={onSortChange}
                currentSort={query.sort}
              />
              <AosTable.Body data-role="contractor-table">
                {contractorsPaged.content.map((contractor) => {
                  const routeToDetail = NavigationRoutes.ContractorId(contractor.id);
                  return (
                    <AosTable.Row
                      className="is-clickable"
                      key={contractor.id}
                      data-id={contractor.id}
                      data-role="contractor-item"
                    >
                      <LinkTableCell to={routeToDetail} dataRole="company-name-cell">
                        {contractor.companyName}
                      </LinkTableCell>
                      <LinkTableCell to={routeToDetail} dataRole="city-cell">
                        {contractor.city}
                      </LinkTableCell>
                      <LinkTableCell to={routeToDetail} dataRole="street-and-house-number-cell">
                        {`${contractor.street} ${contractor.houseNumber}`}
                      </LinkTableCell>
                      <LinkTableCell to={routeToDetail} dataRole="postal-code-cell">
                        {contractor.postalCode}
                      </LinkTableCell>
                    </AosTable.Row>
                  );
                })}
              </AosTable.Body>
            </AosTable>
          ) : (
            <>{t('noContractors')}</>
          )}
        </div>
      </LoadingSpinner>
      {needsPagination && (
        <footer className="pagination-footer">
          <Pagination
            currentPage={query.page ?? 0}
            totalPages={contractorsPaged?.totalPages ?? 0}
            handleOnPageChange={onPageChange}
            size="is-small"
          />
        </footer>
      )}
    </div>
  );
}
