import { Link } from 'react-router-dom';
import NavigationRoutes from '../../../../routing/NavigationRoutes';

interface KnowledgeBaseTileItemProps {
  id: string;
  title: string;
  subTitle?: string | React.ReactNode;
}

export default function WidgetArticleView({ id, title, subTitle }: KnowledgeBaseTileItemProps) {
  return (
    <Link
      to={{
        pathname: NavigationRoutes.KnowledgeBaseArticleId(id),
      }}
    >
      <div className="small-card-wrapper">
        <div className="small-card-title">{title}</div>
        {subTitle && <div className="small-card-content">{subTitle}</div>}
      </div>
    </Link>
  );
}
