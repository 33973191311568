import React, { useCallback, useContext, useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './UploadFile.scss';
import { useDropzone } from 'react-dropzone';
import { AxiosProgressEvent } from 'axios';
import ProgressBar from './progressbar/ProgressBar';
import { MutationKey, MutationPath, useUploadMutation } from '../../react-query/MutationQueries';
import { StrapiFile } from '../../models/strapi/StrapiFile';
import { DropZoneContext } from '../../DropZoneContext';
import { SupportedFileTypes } from '../../models/media/Media';
import { FetchError } from '../../react-query/FetchError';

export interface UploadFileProps {
  mutationKey: MutationKey;
  mutationPath: MutationPath;
  onSuccess: (data: StrapiFile[]) => void;
  onUploadError: (error: FetchError) => void;
  onUploading?: (event?: AxiosProgressEvent) => void;
  supportedFileTypes?: SupportedFileTypes;
}

function UploadFile({
  mutationKey,
  mutationPath,
  onSuccess,
  onUploadError,
  onUploading,
  supportedFileTypes,
}: UploadFileProps) {
  const [progressEvent, setProgressEvent] = useState<AxiosProgressEvent>();
  useDropzone();
  const { droppedFiles, clearDroppedFiles, enableDropZone, disableDropZone } = useContext(DropZoneContext);
  const onError = (e: FetchError) => {
    onUploadError(e);
    setProgressEvent(undefined);
  };

  const { mutate } = useUploadMutation<FormData, StrapiFile[]>(mutationKey, { onSuccess, onError }, (e) => {
    setProgressEvent(e);
    if (onUploading) onUploading(e);
  });

  const uploadFile = useCallback(
    (files: File[] | FileList | null) => {
      if (files) {
        const body = new FormData();
        for (let i = 0; i < files?.length; i) {
          if (!supportedFileTypes || supportedFileTypes.regex.test(files[i].type)) {
            body.append('files', files[i]);
          }
          i += 1;
        }
        mutate({ body, path: mutationPath });
      }
      return files;
    },
    [mutate, mutationPath, supportedFileTypes],
  );

  useEffect(() => {
    enableDropZone();
    return disableDropZone;
  }, [disableDropZone, enableDropZone]);

  useEffect(() => {
    if (droppedFiles && droppedFiles.length > 0) {
      uploadFile(droppedFiles);
      clearDroppedFiles();
    }
  }, [clearDroppedFiles, droppedFiles, uploadFile]);

  return (
    <div
      className={classNames({
        uploadContainer: true,
        disabled: progressEvent,
      })}
    >
      <label
        htmlFor="file-upload"
        aria-disabled={progressEvent !== undefined}
        className={classNames('fileInput', {
          disableBorderBottom: progressEvent,
        })}
        data-role="file-upload"
      >
        <div style={{ width: 30, height: 30 }}>
          <ProgressBar current={progressEvent?.loaded ?? 0} total={progressEvent?.total ?? 100}>
            {!progressEvent && <FontAwesomeIcon className="uploadIcon" icon={faPlus} />}
          </ProgressBar>
        </div>
      </label>
      <input
        id="file-upload"
        data-role="file-upload-input"
        onChange={(f) => uploadFile(f.target.files)}
        multiple
        type="file"
        name="media"
        value=""
        accept={supportedFileTypes?.acceptedTypes}
      />
    </div>
  );
}

export default UploadFile;
