import React, { useState } from 'react';
import TabItem from '../../models/operation/TabItemModel';
import Tabs from '../../ui/tabs/Tabs';
import TicketHistory from '../history/TicketHistory';
import TabsEnum from './TabsEnum';
import TicketCommentsTab from '../comment/TicketCommentsTab';
import { TicketSeverities } from '../../models/operation/TicketSeveritiesModel';
import { TicketStates } from '../../models/operation/TicketStatesModel';

interface TicketTabsProps {
  ticketId: number;
  severities: TicketSeverities;
  states: TicketStates;
  lastHistoryUpdate: number;
}

export default function TicketTabs({ ticketId, severities, states, lastHistoryUpdate }: TicketTabsProps) {
  const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.Comments);
  const tabItems: TabItem[] = [
    {
      label: TabsEnum.Comments,
      content: <TicketCommentsTab ticketId={ticketId} />,
    },
    {
      label: TabsEnum.History,
      content: <TicketHistory ticketId={ticketId} severities={severities} states={states} lastHistoryUpdate={lastHistoryUpdate} />,
    },
  ];

  return <Tabs items={tabItems} activeTab={activeTab} onClick={setActiveTab} />;
}
