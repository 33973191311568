import { ControlProps, GroupBase, OptionProps } from 'react-select';
import classNames from 'classnames';
import {BulmaSize} from "@aos/styleguide-react/dist/common/constants";

export const singleStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: () => ({ display: 'none' }),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorsContainer: () => ({}),
  option: () => ({}),
  menuList: () => ({}),
  menu: () => ({}),
};

export const singleClassNames = <T,>(size: BulmaSize) => ({
  control: (state: ControlProps<T, false, GroupBase<T>>) =>
    classNames('aos-select-trigger',
      state.isFocused && 'is-focused',
      state.menuIsOpen && 'is-open',
      state.isDisabled && 'is-disabled',
      size),
  menuList: () => 'aos-select-content scroll-area-root scroll-area-viewport',
  option: (state: OptionProps<T, false, GroupBase<T>>) =>
    classNames('select-item',
      state.isSelected && 'is-active',
      state.isFocused && 'is-hovered',
      state.isDisabled && 'is-disabled',
      size),
  indicatorsContainer: () => 'select-icon',
  dropdownIndicator: () => 'aos-icon',
  menu: () => 'is-absolute',
  menuPortal: () => 'react-select-menu-portal',
});
