import { useIdleTimer } from 'react-idle-timer';

const SECONDS = 1000;

export function usePresenceHeartbeat(handle: () => void) {

  useIdleTimer({
    throttle: 5 * SECONDS,
    onAction: () => {
      handle();
    },
  });
}
