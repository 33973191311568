import { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldErrorsImpl,
  FormState,
  Merge,
  UseFormReturn,
} from 'react-hook-form';
import { Agent } from '../../../../models/monitoring/Agent';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import ListItemWrapper from '../../../../ui/list-item-wrapper/ListItemWrapper';
import { SnmpModule } from '../../../../models/monitoring/AgentModule';
import { SnmpField, SnmpTable } from '../../../../models/snmp/Snmp';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';
import { useAuthUser } from '../../../../auth/UserRoleCheck';
import { SwitchInput } from '../../../../ui/switch/SwitchInput';
import TextInput from '../../../../ui/text-input/TextInput';

interface SnmpModuleTableFieldFormProps {
  form: UseFormReturn<Agent, object>;
  moduleIndex: number;
  tableIndex: number;
  fieldIndex: number;
  onRemove: () => void;
}

function useSnmpModuleTableFieldErrors(
  formState: FormState<Agent>,
  moduleIndex: number,
  tableIndex: number,
  fieldIndex: number
) {
  return useCallback(
    (field: keyof SnmpField): FieldError | undefined => {
      const modules = formState.errors?.modules;
      if (!modules) {
        return undefined;
      }
      const module = modules[moduleIndex];
      if (!module) {
        return undefined;
      }
      const typedModule = module as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpModule>>>;
      if (!typedModule) {
        return undefined;
      }
      const snmpTables = typedModule.tables as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpTable[]>>>;
      if (!snmpTables) {
        return undefined;
      }
      const snmpTable = snmpTables[fieldIndex] as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpTable>>>;
      if (!snmpTable) {
        return undefined;
      }
      const snmpFields = snmpTable.fields as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpField[]>>>;
      if (!snmpFields) {
        return undefined;
      }
      const snmpField = snmpFields[fieldIndex] as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpField>>>;
      if (!snmpField) {
        return undefined;
      }
      return snmpField[field] as FieldError;
    },
    [formState.errors?.modules, moduleIndex, fieldIndex]
  );
}

export function SnmpModuleTableFieldForm({
  form,
  moduleIndex,
  tableIndex,
  fieldIndex,
  onRemove,
}: SnmpModuleTableFieldFormProps) {
  const { t } = useTranslationText('agents');

  const { control, formState } = form;
  const getErrors = useSnmpModuleTableFieldErrors(formState, moduleIndex, tableIndex, fieldIndex);
  const { hasPermission } = useAuthUser();

  const OidInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        Agent,
        `modules.${typeof moduleIndex}.tables.${typeof tableIndex}.fields.${typeof fieldIndex}.oid`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('oid')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('oid')}
          label={t('moduleSnmpFieldOid')}
          isRequired
          dataRole={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-oid`}
          key={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-oid`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, tableIndex, fieldIndex]
  );

  const NameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        Agent,
        `modules.${typeof moduleIndex}.tables.${typeof tableIndex}.fields.${typeof fieldIndex}.name`
      >;
    }) => (
      <FormFieldWrapper error={getErrors('name')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('name')}
          label={t('moduleSnmpFieldName')}
          isRequired
          dataRole={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-name`}
          key={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-name`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, tableIndex, fieldIndex]
  );

  const TaggedInput = useCallback(
    ({
      field,
    }: {
      // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
      // eslint-disable-next-line react/no-unused-prop-types
      field: ControllerRenderProps<
        Agent,
        `modules.${typeof moduleIndex}.tables.${typeof tableIndex}.fields.${typeof fieldIndex}.metricIsTagged`
      >;
    }) => (
      <FormFieldWrapper error={formState.errors?.accepted}>
        <SwitchInput
          checked={field.value}
          onChange={field.onChange}
          disabled={!hasPermission(UserResourcePermissions.Agent.Update)}
          isRequired
          id={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-metric-is-tagged`}
          label={t('moduleSnmpFieldMetricIsTagged')}
          key={`module-${moduleIndex}-tables-${tableIndex}-fields-${fieldIndex}-metric-is-tagged`}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.accepted, hasPermission, t, moduleIndex, tableIndex, fieldIndex]
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`modules.${moduleIndex}.fields.${fieldIndex}`}
      index={fieldIndex}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.Agent.Update}
      key={`agent-module-${moduleIndex}-snmp-field-${fieldIndex}`}
    >
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-5">
            <Controller
              name={`modules.${moduleIndex}.tables.${tableIndex}.fields.${fieldIndex}.oid`}
              control={control}
              render={OidInput}
            />
          </div>
          <div className="column is-5">
            <Controller
              name={`modules.${moduleIndex}.tables.${tableIndex}.fields.${fieldIndex}.name`}
              control={control}
              render={NameInput}
            />
          </div>
          <div className="column is-2">
            <Controller
              name={`modules.${moduleIndex}.tables.${tableIndex}.fields.${fieldIndex}.metricIsTagged`}
              control={control}
              render={TaggedInput}
            />
          </div>
        </div>
      </div>
    </ListItemWrapper>
  );
}
