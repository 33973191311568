import { useCallback, useEffect, useState } from 'react';
import { Control, Controller, ControllerRenderProps, FormState, UseFormSetValue } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../models/maintenance/Maintenance';
import { mapUsersToOptions, mapUserToOption } from '../../models/operation/TicketFunctions';
import { UserModel } from '../../models/operation/UserModel';
import useGetAllUsers from '../../react-query/getUsers';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import SearchDropdown from '../../ui/search-dropdown/SearchDropdown';
import TextInput from '../../ui/text-input/TextInput';

interface ResponsibleProps {
  formState: FormState<BaseMaintenance>;
  formControl: Control<BaseMaintenance>;
  setValue: UseFormSetValue<BaseMaintenance>;
  readOnly?: boolean;
}

export default function ResponsibleForm({ formState, formControl, setValue, readOnly = false }: ResponsibleProps) {
  const { data: users, isLoading: areUsersLoading, isError: isUsersError, setUserQuery } = useGetAllUsers();
  const { t } = useTranslationText('maintenances');

  const [selectedResponsible, setSelectedResponsible] = useState<UserModel | undefined>();

  useEffect(() => {
    if (!readOnly) {
      setValue('personResponsible.firstName', selectedResponsible?.firstName || '');
      setValue('personResponsible.lastName', selectedResponsible?.lastName || '');
      setValue('personResponsible.phone', selectedResponsible?.phone || '');
    }
  }, [selectedResponsible, readOnly, setValue]);

  const FirstNameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'personResponsible.firstName'> }) => (
      <FormFieldWrapper error={formState.errors?.personResponsible?.firstName}>
        <TextInput
          label={t('firstName')}
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.personResponsible?.firstName, readOnly, t]
  );

  const LastNameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'personResponsible.lastName'> }) => (
      <FormFieldWrapper error={formState.errors?.personResponsible?.lastName}>
        <TextInput
          label={t('lastName')}
          value={field.value}
          onValueChange={field.onChange}
          isRequired
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.personResponsible?.lastName, readOnly, t]
  );

  const PhoneInput = useCallback(
    ({
      field,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      field: ControllerRenderProps<BaseMaintenance, 'personResponsible.phone'>;
    }) => (
      <FormFieldWrapper error={formState.errors?.personResponsible?.phone}>
        <TextInput
          label={t('phone')}
          value={field.value}
          onValueChange={field.onChange}
          readOnly={readOnly}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.personResponsible?.phone, readOnly, t]
  );

  return (
    <div>
      {!readOnly && (
        <div className="columns is-align-items-flex-start">
          <div className="column is-12">
            <SearchDropdown<UserModel | null>
              dataRole="assigned-user-select"
              isError={isUsersError}
              onChange={(value) => {
                if (value?.value) {
                  const selectedUser: UserModel | undefined = users?.content?.find((user) => user.id === value.value);
                  setSelectedResponsible(selectedUser);
                } else {
                  setSelectedResponsible(undefined);
                }
              }}
              onInputChange={setUserQuery}
              isLoading={areUsersLoading}
              noOptionsMessage={t('noSuggestionsUsers')}
              options={mapUsersToOptions(users?.content)}
              isLoadingMessage={t('usersAreLoading')}
              mapValueToSelectOption={mapUserToOption}
              placeholder={t('assignUser')}
              requiredPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
              isClearable
              label={t('loadFromSystem')}
            />
          </div>
        </div>
      )}
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="personResponsible.firstName" control={formControl} render={FirstNameInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="personResponsible.lastName" control={formControl} render={LastNameInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="personResponsible.phone" control={formControl} render={PhoneInput} />
        </div>
      </div>
    </div>
  );
}
