import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { mapContractorsToOptions } from '../../../models/contract-management/ContractFunctions';
import { TaskQuery } from '../../../models/tasks/TaskQuery';
import { useGetAllContractors } from '../../../react-query/ContractManagementSystemApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';

interface ContractorFilterProps {
  label?: string;
  selectedContractors: (string | null)[] | null | undefined;
  onChange: (name: keyof TaskQuery, value: string[]) => void;
  size?: BulmaSize;
}

function formatOptionLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export default function ContractorFilter({
  label,
  selectedContractors,
  onChange,
  size = 'is-small',
}: ContractorFilterProps) {
  const { t } = useTranslationText('tickets');

  const { data: contractors, isLoading: isLoadingContractors, isError: isContractorError } = useGetAllContractors();

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'contractors',
      selectedOptions.map((value) => value.value),
    );
  };

  const getSelectedContracts = () =>
    contractors?.content.filter(({ companyName }) => selectedContractors?.includes(companyName));

  return (
    <MultiSelectDropdown
      dataRole="contract-select"
      onChange={handleOnChange}
      openMenuOnClick
      isSearchable
      options={mapContractorsToOptions(contractors?.content)}
      isLoading={isLoadingContractors}
      isError={isContractorError}
      placeholder={t('selectContractor')}
      mappedValues={mapContractorsToOptions(getSelectedContracts())}
      formatOptionLabel={formatOptionLabel}
      label={label}
      size={size}
    />
  );
}
