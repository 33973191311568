import classNames from 'classnames';
import { KeyboardEvent, useState } from 'react';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface ReactSelectOption {
  label: string;
  value: string;
}

interface MultiValueInputProps {
  dataRole?: string;
  disabled?: boolean;
  isError: boolean;
  isLoading?: boolean;
  label?: string;
  onChange: (values: string[]) => void;
  placeholder?: string;
  values: string[];
  isRequired?: boolean;
}

export default function MultiValueInput({
  dataRole,
  disabled,
  isError,
  isLoading,
  label,
  onChange,
  placeholder,
  values,
  isRequired,
}: MultiValueInputProps) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!values.includes(inputValue)) {
          onChange([...values, inputValue]);
          setInputValue('');
        }
        event.preventDefault();
        break;
      default:
    }
  };

  const handleChange = (options: MultiValue<ReactSelectOption>) => {
    onChange(options.map((option) => option.value));
  };

  return (
    <div className="field" data-role={dataRole}>
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control">
        <CreatableSelect
          className={classNames({
            'react-select-container': true,
            'react-select-container-error': isError,
          })}
          classNamePrefix="react-select"
          components={{ DropdownIndicator: null }}
          isDisabled={disabled}
          inputId="creatable-select-input"
          inputValue={inputValue}
          isClearable
          isLoading={isLoading}
          isMulti
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={setInputValue}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={values.map((value) => ({
            label: value,
            value,
          }))}
        />
      </div>
    </div>
  );
}
