import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { faGripHorizontal, faList } from '@fortawesome/free-solid-svg-icons';
import KnowledgeBaseSearchBar from './dashboard/search-bar/KnowledgeBaseSearchBar';
import MostVisitedArticles from './dashboard/widgets/global/MostVisitedArticles';
import LastEditedArticles from './dashboard/widgets/global/LastEditedArticles';
import HighlightedArticles from './dashboard/widgets/global/HighlightedArticles';
import RecentlyVisitedArticles from './dashboard/widgets/personal/RecentlyVisitedArticles';
import OwnedArticles from './dashboard/widgets/personal/OwnedArticles';
import ArticleTagCloud from './dashboard/widgets/global/ArticleTagCloud';
import { useGetKnowledgeBaseArticles } from '../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '../translation/TranslationHooks';
import KnowledgeBaseResultItem from './dashboard/KnowledgeBaseResultItem';
import './KnowledgeBaseDashboard.scss';
import useDebouncedValue from '../custom-hooks/useDebouncedValue';
import { ArticleSort } from '../models/knowledge-base/ArticleQuery';

export default function KnowledgeBaseDashboard() {
  const { t } = useTranslationText('knowledgeBases');

  const [query, setQuery] = useQueryParams({
    tagTitles: withDefault(ArrayParam, undefined),
    showWidget: withDefault(BooleanParam, true),
    searchValue: withDefault(StringParam, undefined),
    limit: withDefault(NumberParam, 10),
    sort: withDefault(StringParam, ArticleSort.TitleDesc),
  });

  const setSearchTerm = (newSearchTerm: string) => {
    setQuery({
      searchValue: newSearchTerm,
      showWidget: newSearchTerm.length === 0,
    });
  };
  const [debouncedSearchTerm, isDebouncing] = useDebouncedValue(query.searchValue ?? '');

  const { data: articles = [], isLoading } = useGetKnowledgeBaseArticles(query);

  const UNSET_MAX_WIDTH_AT_CHARS = 60;

  const articlesExist = articles.length > 0;

  const Articles =
    articlesExist && debouncedSearchTerm.length > 0 ? (
      articles.map((article) => (
        <KnowledgeBaseResultItem highlightTextPart={debouncedSearchTerm} key={article.id} article={article} />
      ))
    ) : (
      <span>{t('noArticleAfterSearchFound')}</span>
    );

  return (
    <div className="knowledge-base-dashboard" data-role="knowledgebase-dashboard">
      <div className="columns">
        <div className="column search-area">
          <div className="kb-searchbar">
            <div
              className={classNames('searchbar-wrapper', {
                fullWidth: (query?.searchValue?.length ?? 0) > UNSET_MAX_WIDTH_AT_CHARS,
              })}
            >
              {(query?.searchValue?.length ?? 0) > 0 && (
                <button
                  type="button"
                  onClick={() => setQuery({ showWidget: !query.showWidget })}
                  className="button is-primary icon widgets"
                >
                  <FontAwesomeIcon icon={query.showWidget ? faList : faGripHorizontal} />
                  {query.showWidget && articlesExist && <div className="badge">{articles?.length}</div>}
                </button>
              )}
              <KnowledgeBaseSearchBar
                value={query.searchValue ?? ''}
                onChange={setSearchTerm}
                isLoading={isDebouncing || isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="knowledge-base--content">
        <div className={classNames('results', { widgetInFocus: query.showWidget })}>
          <LoadingSpinner isLoading={isLoading}>
            <div className="article-list-scroll-container">
              <div className="article-list">{Articles}</div>
            </div>
          </LoadingSpinner>
        </div>
        <div
          className={classNames('widgets', {
            widgetsVisible: query.showWidget,
          })}
        >
          <div className="columns">
            <div className="column is-one-third">
              <MostVisitedArticles />
            </div>
            <div className="column is-one-third">
              <ArticleTagCloud onTagClick={(tag) => setSearchTerm(tag)} />
            </div>
            <div className="column is-one-third">
              <HighlightedArticles />
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third">
              <RecentlyVisitedArticles />
            </div>
            <div className="column is-one-third">
              <LastEditedArticles />
            </div>
            <div className="column is-one-third">
              <OwnedArticles />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
