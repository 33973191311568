import './FilterDropdown.scss';
import { DecodedValueMap, QueryParamConfigMap } from 'use-query-params';
import { UseQueryResult } from '@tanstack/react-query';
import { forwardRef, HTMLAttributes, useEffect, useImperativeHandle, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import { ReactSelectOption } from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import { FilterDropdownType } from './FilterDropdownType';
import { useTranslationText } from '../../translation/TranslationHooks';
import SingleSelectDropdown from '../../ui/single-select-dropdown/SingleSelectDropdown';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';

export interface FilterDropdownProps extends HTMLAttributes<HTMLDivElement> {
  query: DecodedValueMap<QueryParamConfigMap>;
  useGetAllFilter: () => UseQueryResult<FilterDropdownType[]>;
  applySelectedFilter: (filter: FilterDropdownType) => void;
  resetFilterQuery: () => void;
  handleUnselectFilter: () => void;
}

const FilterDropdown = forwardRef(
  (
    {
      query,
      useGetAllFilter,
      applySelectedFilter,
      resetFilterQuery,
      handleUnselectFilter,
      ...props
    }: FilterDropdownProps,
    ref,
  ) => {
    const { t } = useTranslationText('commons');

    const { data: allFilter = [], status } = useGetAllFilter();

    function findFilterByName(name: string | undefined, providedFilter: FilterDropdownType[]) {
      if (!name) return null;
      return providedFilter.find((filter) => filter.name === name) ?? null;
    }
    const [selectedFilter, setSelectedFilter] = useState<FilterDropdownType | null>(
      findFilterByName(query?.name, allFilter),
    );
    const [filterOptions, setFilterOptions] = useState<ReactSelectOption<FilterDropdownType>[]>([]);

    function filterToDropdownOptions(tFilter: FilterDropdownType[]): ReactSelectOption<FilterDropdownType>[] {
      return (
        tFilter.map(
          (tf) =>
            ({
              value: tf,
              label: tf.name,
            }) as ReactSelectOption<FilterDropdownType>,
        ) ?? []
      );
    }

    useEffect(() => {
      if (status === 'success') {
        setFilterOptions(filterToDropdownOptions(allFilter));
        setSelectedFilter(findFilterByName(selectedFilter?.name, allFilter));
      }
      // selectedFilter is willingly excluded, because there are cases where selectedFilter changes but useEffect should not be triggered
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFilter, status]);

    useEffect(() => {
      setSelectedFilter(findFilterByName(query?.name, allFilter));
    }, [query?.name, allFilter]);

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedFilter: () => setSelectedFilter(null),
      }),
      [],
    );

    function filterToDropdownValue(
      currentFilter: FilterDropdownType | null,
    ): ReactSelectOption<FilterDropdownType> | null {
      return currentFilter
        ? ({
            value: currentFilter,
            label: currentFilter.name,
          } as ReactSelectOption<FilterDropdownType>)
        : null;
    }

    function onSelectedFilterChange(
      newFilter: SingleValue<ReactSelectOption<FilterDropdownType>>,
      actionMeta: ActionMeta<ReactSelectOption<FilterDropdownType>>,
    ) {
      if (actionMeta.action === 'clear') {
        setSelectedFilter(null);
        handleUnselectFilter();
        return;
      }

      setSelectedFilter(allFilter.filter((f) => f.name === newFilter?.value?.name)[0] || null);
      if (newFilter?.value) {
        applySelectedFilter(newFilter.value);
      }
    }

    function handleSingleSelectDropdownChange(
      selectedOption: SingleValue<ReactSelectOption<FilterDropdownType>>,
      actionMeta: ActionMeta<ReactSelectOption<FilterDropdownType>>,
    ) {
      onSelectedFilterChange(selectedOption, actionMeta);
      if (actionMeta.action === 'clear') {
        resetFilterQuery();
      }
    }

    return (
      <div className={props.className}>
        <SingleSelectDropdown
          label={t('myFilter')}
          options={filterOptions}
          value={filterToDropdownValue(selectedFilter)}
          onChange={handleSingleSelectDropdownChange}
          requiredPermission={UserResourcePermissions.ActiveAlertFilter.Read}
          isClearable
          placeholder={t('selectFilter')}
        />
      </div>
    );
  },
);

export default FilterDropdown;
