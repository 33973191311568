import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { CreateContractModel, PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import { EditServiceTime } from '../../EditServiceTime/EditServiceTime';
import Collapsible from '../../../ui/collapsible/Collapsible';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface CreateServiceTimesFormProps {
  form: UseFormReturn<CreateContractModel, object>;
}

export function CreateServiceTimesFormCollapsible({ form }: CreateServiceTimesFormProps) {
  const { control } = form;
  const { append, fields, remove } = useFieldArray({
    control,
    name: `serviceTimes`,
  });
  const { t } = useTranslationText('contractManagements');

  return (
    <Collapsible
      collapsibleKey="serviceTimes"
      initialExpanded
      title={t('serviceTimes')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ publicHolidaysIncluded: false, days: [] })}
      hasNoItems={fields.length === 0}
      addPermission={UserResourcePermissions.Contract.Create}
      dataRole="service-time"
    >
      <div className="create-service-times">
        {fields.map((field, index: number) => (
          <EditServiceTime
            // TODO: Leider bisher keine bessere Lösung als diese type assertion gefunden und für besser befunden als 2 Komponenten, die bis auf den UseFormReturn-Typ identisch sind
            form={form as UseFormReturn<PatchContractModel>}
            key={field.id}
            index={index}
            onRemove={() => remove(index)}
          />
        ))}
      </div>
    </Collapsible>
  );
}
