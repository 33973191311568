import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { createRef, useEffect } from 'react';
import { TextArea as AosTextArea, Field, Control, Label } from '@aos/styleguide-react';
import './textArea.scss';

interface TextAreaProps {
  label?: string;
  onValueChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  dataRole?: string;
  error?: FieldError;
  isUpdated?: boolean;
  isRequired?: boolean;
  autosizeOnLoad?: boolean;
}

function TextArea({
  value,
  onValueChange,
  placeholder,
  readOnly,
  dataRole,
  label,
  error,
  isUpdated,
  isRequired,
  autosizeOnLoad,
}: TextAreaProps) {
  const ref = createRef<HTMLTextAreaElement>();

  // autosize the height to the text height on page load
  useEffect(() => {
    if (ref && ref.current) {
      const defaultHeight = '8em'; // default textarea height value
      ref.current.style.height = autosizeOnLoad ? `${(ref?.current?.scrollHeight ?? 0) + 5}px` : defaultHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field>
      {label && (
        <Label isRequired={isRequired} htmlFor={label}>
          {label}
        </Label>
      )}
      <Control
        className={classNames('is-fullwidth', {
          'is-updated--success': isUpdated,
        })}
      >
        <AosTextArea
          ref={ref}
          isError={error !== undefined}
          onChange={(event) => onValueChange?.(event.target.value)}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          data-role={dataRole}
        />
      </Control>
    </Field>
  );
}

export default TextArea;
