import { MultiValue } from 'react-select';
import { useState } from 'react';
import useGetAllUsers, { loadUserOptions } from '../../../react-query/getUsers';
import { mapUsersToOptions } from '../../../models/operation/TicketFunctions';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import MultiSearchDropdown from '../../../ui/multi-search-dropdown-paginated/MultiSearchDropdownPaginated';
import useCustomFetch from '../../../react-query/CustomFetch';

interface EditAssigneeIdsProps {
  assignedUserIds: string[];
  mutationIsLoading: boolean;
  onChange: (selectedAssigneeIds: string[]) => void;
}

export function EditAssigneeIds({ assignedUserIds, mutationIsLoading, onChange }: EditAssigneeIdsProps) {
  const [isError, setIsError] = useState(false);

  const { t } = useTranslationText('contractManagements');
  const customFetch = useCustomFetch();

  const { data: allUsers } = useGetAllUsers(assignedUserIds, {
    enabled: assignedUserIds.length > 0,
  });

  function handleOnChange(selectedOptions: MultiValue<ReactSelectOption>) {
    onChange(selectedOptions.map((option) => option.value) ?? []);
  }

  const loadOptions = async (searchInput: string, prev?: ReactSelectOption[]) =>
    loadUserOptions(searchInput, prev, setIsError, customFetch);

  return (
    <MultiSearchDropdown
      dataRole="assignee-select"
      onChange={handleOnChange}
      requiredPermission={UserResourcePermissions.Contractor.CreateOrUpdate}
      loadOptions={loadOptions}
      values={allUsers?.content}
      isError={isError}
      noOptionsMessage={t('noSuggestionsUsers')}
      isLoading={mutationIsLoading}
      isLoadingMessage={t('usersAreLoading')}
      mapValueToSelectOption={mapUsersToOptions}
      isSearchable
      label={t('assignedUsers')}
      placeholder={t('assignedUsersPlaceholder')}
      openMenuOnClick
    />
  );
}
