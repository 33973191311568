import './UserConfig.scss';
import { useTranslationText } from '../../translation/TranslationHooks';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useGetSettings } from '../../react-query/GetUserSettings';
import UserSettingsItem from './UserSettingsItem';

export interface UserSettingsProps {
  smsEnabled: boolean;
}

export default function UserSettings({ smsEnabled }: UserSettingsProps) {
  const { t } = useTranslationText('configurations');
  const { data: settings, isLoading } = useGetSettings();

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className="user-configuration">
        {settings?.map((setting) => (
          <ul key={setting.section} className="config-item">
            <li>
              <div className="is-font-medium has-text-weight-bold section-header">{t(setting.section)}</div>
              <ul>
                {setting.conditions.map((condition) => (
                  <UserSettingsItem
                    key={condition}
                    section={setting.section}
                    condition={condition}
                    channels={setting.channels}
                    smsEnabled={smsEnabled}
                  />
                ))}
              </ul>
            </li>
          </ul>
        ))}
      </div>
    </LoadingSpinner>
  );
}
