import React from 'react';

import { ConditionType, RuleCondition } from '../../models/operation/AutomationRuleModel';
import HasAssignedComponentCondition from './conditions/HasAssignedComponentCondition';
import CollapsibleCheck from '../../ui/collapsible/CollapsibleCheck';
import HasSeveritiesCondition from './conditions/HasSeveritiesCondition/HasSeveritiesCondition';
import HasStatesCondition from './conditions/HasStatesCondition';
import HasAssigneeCondition from './conditions/HasAssigneeCondition';
import { useOptionalTranslationText, useTranslationText } from '../../translation/TranslationHooks';
import HasAssignedComponentTypeCondition from './conditions/HasAssignedComponentType';

interface SelectConditionsProps {
  conditionType: ConditionType;
  condition?: RuleCondition;
  handleChange: (condition: RuleCondition) => void;
  onUncheck: (condition?: RuleCondition) => void;
}

interface ConditionConfigurationProps {
  conditionType: ConditionType;
  condition?: any;
  handleConditionUpdate: (condition: RuleCondition) => void;
  onClear: () => void;
}

function ConditionConfiguration({
  conditionType,
  condition,
  handleConditionUpdate,
  onClear,
}: ConditionConfigurationProps) {
  switch (conditionType) {
    case 'hasAssignedComponent':
      return (
        <HasAssignedComponentCondition
          condition={condition}
          handleConfigurationUpdate={handleConditionUpdate}
          onClear={onClear}
        />
      );
    case 'hasSeverities':
      return (
        <HasSeveritiesCondition
          condition={condition}
          handleConfigurationUpdate={handleConditionUpdate}
          onClear={onClear}
        />
      );
    case 'hasStates':
      return (
        <HasStatesCondition condition={condition} handleConfigurationUpdate={handleConditionUpdate} onClear={onClear} />
      );
    case 'hasAssignee':
      return (
        <HasAssigneeCondition
          condition={condition}
          handleConfigurationUpdate={handleConditionUpdate}
          onClear={onClear}
        />
      );
    case 'hasAssignedComponentType':
      return (
        <HasAssignedComponentTypeCondition
          condition={condition}
          handleConfigurationUpdate={handleConditionUpdate}
          onClear={onClear}
        />
      );
    default:
      return <></>;
  }
}

export default function RuleConditionItem({
  condition,
  conditionType,
  handleChange,
  onUncheck,
}: SelectConditionsProps) {
  const { t } = useTranslationText('automations');
  const { t: optionalTranslationText } = useOptionalTranslationText('automations');
  const description = optionalTranslationText(`${conditionType}_description`);
  return (
    <div className="condition-item control mt-2">
      <CollapsibleCheck
        isChecked={!!condition}
        title={t(`${conditionType}_title`)}
        onToggle={(isChecked) => !isChecked && onUncheck(condition)}
      >
        {description && <div>{description}</div>}
        <ConditionConfiguration
          conditionType={conditionType}
          condition={condition}
          handleConditionUpdate={handleChange}
          onClear={() => onUncheck(condition)}
        />
      </CollapsibleCheck>
    </div>
  );
}
