import { ReactNode } from 'react';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useToggle from '../../custom-hooks/useToggle';

interface CollapsibleCheckProps {
  isChecked: boolean;
  title: string;
  children: ReactNode;
  dataRole?: string;
  onToggle: (isChecked: boolean) => void;
}

export default function CollapsibleCheck({ isChecked, children, title, dataRole, onToggle }: CollapsibleCheckProps) {
  const [isConditionDetailVisible, toggleConditionVisibility] = useToggle(isChecked);

  return (
    <div className="collapsible-check">
      <div className="condition-toggle">
        <div
          className="condition-container--header"
          data-role={dataRole}
          onClick={(e) => {
            e.stopPropagation();
            onToggle(!isChecked);
            toggleConditionVisibility();
          }}
        >
          <div className="left">
            <FontAwesomeIcon
              icon={isChecked ? faCheckCircle : faCircle}
              className={classNames('is-font-medium', {
                isChecked,
              })}
            />
            <div>{title}</div>
          </div>
        </div>
        <div
          className={classNames('condition-config', {
            isVisible: children && (isChecked || isConditionDetailVisible),
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
