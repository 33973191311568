import { ReactSelectOption } from '../ui/search-dropdown/SearchDropdown';
import { TTranslateFn } from '../translation/TranslationHooks';

export function toTranslationKeyFromEnumKey(
  allEnumObjects: { key: string; translationKey: string }[] | undefined,
  enumKey: string
) {
  return allEnumObjects?.find((enumObject) => enumObject.key === enumKey)?.translationKey ?? enumKey;
}

export function toTranslationKeysFromEnumKeys(
  allEnumObjects: { key: string; translationKey: string }[] | undefined,
  enumKeys: string[] | undefined
) {
  return (enumKeys ?? []).map((e) => toTranslationKeyFromEnumKey(allEnumObjects, e));
}

export function toReactSelectFromTranslationKey(t: TTranslateFn, enumKeys?: string[]): ReactSelectOption[] {
  return (
    enumKeys?.map((severity) => ({
      label: severity ? t(severity) : '',
      value: severity ?? '',
    })) ?? []
  );
}

export function toReactSelectFromEnumObject(
  t: TTranslateFn,
  elements?: { key: string; translationKey: string }[]
): ReactSelectOption[] {
  return (
    elements?.map((element) => ({
      value: element.translationKey,
      label: t(element.translationKey),
    })) ?? []
  );
}

export function getEnumKeyFromSelectOption(
  allEnumObjects: { key: string; translationKey: string }[] | undefined,
  option: ReactSelectOption
): string | undefined {
  return allEnumObjects?.find((enumObject) => enumObject.translationKey === option.value)?.key;
}
