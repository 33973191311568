import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useQueryClient } from '@tanstack/react-query';
import { AutomationRule, AutomationRuleWithOptionalId } from '../../models/operation/AutomationRuleModel';

import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import RuleEditor from '../RuleEditor';
import { AutomationApiPrefix, useGetAutomationRuleById } from '../../react-query/AutomationApi';
import { invalidateAllAutomations, invalidateAutomationRule } from '../../react-query/InvalidationQueries';
import useParamId from '../../custom-hooks/UseParamId';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';

export default function EditRule() {
  const { id: ruleId, ErrorPage } = useParamId();
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isPending } = usePutMutation<AutomationRule, AutomationRule>(MutationKey.PutRule, {
    onSuccess: async () => {
      toast.success(tSuccess('editRule'));
      await invalidateAutomationRule(queryClient, ruleId);
      navigate(-1);
    },
    onError: () => {
      toast.error(tError('ruleCouldNotBeSaved'));
    },
  });

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteRule, {
    onSuccess: async () => {
      await invalidateAllAutomations(queryClient).then(() => navigate(-1));
      toast.success(tSuccess('deleteRule'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('ruleDeleteError')}`);
    },
  });

  const onDelete = () => deleteMutate({ path: `${AutomationApiPrefix}/rule/${ruleId}` });

  const onSubmit = (ruleModel: AutomationRuleWithOptionalId) => {
    mutate({
      body: {
        id: ruleId,
        name: ruleModel.name,
        description: ruleModel.description,
        active: ruleModel.active,
        actions: ruleModel.actions,
        conditions: ruleModel.conditions,
        trigger: ruleModel.trigger!,
        createdBy: '',
        lastModified: '',
      },
      path: MutationPath.PutRule(ruleId),
    });
  };

  const {
    data,
    isLoading: isDataLoading,
    isError,
  } = useGetAutomationRuleById(ruleId!, {
    enabled: ruleId !== undefined,
  });

  return (
    <LoadingSpinner isLoading={isDataLoading || isPending} errors={isError && ErrorPage}>
      <RuleEditor
        data={data}
        permission={UserResourcePermissions.Rule.Update}
        onSubmit={onSubmit}
        onDelete={onDelete}
        isLoading={isPending || isDataLoading}
      />
    </LoadingSpinner>
  );
}
