import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, ControllerRenderProps, useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useCallback, useState } from 'react';
import CreateArticleModel from '../../../models/knowledge-base/CreateArticleModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import useYupLocal from '../../../translation/YupLocal';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../ui/text-input/TextInput';
import articleValidationSchema from '../ArticleValidationSchema';
import TextArea from '../../../ui/text-area/TextArea';
import { MutationKey, MutationPath, usePostMutation } from '../../../react-query/MutationQueries';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { KnowledgeBaseArticle } from '../../../models/knowledge-base/KnowledgeBaseModel';
import RichTextEditor from '../../../ui/rich-text/RichTextEditor';
import ActionBar from '../../../ui/action-bar/ActionBar';
import usePreventNavigation from '../../../custom-hooks/PreventNavigation';
import Media from '../../../ui/media/Media';
import ArticleTag from '../article-tag/ArticleTag';

import './CreateArticle.scss';
import { ArticleComponentTypeSelection } from '../article-component-type/ArticleComponentTypeSelection';
import { ArticleMediaUrlBuilders } from '../../../react-query/KnowledgeBaseApi';
import { ArticleComponentSelection } from '../article-component/ArticleComponentSelection';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import { imageTagToDocument } from '../../../ui/media/ImageToDocument';

function MediaSection({
  field,
  setValue,
  getValues,
}: {
  field: ControllerRenderProps<CreateArticleModel, 'media'>;
  setValue: UseFormSetValue<CreateArticleModel>;
  getValues: UseFormGetValues<CreateArticleModel>;
}) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <Media
      mutationPath={MutationPath.UploadArticleMedia}
      mutationKey={MutationKey.PostUploadArticleMedia}
      media={field.value}
      urlBuilders={ArticleMediaUrlBuilders}
      isEditable
      isSideView
      onMediaChange={field.onChange}
      onCopyImage={(url) => setValue('content', imageTagToDocument(url, getValues('content')?.toString() ?? ''))}
      label={t('attachments')}
    />
  );
}

export function CreateArticle() {
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);

  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();
  const navigate = useNavigate();

  const { mutate, isPending } = usePostMutation<CreateArticleModel, KnowledgeBaseArticle>(MutationKey.PostArticle, {
    onSuccess: (article) => navigate(NavigationRoutes.KnowledgeBaseArticleId(article.id), { replace: true }),
    onError: () => {
      toast.error(tError('createArticleError'));
    },
  });

  const { handleSubmit, control, formState, getValues, setValue } = useForm<CreateArticleModel>({
    mode: 'onChange',
    resolver: yupResolver(articleValidationSchema(yup)),
  });

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const onSubmit = (createArticleModel: CreateArticleModel) => {
    setPreventNavigation(false);
    mutate({
      body: {
        title: createArticleModel.title,
        descriptionShort: createArticleModel.descriptionShort,
        content: createArticleModel.content,
        highlighted: createArticleModel.highlighted,
        media: createArticleModel.media,
        tags: createArticleModel.tags,
        componentTypes: createArticleModel.componentTypes,
        components: createArticleModel.components,
      },
      path: MutationPath.CreateArticle,
    });
  };

  const TitleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'title'> }) => (
      <FormFieldWrapper error={formState.errors?.title}>
        <TextInput
          placeholder={t('titlePlaceholder')}
          onValueChange={field.onChange}
          label={t('title')}
          value={field.value}
          error={formState.errors?.title}
          dataRole="article-title"
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors.title, t],
  );

  const DescriptionShortTextArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'descriptionShort'> }) => (
      <FormFieldWrapper error={formState.errors?.descriptionShort}>
        <TextArea
          placeholder={t('descriptionShortPlaceholder')}
          onValueChange={field.onChange}
          label={t('descriptionShort')}
          value={field.value}
          error={formState.errors?.descriptionShort}
          dataRole="article-descriptionShort"
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors.descriptionShort, t],
  );

  const ContentTextArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'content'> }) => (
      <FormFieldWrapper error={formState.errors?.content}>
        <RichTextEditor
          id="articleDescription"
          placeholder={t('contentPlaceholder')}
          label={t('content')}
          value={field.value}
          onChange={field.onChange}
          initHeight={450}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.content, t],
  );

  const TagArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'tags'> }) => (
      <FormFieldWrapper>
        <ArticleTag onChange={field.onChange} values={field.value} />
      </FormFieldWrapper>
    ),
    [],
  );

  const Components = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'components'> }) => (
      <FormFieldWrapper>
        <ArticleComponentSelection onChange={field.onChange} values={field.value} />
      </FormFieldWrapper>
    ),
    [],
  );

  const ComponentTypes = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'componentTypes'> }) => (
      <FormFieldWrapper>
        <ArticleComponentTypeSelection onChange={field.onChange} values={field.value} />
      </FormFieldWrapper>
    ),
    [],
  );

  const isHighlightedSwitch = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<CreateArticleModel, 'highlighted'> }) => (
      <FormFieldWrapper>
        <SwitchInput id="isHighlighted" onChange={field.onChange} checked={field.value} label={t('highlight')} />
      </FormFieldWrapper>
    ),
    [t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ActionBar
        right={
          <button
            className={classNames('button button-actionbar is-primary', { 'is-loading': isPending })}
            type="submit"
            data-role="create-article-submit-button"
          >
            {t('publishNewArticle')}
          </button>
        }
      />

      <div className="columns create-article is-5">
        <div className="column is-7">
          <Controller name="title" control={control} render={TitleInput} />
          <Controller name="content" control={control} render={ContentTextArea} />
        </div>
        <div className="column is-5">
          <Controller name="descriptionShort" control={control} render={DescriptionShortTextArea} />
          <Controller name="highlighted" control={control} defaultValue={false} render={isHighlightedSwitch} />
          <Controller name="tags" control={control} defaultValue={[]} render={TagArea} />
          <Controller name="components" control={control} defaultValue={[]} render={Components} />
          <Controller name="componentTypes" control={control} defaultValue={[]} render={ComponentTypes} />
          <Controller
            name="media"
            control={control}
            defaultValue={[]}
            render={({ field }) => <MediaSection field={field} getValues={getValues} setValue={setValue} />}
          />
        </div>
      </div>
    </form>
  );
}
