import { useState } from 'react';
import { useGetComponent } from '../../../react-query/ComponentApi';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import SingleComponentSelect from '../../../ticketing/create-ticket/SingleComponentSelect/SingleComponentSelect';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { GenericConditionConfigurationProps } from './GenericConditionConfigurationProps';
import { RuleCondition } from '../../../models/operation/AutomationRuleModel';
import { BaseComponentItem } from "../../../models/operation/ComponentModel";

interface HasAssignedComponentConfiguration extends RuleCondition {
  type: 'hasAssignedComponent';
  withDescendents: boolean;
  componentId: string;
}

export default function HasAssignedComponentCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasAssignedComponentConfiguration>) {
  const [componentId, setComponentId] = useState<string | undefined>(condition?.componentId);
  const [withDescendents, setWithDescendents] = useState<boolean>(condition?.withDescendents ?? false);
  const { t } = useTranslationText('automations');

  const update = (id: string | undefined, descendants: boolean) => {
    if (id) {
      handleConfigurationUpdate({
        type: 'hasAssignedComponent',
        componentId: id,
        withDescendents: descendants,
      } as HasAssignedComponentConfiguration);
    }
  };

  const handleComponentsChanged = (component: BaseComponentItem | null) => {
    setComponentId(component?.id);
    if (!component) {
      onClear();
    }
    update(component?.id, withDescendents);
  };

  const handleSwitchChange = (value: boolean) => {
    setWithDescendents(value);
    update(componentId, value);
  };

  const { data: selectedComponent } = useGetComponent(componentId!, { enabled: !!componentId });

  return (
    <div>
      <SingleComponentSelect onChange={handleComponentsChanged} value={selectedComponent || null} />
      {componentId && (
        <SwitchInput
          checked={withDescendents}
          id="with-descendents"
          label={t('withDescendentComponents')}
          disabled={false}
          onChange={handleSwitchChange}
        />
      )}
    </div>
  );
}
