import { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { MutationKey, MutationPath, usePostMutation } from '../../../../react-query/MutationQueries';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { KnowledgeBaseArticleComment } from '../../../../models/knowledge-base/KnowledgeBaseModel';
import RichTextEditor from '../../../../ui/rich-text/RichTextEditor';
import useToggle from '../../../../custom-hooks/useToggle';
import './CreateArticleComment.scss';
import ServerStateKeysEnum from '../../../../react-query/ServerStateKeysEnum';
import CreateCommentModel from '../../../../models/operation/CreateCommentModel';

interface BaseCreateArticleCommentProps {
  id?: string;

  onCancel?: () => void;
  onSubmit?: () => void;
}

interface CreateCommentProps extends BaseCreateArticleCommentProps {
  queryKey: ServerStateKeysEnum;
  identifier: string;
  mutationKey: MutationKey;
  mutationPath: MutationPath;
}

function CreateComment({
  id = 'articleComment',
  queryKey,
  identifier,
  mutationKey,
  mutationPath,
  onCancel = () => {},
  onSubmit = () => {},
}: CreateCommentProps) {
  const { t } = useTranslationText('comments');
  const { t: tError } = useTranslationText('errorTexts');
  const [text, setText] = useState('');
  const queryClient = useQueryClient();

  const [isCreateCommentVisible, toggleIsCreateCommentVisible] = useToggle(false);

  const { mutate, isPending } = usePostMutation<CreateCommentModel, KnowledgeBaseArticleComment>(mutationKey, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKey, identifier] });
      setText('');
      toggleIsCreateCommentVisible();
    },
    onError: () => {
      toast.error(tError('createCommentError'));
    },
  });

  const handleSubmit = () => {
    mutate(
      { body: { text }, path: mutationPath },
      {
        onSuccess: () => onSubmit(),
      },
    );
  };

  if (isCreateCommentVisible) {
    return (
      <div className="create-article-comment-container">
        <RichTextEditor
          id={id}
          placeholder={t('createCommentPlaceholder')}
          onChange={setText}
          value={text}
          autofocus
          initHeight={200}
        />
        <div className="is-fluid is-flex is-justify-content-space-between is-align-items-center is-fullwidth is-flex-column-mobile">
          <button
            className="button is-danger is-outlined mt-2 is-fullwidth-mobile"
            type="button"
            onClick={() => {
              toggleIsCreateCommentVisible();
              setText('');
              onCancel();
            }}
          >
            {t('abort')}
          </button>
          <button
            className={classNames('button', 'is-primary', 'mt-2', 'is-fullwidth-mobile', { 'is-loading': isPending })}
            type="button"
            onClick={handleSubmit}
          >
            {t('saveComment')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {!isCreateCommentVisible && (
        <button
          className="input create-article-comment-button"
          type="button"
          onClick={() => {
            toggleIsCreateCommentVisible();
          }}
        >
          {t('createComment')}
        </button>
      )}
    </div>
  );
}

interface CreateArticleCommentProps extends BaseCreateArticleCommentProps {
  articleId: string;
  queryKey?: ServerStateKeysEnum;
}

export function CreateArticleComment({
  id = 'articleComment',
  articleId,
  queryKey = ServerStateKeysEnum.ArticleComments,
  onCancel = () => {},
  onSubmit = () => {},
}: CreateArticleCommentProps) {
  return (
    <CreateComment
      id={id}
      queryKey={queryKey}
      identifier={articleId}
      mutationKey={MutationKey.PostArticleComment}
      mutationPath={MutationPath.CreateArticleComment(articleId)}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}
