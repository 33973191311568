import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Select, { InputActionMeta, SingleValue, StylesConfig } from 'react-select';
import './JSXSearchDropdown.scss';
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';

// TODO ADB-558: refactor ReactSelectOption
export interface JSXSelectOption {
  readonly label: JSX.Element;
  readonly value: any;
  labelText: string
}

interface JSXSearchDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  options: JSXSelectOption[];
  onChange: (selectedOption: SingleValue<JSXSelectOption>) => void;
  placeholder?: string;
  value?: T;
  onInputChange?: (value: string, action?: InputActionMeta) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T) => SingleValue<JSXSelectOption>;
  requiredPermission: UserPermission;
  hideDropDownButton?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  isSmall?: boolean;
  filterOption?: (option: FilterOptionOption<JSXSelectOption>, inputValue: string) => boolean
}

export default function JSXSearchDropdown<T>({
  dataRole,
  isError,
  isLoading,
  label,
  noOptionsMessage,
  isLoadingMessage,
  options,
  onChange,
  placeholder,
  value,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  hideDropDownButton = false,
  isClearable = false,
  isSearchable,
  openMenuOnClick = false,
  isSmall,
  filterOption
}: JSXSearchDropdownProps<T>): React.ReactElement<JSXSearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<SingleValue<JSXSelectOption | undefined>>(undefined);
  const { hasPermission } = useAuthUser();

  function handleChange(newValue: SingleValue<JSXSelectOption>) {
    onChange(newValue);
  }

  useEffect(() => {
    if (value && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(value));
    } else {
      setSelectOption(undefined);
    }
  }, [mapValueToSelectOption, value]);

  const colourStyles: StylesConfig<JSXSelectOption> = {
    menu: (styles) => ({ ...styles, display: value ? 'none' : 'block' }),
    dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  };

  return (
    <div className="field is-fullwidth" data-role={dataRole}>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control is-fullwidth">
        <Select
          className={classNames({
            'react-select-container': true,
            'react-select-container-error': isError,
            'is-small': isSmall,
          })}
          classNamePrefix="react-select"
          options={options}
          styles={hideDropDownButton ? colourStyles : undefined}
          isClearable={isClearable}
          isLoading={isLoading}
          loadingMessage={() => isLoadingMessage}
          onInputChange={onInputChange}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebaren Fehler
          onChange={handleChange as any}
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={selectOption}
          isDisabled={!hasPermission(requiredPermission)}
          filterOption={filterOption}
        />
      </div>
    </div>
  );
}
