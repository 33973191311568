import { Tag } from '@aos/styleguide-react';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { TicketRelationDto } from '../../../models/operation/RelationTypeModel';
import { toRelationSpec } from '../../../models/operation/RelationTypeFunctions';
import { useRelationTypeTranslations } from '../../../translation/TranslationHooks';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';

interface TicketRelationItemProps {
  relation: TicketRelationDto;
  onDelete?: () => void;
  index: number;
}

function TicketRelationItem({ relation, onDelete, index }: TicketRelationItemProps) {
  const relSpec = toRelationSpec(relation);
  const { translateRelation } = useRelationTypeTranslations();

  const other = relation.source ?? relation.target!!;

  return (
    <ListItemWrapper
      field="serviceTime"
      index={index}
      onRemove={onDelete}
      itemLink={NavigationRoutes.TicketAllId(other.id)}
      deletePermission={UserResourcePermissions.ComponentRelation.Delete}
    >
      <div className="ticket-relation-item" data-role="ticket-relation-item" data-component-display-name={other.title}>
        <Tag color="is-default">{translateRelation(relSpec)}</Tag>
        <div>{other.title}</div>
      </div>
    </ListItemWrapper>
  );
}

export default TicketRelationItem;
