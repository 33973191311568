import React from 'react';
import classNames from 'classnames';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import './RichTextEditor.scss';

export interface RichTextEditorProps extends Omit<IAllProps, 'onChange' | 'onEditorChange'> {
  autofocus?: true;
  error?: string;
  initHeight?: number;
  isWarning?: boolean;
  label?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export default function RichTextEditor({
  autofocus,
  error,
  initHeight = 400,
  isWarning,
  label,
  onBlur,
  onChange,
  placeholder = '',
  value,
  ...rteProps
}: RichTextEditorProps) {
  return (
    <div className="field">
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control is-fullwidth">
        <div
          data-role="rte-wrapper"
          className={classNames('editor', {
            'rte-danger': error,
            'rte-warning': isWarning,
          })}
        >
          <Editor
            init={{
              auto_focus: autofocus,
              language: 'de',
              height: initHeight,
              promotion: false,
              placeholder,
              min_height: initHeight,
              menubar: true,
              resize: true,
              convert_urls: false,
              removed_menuitems: 'newdocument',
              images_upload_base_path: '/',
              relative_urls: false,
              remove_script_host: false,
              plugins: [
                'autoresize',
                'image',
                'advlist',
                'autolink',
                'lists',
                'link',
                'charmap',
                'anchor',
                'searchreplace',
                'visualblocks',
                'fullscreen',
                'insertdatetime',
                'table',
                'code',
                'help',
                'wordcount',
              ],
              contextmenu: 'link image',
              toolbar:
                'undo redo | styles | bold italic backcolor |' +
                'link | image | alignleft aligncenter alignright alignjustify |' +
                'bullist numlist outdent indent | table | help',
            }}
            onBlur={(e, editor) => {
              e.preventDefault();
              onBlur?.(e, editor);
            }}
            onEditorChange={onChange}
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={value}
            {...rteProps}
          />
        </div>
      </div>
    </div>
  );
}
