import { Actor, ActorKind } from '../../models/actor/Actor';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetUser } from '../../react-query/TicketingSystemApi';
import { useGetAutomationRuleById } from '../../react-query/AutomationApi';
import { userToDisplayName } from '../../models/operation/UserModel';

export function useActorKindTranslation() {
  const { t } = useTranslationText('actors');
  return (kind: ActorKind) => t(`kind_${kind}`);
}

export interface ActorLabelProps {
  actor?: Actor;
  emailAddress?: String;
}

interface UserActorLabelProps {
  userId: string;
}

interface EmailActorLabelProps {
  actor: Actor;
  emailAddress: String;
}

function UserActorLabel({ userId }: UserActorLabelProps) {
  const { data: user, isLoading } = useGetUser(userId);
  return isLoading ? <></> : <>{user ? userToDisplayName(user) : userId}</>;
}

function EmailActorLabel({ actor, emailAddress }: EmailActorLabelProps) {
  const actorKindTranslation = useActorKindTranslation();
  return (
    <>
      {actorKindTranslation(actor.kind)} ({emailAddress})
    </>
  );
}

interface AutomationRuleActorLabelProps {
  ruleId: string;
}

function AutomationRuleActorLabel({ ruleId }: AutomationRuleActorLabelProps) {
  const { t } = useTranslationText('automations');

  const { data: ruleNameData, isSuccess: ruleNameIsSuccess } = useGetAutomationRuleById(ruleId, {
    suppressErrorToast: true,
  });

  const actorKindTranslation = useActorKindTranslation();
  return (
    <>
      {actorKindTranslation('AUTOMATION_RULE')}{' '}
      <i>{ruleNameIsSuccess ? ruleNameData.name ?? ruleId : t('unknownRule')}</i>
    </>
  );
}

function DefaultActorLabel({ actor }: ActorLabelProps) {
  const actorKindTranslation = useActorKindTranslation();
  return <>{actor && <>{actorKindTranslation(actor.kind)}</>}</>;
}

export default function ActorLabel({ actor, emailAddress }: ActorLabelProps) {
  switch (actor?.kind) {
    case 'USER':
      return actor?.id ? <UserActorLabel userId={actor.id} /> : <DefaultActorLabel actor={actor} />;
    case 'AUTOMATION_RULE':
      return actor?.id ? <AutomationRuleActorLabel ruleId={actor.id} /> : <DefaultActorLabel actor={actor} />;
    case 'EMAIL':
      return <EmailActorLabel actor={actor} emailAddress={emailAddress!!} />;
    default:
      return <DefaultActorLabel actor={actor} />;
  }
}
