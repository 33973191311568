import React, { useMemo } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router';
import LoadingSpinner from './ui/loading-spinner/LoadingSpinner';
import useRuntimeConfig from './runtime-config/RuntimeConfig';
import StatusPage from './status-page/StatusPage';
import { AppSignIn } from './AppSignIn';

const REDIRECT_SESSION_STORAGE_KEY = 'post-login-target';

function useLoginCallBackWithRedirect(): [string, () => void] {
  const target = window.location.pathname + window.location.search;
  const navigate = useNavigate();

  const callbackUri = useMemo(() => {
    if (!target.startsWith('/callback')) {
      sessionStorage.setItem(REDIRECT_SESSION_STORAGE_KEY, target);
    }
    return `${window.location.origin}/callback`;
  }, [target]);

  const postLoginHandler = async () => {
    const redirectTarget = sessionStorage.getItem(REDIRECT_SESSION_STORAGE_KEY);

    if (redirectTarget) {
      sessionStorage.removeItem(REDIRECT_SESSION_STORAGE_KEY);
      navigate(redirectTarget, { replace: true });
      return;
    }

    // Remove keycloak params and keep others
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('state');
    searchParams.delete('session_state');
    searchParams.delete('code');
    searchParams.delete('iss');

    const hasParams = Array.from(searchParams.values()).length > 0;
    const url = hasParams ? `${window.location.pathname}?${searchParams.toString()}` : window.location.pathname;

    window.history.replaceState({}, document.title, url);
  };

  return [callbackUri, postLoginHandler];
}

export function AppWithAuthentication() {
  const [runtimeConfig, isLoadingConfig] = useRuntimeConfig();
  const [redirectUri, handlePostLogin] = useLoginCallBackWithRedirect();

  return (
    <LoadingSpinner isLoading={isLoadingConfig}>
      <>
        {runtimeConfig ? (
          <Routes>
            <Route path="/status" element={<StatusPage />} />
            <Route
              path="/*"
              element={
                <AuthProvider
                  accessTokenExpiringNotificationTimeInSeconds={120}
                  authority={runtimeConfig.oauthAuthority}
                  client_id={runtimeConfig.clientId}
                  redirect_uri={redirectUri}
                  scope="openid profile phone"
                  onSigninCallback={handlePostLogin}
                  post_logout_redirect_uri={window.location.origin}
                >
                  <AppSignIn />
                </AuthProvider>
              }
            />
          </Routes>
        ) : (
          <></>
        )}
      </>
    </LoadingSpinner>
  );
}
