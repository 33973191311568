import { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldErrorsImpl,
  FormState,
  Merge,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import { Agent } from '../../../../models/monitoring/Agent';
import { SnmpModule } from '../../../../models/monitoring/AgentModule';
import { SnmpTable } from '../../../../models/snmp/Snmp';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import Collapsible from '../../../../ui/collapsible/Collapsible';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../../../ui/list-item-wrapper/ListItemWrapper';
import TextInput from '../../../../ui/text-input/TextInput';
import { SnmpModuleTableFieldForm } from './SnmpModuleTableFieldForm';

interface SnmpModuleTableFormProps {
  form: UseFormReturn<Agent, object>;
  moduleIndex: number;
  tableIndex: number;
  onRemove: () => void;
}

function useSnmpModuleTableErrors(formState: FormState<Agent>, moduleIndex: number, fieldIndex: number) {
  return useCallback(
    (field: keyof SnmpTable): FieldError | undefined => {
      const modules = formState.errors?.modules;
      if (!modules) {
        return undefined;
      }
      const module = modules[moduleIndex];
      if (!module) {
        return undefined;
      }
      const typedModule = module as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpModule>>>;
      if (!typedModule) {
        return undefined;
      }
      const snmpTables = typedModule.tables as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpTable[]>>>;
      if (!snmpTables) {
        return undefined;
      }
      const snmpTable = snmpTables[fieldIndex] as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpTable>>>;
      if (!snmpTable) {
        return undefined;
      }
      return snmpTable[field] as FieldError;
    },
    [formState.errors?.modules, moduleIndex, fieldIndex]
  );
}

export function SnmpModuleTableForm({ form, moduleIndex, onRemove, tableIndex }: SnmpModuleTableFormProps) {
  const { t } = useTranslationText('agents');

  const { control, formState } = form;
  const getErrors = useSnmpModuleTableErrors(formState, moduleIndex, tableIndex);

  const {
    append: appendField,
    fields: snmpFields,
    remove: removeField,
  } = useFieldArray({
    control,
    name: `modules.${moduleIndex}.tables.${tableIndex}.fields`,
  });

  const OidInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<Agent, `modules.${typeof moduleIndex}.tables.${typeof tableIndex}.oid`>;
    }) => (
      <FormFieldWrapper error={getErrors('oid')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('oid')}
          label={t('moduleSnmpTableOid')}
          isRequired
          dataRole={`module-${moduleIndex}-tables-${tableIndex}-oid`}
          key={`module-${moduleIndex}-tables-${tableIndex}-oid`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, tableIndex]
  );

  const NameInput = useCallback(
    ({
      field,
    }: {
      // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
      // eslint-disable-next-line react/no-unused-prop-types
      field: ControllerRenderProps<Agent, `modules.${typeof moduleIndex}.tables.${typeof tableIndex}.name`>;
    }) => (
      <FormFieldWrapper error={getErrors('name')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('name')}
          label={t('moduleSnmpTableName')}
          isRequired
          dataRole={`module-${moduleIndex}-tables-${tableIndex}-name`}
          key={`module-${moduleIndex}-tables-${tableIndex}-name`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, tableIndex]
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`modules.${moduleIndex}.fields.${tableIndex}`}
      index={tableIndex}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.Agent.Update}
      key={`agent-module-${moduleIndex}-snmp-field-${tableIndex}`}
    >
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-6">
            <Controller name={`modules.${moduleIndex}.tables.${tableIndex}.oid`} control={control} render={OidInput} />
          </div>
          <div className="column is-6">
            <Controller
              name={`modules.${moduleIndex}.tables.${tableIndex}.name`}
              control={control}
              render={NameInput}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <Collapsible
              title={t('snmpFields')}
              collapsibleKey="agent-modules-snmp-tables"
              initialExpanded
              addPermission={UserResourcePermissions.Agent.Update}
              addBtnClicked={() => appendField({ id: undefined, oid: '', name: '', metricIsTagged: false })}
            >
              <div>
                {snmpFields.map((field, fieldIndex) => (
                  <SnmpModuleTableFieldForm
                    form={form}
                    moduleIndex={moduleIndex}
                    tableIndex={tableIndex}
                    fieldIndex={fieldIndex}
                    onRemove={() => removeField(fieldIndex)}
                    key={field.id}
                  />
                ))}
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </ListItemWrapper>
  );
}
