import {
  faBook,
  faFileSignature,
  faHome,
  faScrewdriverWrench,
  faSitemap,
  faTicket,
} from '@fortawesome/free-solid-svg-icons';
import NavigationRoutes from './NavigationRoutes';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import { MainNavigationItem } from '../models/navigation/MainNavigationItem';

const navItems = {
  dashboard: {
    titleTranslationKey: 'dashboard',
    icon: faHome,
    path: NavigationRoutes.Dashboard,
    requiredPermission: UserResourcePermissions.Component.Read,
  },
  ticketingSystem: {
    titleTranslationKey: 'ticketingSystem',
    icon: faTicket,
    path: NavigationRoutes.TicketsRoot,
    requiredPermission: UserResourcePermissions.Ticket.Read.or(UserResourcePermissions.Task.Read),
    children: {
      currentTickets: {
        titleTranslationKey: 'currentTickets',
        path: NavigationRoutes.TicketsCurrent,
        requiredPermission: UserResourcePermissions.Ticket.Read,
      },
      allTickets: {
        titleTranslationKey: 'allTickets',
        path: NavigationRoutes.TicketsAll,
        requiredPermission: UserResourcePermissions.Ticket.Read,
      },
      allTasks: {
        titleTranslationKey: 'allTasks',
        path: NavigationRoutes.TasksAll,
        requiredPermission: UserResourcePermissions.Task.Read,
      },
      automation: {
        titleTranslationKey: 'automationRules',
        path: NavigationRoutes.AutomationRules,
        requiredPermission: UserResourcePermissions.Rule.CreateOrUpdate,
      },
    },
  },
  knowledgeBase: {
    titleTranslationKey: 'knowledgeBase',
    icon: faBook,
    path: NavigationRoutes.KnowledgeBaseRoot,
    requiredPermission: UserResourcePermissions.Article.Read,
    children: {
      dashboard: {
        titleTranslationKey: 'dashboard',
        path: NavigationRoutes.KnowledgeBaseDashboard,
      },
      allArticles: {
        titleTranslationKey: 'article',
        path: NavigationRoutes.KnowledgeBaseArticle,
      },
    },
  },
  components: {
    titleTranslationKey: 'components',
    icon: faSitemap,
    path: NavigationRoutes.ComponentsRoot,
    requiredPermission: UserResourcePermissions.Component.Read,
    children: {
      all: {
        titleTranslationKey: 'componentOverview',
        path: NavigationRoutes.Components,
      },
      systems: {
        titleTranslationKey: 'systems',
        path: NavigationRoutes.Systems,
        requiredPermission: UserResourcePermissions.System.Read,
      },
      map: {
        titleTranslationKey: 'componentMap',
        path: NavigationRoutes.ComponentsMap,
      },
      reports: {
        titleTranslationKey: 'reports',
        path: NavigationRoutes.Reports,
        requiredPermission: UserResourcePermissions.Component.Read,
      },
      types: {
        titleTranslationKey: 'componentTypes',
        path: NavigationRoutes.ComponentTypes,
        requiredPermission: UserResourcePermissions.ComponentType.Read,
      },
      alertOverview: {
        titleTranslationKey: 'activeAlertOverview',
        path: NavigationRoutes.ActiveAlertOverview,
        requiredPermission: UserResourcePermissions.ActiveAlertOverview.Read,
      },
      agents: {
        titleTranslationKey: 'agents',
        path: NavigationRoutes.Agents,
        requiredPermission: UserResourcePermissions.Agent.Read,
      },
    },
  },
  maintenance: {
    titleTranslationKey: 'maintenance',
    icon: faScrewdriverWrench,
    path: NavigationRoutes.MaintenanceRoot,
    requiredPermission: UserResourcePermissions.MaintenanceRequest.Read,
    children: {
      overview: {
        titleTranslationKey: 'maintenanceOverview',
        path: NavigationRoutes.MaintenanceOverview,
        requiredPermission: UserResourcePermissions.MaintenanceRequest.Read,
      },
      maintenanceRequests: {
        titleTranslationKey: 'maintenanceRequests',
        path: NavigationRoutes.MaintenanceRequests,
        requiredPermission: UserResourcePermissions.MaintenanceRequest.Read,
      },
    },
  },
  contractManagement: {
    titleTranslationKey: 'contractManagement',
    icon: faFileSignature,
    path: NavigationRoutes.ContractManagementRoot,
    requiredPermission: UserResourcePermissions.Contract.Read,
    children: {
      allContracts: {
        titleTranslationKey: 'allContracts',
        path: NavigationRoutes.AllContracts,
      },
      allContractors: {
        titleTranslationKey: 'allContractors',
        path: NavigationRoutes.AllContractors,
      },
    },
  },
} as const satisfies Record<string, MainNavigationItem>;

export default navItems;
