import dayjs from 'dayjs';
import {
  keyForArticleCommentModel,
  KnowledgeBaseArticleComment,
} from '../../../../models/knowledge-base/KnowledgeBaseModel';
import ArticleCommentListingItem from './item/ArticleCommentListingItem';
import LoadingSpinner from '../../../../ui/loading-spinner/LoadingSpinner';
import './ArticleCommentListing.scss';

interface CommentListingProps {
  comments: KnowledgeBaseArticleComment[];
  isLoading: boolean;
  error: string | undefined;
}

export default function ArticleCommentListing({ comments, isLoading, error }: CommentListingProps) {
  const commentListingElement = comments
    .sort((comment1, comment2) => -dayjs(comment1?.createdAt).diff(dayjs(comment2?.createdAt || 0)) || 0)
    .map((record) => <ArticleCommentListingItem key={keyForArticleCommentModel(record)} articleComment={record} />);

  return (
    <LoadingSpinner isLoading={isLoading} errors={error}>
      <div data-role="article-comments" className="article-comment-listing">
        {commentListingElement}
      </div>
    </LoadingSpinner>
  );
}
