import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { NotificationSettingItem } from '../models/user/NotificationSettings';
import { UserNotificationSetting } from '../models/user/UserNotificationSetting';
import UserInfo from '../models/user/UserInfo';

export function useGetUserSetting(section: string, condition: string, options?: QueryOptions<UserNotificationSetting>) {
  return useGetQuery<UserNotificationSetting>(
    [ServerStateKeysEnum.UserSettings, section, condition],
    `/api/ticketing/user/settings?section=${section}&condition=${condition}`,
    options,
  );
}

export function useGetSettings(options?: QueryOptions<Array<NotificationSettingItem>>) {
  return useGetQuery<Array<NotificationSettingItem>>(
    [ServerStateKeysEnum.UserSettingsAll],
    '/api/ticketing/settings',
    options,
  );
}

export function useGetUserInfo(options?: QueryOptions<UserInfo>) {
  return useGetQuery<UserInfo>([ServerStateKeysEnum.UserInfo], '/api/ticketing/user/info', options);
}
