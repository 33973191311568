import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { KnowledgeBaseArticleTag } from './KnowledgeBaseArticleTagModel';

export const sortKnowledgeBaseArticleTagDesc = (
  items: KnowledgeBaseArticleTag[] | undefined,
): KnowledgeBaseArticleTag[] => items?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];

export const sortAndMapKnowledgeBaseTagsToOption = (
  items: KnowledgeBaseArticleTag[] | undefined,
): ReactSelectOption[] =>
  sortKnowledgeBaseArticleTagDesc(items)?.map((item) => ({
    value: `${item.id}`,
    label: item.title,
  })) ?? [];
