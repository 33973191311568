import { useState } from 'react';
import { MultiValue } from 'react-select';
import { getSeverityIcon } from '../../../../models/operation/TicketFunctions';
import { useGetSeverities } from '../../../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../../ui/search-dropdown/SearchDropdown';
import './HasSeveritiesCondtion.scss';
import { RuleCondition } from '../../../../models/operation/AutomationRuleModel';
import { GenericConditionConfigurationProps } from '../GenericConditionConfigurationProps';
import {
  getEnumKeyFromSelectOption,
  toReactSelectFromEnumObject,
  toReactSelectFromTranslationKey,
  toTranslationKeysFromEnumKeys,
} from '../../../../utils/EnumSelectMapper';

function formatOptionLabel(opt: ReactSelectOption) {
  return (
    <div className="state-flex">
      <span className="severity-icon-wrapper">{getSeverityIcon(opt.value)}</span>
      <span className="state-label">{opt.label}</span>
    </div>
  );
}

interface HasSeveritiesConfiguration extends RuleCondition {
  type: 'hasSeverities';
  severities: string[];
}

export default function HasSeveritiesCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasSeveritiesConfiguration>) {
  const { t: tTicket } = useTranslationText('tickets');
  const { data: severitiesData, isLoading, isError } = useGetSeverities();
  const enumKeySelector = (opt: ReactSelectOption) => getEnumKeyFromSelectOption(severitiesData, opt);

  const [severities, setSeverities] = useState<MultiValue<ReactSelectOption>>(
    toReactSelectFromTranslationKey(tTicket, toTranslationKeysFromEnumKeys(severitiesData, condition?.severities)),
  );

  const handleChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    setSeverities(selectedOptions);
    if (selectedOptions.length > 0) {
      handleConfigurationUpdate({
        type: 'hasSeverities',
        severities: selectedOptions.map(enumKeySelector),
      } as HasSeveritiesConfiguration);
    } else {
      onClear();
    }
  };

  return (
    <>
      <MultiSelectDropdown
        dataRole="severity-select"
        isError={isError}
        isLoading={isLoading}
        onChange={handleChange}
        options={toReactSelectFromEnumObject(tTicket, severitiesData)}
        openMenuOnClick
        isSearchable={false}
        placeholder={tTicket('selectSeverities')}
        mappedValues={severities}
        formatOptionLabel={formatOptionLabel}
        label={tTicket('severitiesConditionLabel')}
      />
    </>
  );
}
