import React, { useState } from 'react';

export interface DropZoneContextInterface {
  isDropZoneEnabled: boolean;
  enableDropZone: () => void;
  disableDropZone: () => void;
  clearDroppedFiles: () => void;
  droppedFiles?: File[];
  onDrop: (fileList: File[]) => void;
}

export const DropZoneContext = React.createContext<DropZoneContextInterface>({
  isDropZoneEnabled: false,
  enableDropZone: () => {},
  clearDroppedFiles: () => {},
  disableDropZone: () => {},
  droppedFiles: [],
  onDrop: () => {},
});
export function DropZoneProvider({ children }: any) {
  const initState = {
    isDropZoneEnabled: false,
  } as DropZoneContextInterface;

  const [state, setState] = useState<DropZoneContextInterface>(initState);

  const onDrop = (droppedFiles: File[]) => {
    setState((prevState) => ({
      ...prevState,
      droppedFiles,
    }));
  };

  const enableDropZone = () => {
    if (!state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        isDropZoneEnabled: true,
      }));
    }
  };
  const disableDropZone = () => {
    if (state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        isDropZoneEnabled: false,
        droppedFiles: [],
      }));
    }
  };

  const clearDroppedFiles = () => {
    if (state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        droppedFiles: [],
      }));
    }
  };

  return (
    // Wenn man hier um die context-values ein useMemo / useCallback packt, meckert der NavigationContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DropZoneContext.Provider value={{ ...state, enableDropZone, disableDropZone, onDrop, clearDroppedFiles }}>
      {children}
    </DropZoneContext.Provider>
  );
}
