import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { MaintenanceStatus } from '../../../models/maintenance/MaintenanceStatus';
import { shouldShowStartOfActionHint } from '../../../models/operation/MaintenanceFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DatePicker from '../../../ui/date-picker/DatePicker';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import { AlternativeTimesCollapsible } from '../alternative-times-form/AlternativeTimesCollapsible';

interface MaintenanceTimesProps {
  form: UseFormReturn<BaseMaintenance>;
  readOnly: boolean;
}

export function MaintenanceTimes({ form, readOnly }: MaintenanceTimesProps) {
  const { t } = useTranslationText('maintenances');

  const { formState, control: formControl, watch } = form;

  const maintenanceStatus = watch('status');

  const StartOfActionInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'startOfAction'> }) => (
      <FormFieldWrapper error={formState.errors?.startOfAction}>
        <DatePicker
          enableTime
          value={field.value}
          label={t('startOfAction')}
          placeholder={t('startOfActionPlaceholder')}
          onValueChange={(newDate) => {
            field.onChange(newDate);
            void form.trigger('alternativeTimes');
          }}
          isError={!!formState.errors?.startOfAction}
          isRequired
          disabled={readOnly}
        />
        {shouldShowStartOfActionHint(field.value) && field.value !== undefined && (
          <div className="help is-info">{t('startOfActionHint')}</div>
        )}
      </FormFieldWrapper>
    ),
    [form, formState.errors?.startOfAction, readOnly, t]
  );

  const EndOfActionInput = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, 'endOfAction'> }) => (
      <FormFieldWrapper error={formState.errors?.endOfAction}>
        <DatePicker
          enableTime
          value={field.value}
          label={t('endOfAction')}
          placeholder={t('endOfActionPlaceholder')}
          onValueChange={(newDate) => {
            field.onChange(newDate);
            void form.trigger('alternativeTimes');
          }}
          isError={!!formState.errors?.endOfAction}
          isRequired
          disabled={readOnly}
        />
      </FormFieldWrapper>
    ),
    [form, formState.errors?.endOfAction, readOnly, t]
  );

  return (
    <>
      <div className="columns is-align-items-flex-start">
        <div className="column is-6">
          <Controller name="startOfAction" control={formControl} render={StartOfActionInput} />
        </div>
        <div className="column is-6">
          <Controller name="endOfAction" control={formControl} render={EndOfActionInput} />
        </div>
      </div>
      {maintenanceStatus === MaintenanceStatus.REQUEST && (
        <div className="columns is-align-items-flex-start">
          <div className="column is-12">
            <AlternativeTimesCollapsible form={form} readOnly={readOnly} />
          </div>
        </div>
      )}
    </>
  );
}
