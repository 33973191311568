import { useCallback, useState } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { SummarizedComponent } from '../models/operation/ComponentModel';
import { Page } from '../models/pagination/Pagination';
import useGetComponentsSummarized, { ComponentFilterQuery } from '../react-query/ComponentApi';

import { FetchError } from '../react-query/FetchError';

export default function useGetComponentSuggestions(options?: UseQueryOptions<Page<SummarizedComponent>, FetchError>) {
  const [filter, setFilter] = useState<ComponentFilterQuery>({});

  // convenience function to just set the name to search for
  const setComponentQuery = useCallback((displayName: string) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      displayName,
    }));
  }, []);

  const { data, isLoading, isError } = useGetComponentsSummarized(
    {
      ...options,
      enabled: filter.displayName != null && filter.displayName.length > 0,
      gcTime: 0,
    },
    {
      ...filter,
      size: 5,
      page: 0,
    },
  );

  return { data, isLoading, isError, filter, setFilter, setComponentQuery };
}
