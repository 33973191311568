import { TaskQuery } from '../../models/tasks/TaskQuery';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';

interface OpenQuestionFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TaskQuery, value: any) => void;
  secondLabel?: string;
}

export default function OpenQuestionFilter({
  isChecked = false,
  onChange,
  secondLabel,
}: OpenQuestionFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <SwitchInput
      id="highlighedTicketsFilter"
      checked={isChecked}
      onChange={(value) => onChange('hasOpenQuestions', value || undefined)}
      label={t('hasOpenQuestionsLabel')}
      secondLabel={secondLabel}
    />
  );
}
