import React from 'react';
import { FieldError, Merge } from 'react-hook-form';
import './FormFieldWrapper.scss';
import { useTranslationText } from '../../translation/TranslationHooks';

interface FormFieldWrapperProps {
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]> | undefined;
  children: React.ReactNode;
}

export default function FormFieldWrapper({ children, error }: FormFieldWrapperProps) {
  const { t } = useTranslationText('errorTexts');
  const errorMessage = error !== undefined && (
    <div data-role="form-error-message" className="help is-danger">
      {Array.isArray(error) ? (
        <ul>
          {error.map((err, idx) => (
            <li key={err.message}>{`#${idx + 1}: ${t(err.type)}`}</li>
          ))}
        </ul>
      ) : (
        error.message
      )}
    </div>
  );

  return (
    <div className="form-field-wrapper">
      {children}
      {errorMessage}
    </div>
  );
}
