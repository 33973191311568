import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Agent } from '../../../models/monitoring/Agent';
import { TomlModule } from '../../../models/monitoring/AgentModule';
import { useModuleErrors } from '../../../models/operation/AgentFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextArea from '../../../ui/text-area/TextArea';

interface TomlModuleProps {
  index: number;
  form: UseFormReturn<Agent, object>;
}

export function TomlModuleForm({ form, index }: TomlModuleProps) {
  const { t } = useTranslationText('agents');
  const { control, formState } = form;
  const getErrors = useModuleErrors<TomlModule>(formState, index);

  const TomlInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.toml`> }) => (
      <FormFieldWrapper error={getErrors('toml')}>
        <TextArea
          dataRole={`module-toml-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('toml')}
          label={t('moduleToml')}
          isRequired
          key={`module-toml-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  return (
    <>
      <div className="horizontal-wrapper">
        <Controller name={`modules.${index}.toml`} control={control} render={TomlInput} />
      </div>
    </>
  );
}
