import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { mapComponentsToOptions } from '../../models/operation/TicketFunctions';
import useGetComponentsSummarized from '../../react-query/ComponentApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import useGetComponentSuggestions from '../../components/GetComponentSuggestions';
import { BaseComponentItem } from '../../models/operation/ComponentModel';
import { JSXSelectOption } from '../jsx-search-dropdown/JSXSearchDropdown';
import MultiJSXSelectDropdown from '../multi-jsx-select-dropdown/MultiJSXSelectDropdown';

interface ComponentFilterProps {
  label?: string;
  selectedComponentIds: (string | null)[] | null | undefined;
  onChange: (name: keyof ComponentInterface, value: string[]) => void;
  size?: BulmaSize;
}

interface ComponentInterface {
  components: string[];
}

export default function ComponentFilter({
  label,
  selectedComponentIds,
  onChange,
  size = 'is-small',
}: ComponentFilterProps) {
  const { t } = useTranslationText('tickets');
  const {
    data: componentsPaged,
    isLoading: areComponentsLoading,
    isError: isComponentsError,
    setComponentQuery,
  } = useGetComponentSuggestions();

  // get components for selected componentIds
  const { data: components } = useGetComponentsSummarized(
    {
      enabled: !!selectedComponentIds,
    },
    { componentIds: selectedComponentIds },
  );

  const handleOnChange = (newSelectedComponents: MultiValue<JSXSelectOption>): void => {
    onChange(
      'components',
      newSelectedComponents.map((value) => (value.value as BaseComponentItem)?.id),
    );
  };

  return (
    <MultiJSXSelectDropdown
      dataRole="components-select"
      isError={isComponentsError}
      isLoading={areComponentsLoading}
      onChange={handleOnChange}
      onInputChange={setComponentQuery}
      options={mapComponentsToOptions(componentsPaged?.content)}
      mappedValues={mapComponentsToOptions(components?.content)}
      placeholder={t('selectComponent')}
      isLoadingMessage={t('componentsAreLoading')}
      noOptionsMessage={t('noSuggestionsAssignedComponents')}
      openMenuOnClick
      label={label}
      size={size}
    />
  );
}
