import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback } from 'react';
import { Agent } from '../../../models/monitoring/Agent';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { mapNetworkResponseProtocolsToOptions, useModuleErrors } from '../../../models/operation/AgentFunctions';
import { NetworkResponseModule } from '../../../models/monitoring/AgentModule';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../ui/text-input/TextInput';
import SingleValueInput from '../../../ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';

interface NetworkResponseModuleProps {
  index: number;
  form: UseFormReturn<Agent, object>;
}

export function NetworkResponseForm({ form, index }: NetworkResponseModuleProps) {
  const { t } = useTranslationText('agents');
  const { control, formState } = form;
  const getErrors = useModuleErrors<NetworkResponseModule>(formState, index);

  const ProtocolInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.protocol`> }) => (
      <FormFieldWrapper error={getErrors('protocol')}>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          label={t('moduleNetworkResponseProtocolLabel')}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleNetworkResponseProtocolPlaceholder')}
          isRequired
          options={mapNetworkResponseProtocolsToOptions()}
          dataRole={`module-${index}-protocol`}
          key={`module-${index}-protocol`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const HostInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.host`> }) => (
      <FormFieldWrapper error={getErrors('host')}>
        <TextInput
          dataRole={`module-${index}-host`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('host')}
          label={t('moduleNetworkResponseHost')}
          isRequired
          key={`module-${index}-host`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const PortInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.port`> }) => (
      <FormFieldWrapper error={getErrors('port')}>
        <TextInput
          dataRole={`module-${index}-port`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('port')}
          label={t('moduleNetworkResponsePort')}
          isRequired
          key={`module-${index}-port`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  return (
    <>
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-3">
            <Controller name={`modules.${index}.protocol`} control={control} render={ProtocolInput} />
          </div>
          <div className="column is-6">
            <Controller name={`modules.${index}.host`} control={control} render={HostInput} />
          </div>
          <div className="column is-3">
            <Controller name={`modules.${index}.port`} control={control} render={PortInput} />
          </div>
        </div>
      </div>
    </>
  );
}
