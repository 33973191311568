/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import './CreateComponent.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { CreateComponentModel } from '../../models/operation/CreateComponentModel';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import ActionBar from '../../ui/action-bar/ActionBar';
import Collapsible from '../../ui/collapsible/Collapsible';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../ui/text-input/TextInput';
import createComponentValidationSchema from '../CreateComponentValidationSchema';

export default function CreateComponent() {
  const DEFAULT_COMPONENT_TYPE_ID = 'system-Logical';
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);

  const { t } = useTranslationText('components');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver<CreateComponentModel>(createComponentValidationSchema(yup)),
  });

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const navigate = useNavigate();
  const { mutate, isPending } = usePostMutation<CreateComponentModel, ComponentItem>(MutationKey.PostComponent, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('createComponent'));
    },
    onError: () => {
      toast.error(tError('createComponentError'));
    },
  });

  const onSubmit = (ctm: CreateComponentModel) => {
    setPreventNavigation(false);

    mutate({
      body: {
        displayName: ctm.displayName,
        displayNameAlt1: ctm.displayNameAlt1?.length ? ctm.displayNameAlt1 : null,
        componentTypeId: DEFAULT_COMPONENT_TYPE_ID,
      },
      path: MutationPath.CreateComponent,
    });
  };

  const DisplayNameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateComponentModel, 'displayName'> }) => (
      <FormFieldWrapper error={formState.errors?.displayName}>
        <TextInput
          placeholder={t('displayNamePlaceholder')}
          dataRole="component-display-name"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.displayName}
          label={t('displayName')}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.displayName, t],
  );

  const DisplayNameAlt1Input = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateComponentModel, 'displayNameAlt1'> }) => (
      <FormFieldWrapper error={formState.errors?.displayNameAlt1}>
        <TextInput
          placeholder={t('displayNameAlt1Placeholder')}
          dataRole="component-display-name-alt-1"
          onValueChange={field.onChange}
          value={field?.value ?? ''}
          error={formState.errors?.displayNameAlt1}
          label={t('displayNameAlt1')}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.displayNameAlt1, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ActionBar
        right={
          <button
            className={classNames('button is-primary', { 'is-loading': isPending })}
            type="submit"
            aria-label="create-button"
            data-role="component-submit-button"
            disabled={!formState.isValid || !formState.isDirty}
          >
            {t('createNewComponent')}
          </button>
        }
      />
      <Collapsible title={t('metadata')} collapsibleKey="collapisble-metadat" initialExpanded>
        <div className="component-attribute-container">
          <Controller name="displayName" control={control} defaultValue="" render={DisplayNameInput} />
          <Controller name="displayNameAlt1" control={control} defaultValue="" render={DisplayNameAlt1Input} />
        </div>
      </Collapsible>
    </form>
  );
}
