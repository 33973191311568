import { useCallback, useEffect } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Agent } from '../../../../models/monitoring/Agent';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import {
  mapSnmpAuthProtocolsToOptions,
  mapSnmpEncryptionAlgorithmToOptions,
  mapSnmpSecurityLevelToOptions,
  useSnmpModuleAuthErrors,
} from '../../../../models/operation/AgentFunctions';
import { SnmpSecurityLevel } from '../../../../models/snmp/Snmp';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';
import SingleValueInput from '../../../../ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import TextInput from '../../../../ui/text-input/TextInput';

interface SnmpAuthFormProps {
  index: number;
  form: UseFormReturn<Agent, object>;
}

export function SnmpAuthForm({ index, form }: SnmpAuthFormProps) {
  const { t } = useTranslationText('agents');
  const { control, formState, watch, setValue } = form;
  const getErrors = useSnmpModuleAuthErrors(formState, index);

  const securityLevel = watch(`modules.${index}.auth.securityLevel`) as SnmpSecurityLevel | undefined;

  useEffect(() => {
    if (securityLevel === 'NO_AUTH_NO_PRIV') {
      setValue(`modules.${index}.auth.protocol`, undefined);
      setValue(`modules.${index}.auth.username`, undefined);
      setValue(`modules.${index}.auth.password`, undefined);
    }
    if (securityLevel === 'NO_AUTH_NO_PRIV' || securityLevel === 'AUTH_NO_PRIV') {
      setValue(`modules.${index}.auth.encryptionAlgorithm`, undefined);
      setValue(`modules.${index}.auth.preSharedKey`, undefined);
    }
  }, [securityLevel, index, setValue]);

  const renderAuth = securityLevel === 'AUTH_NO_PRIV' || securityLevel === 'AUTH_PRIV';
  const renderEncryption = securityLevel === 'AUTH_PRIV';

  const ProtocolInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.protocol`> }) => (
      <FormFieldWrapper error={getErrors('protocol')}>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          label={t('moduleSnmpAuthProtocol')}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleSnmpAuthProtocolPlaceholder')}
          isRequired
          options={mapSnmpAuthProtocolsToOptions(t)}
          dataRole={`module-${index}-auth-protocol`}
          key={`module-${index}-auth-protocol`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const UsernameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.username`> }) => (
      <FormFieldWrapper error={getErrors('username')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('username')}
          label={t('moduleSnmpAuthUsername')}
          isRequired
          dataRole={`module-${index}-auth-username`}
          key={`module-${index}-auth-username`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const PasswordInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.password`> }) => (
      <FormFieldWrapper error={getErrors('password')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('password')}
          label={t('moduleSnmpAuthPassword')}
          isRequired
          dataRole={`module-${index}-auth-password`}
          key={`module-${index}-auth-password`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const SecurityLevelInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.securityLevel`> }) => (
      <FormFieldWrapper error={getErrors('securityLevel')}>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          label={t('moduleSnmpAuthSecurityLevel')}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleSnmpAuthSecurityLevelPlaceholder')}
          isRequired
          options={mapSnmpSecurityLevelToOptions(t)}
          dataRole={`module-${index}-auth-security-level`}
          key={`module-${index}-auth-security-level`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const ContextNameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.contextName`> }) => (
      <FormFieldWrapper error={getErrors('contextName')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('contextName')}
          label={t('moduleSnmpAuthContextName')}
          dataRole={`module-${index}-auth-context-name`}
          key={`module-${index}-auth-context-name`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const EncryptionAlgorithmInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.encryptionAlgorithm`> }) => (
      <FormFieldWrapper error={getErrors('encryptionAlgorithm')}>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          label={t('moduleSnmpAuthEncryptionAlgorithm')}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleSnmpAuthEncryptionAlgorithmPlaceholder')}
          isRequired
          options={mapSnmpEncryptionAlgorithmToOptions(t)}
          dataRole={`module-${index}-auth-encryption-algorithm`}
          key={`module-${index}-auth-encryption-algorithm`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  const PreSharedKeyInput = useCallback(
    // Dies ist ein Fehler von eslint - immer das letzt useCallback vor dem return, gibt diesen eslint Fehler aus (der aber keiner ist)
    // eslint-disable-next-line react/no-unused-prop-types
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.auth.preSharedKey`> }) => (
      <FormFieldWrapper error={getErrors('preSharedKey')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('preSharedKey')}
          label={t('moduleSnmpAuthPreSharedKey')}
          isRequired
          dataRole={`module-${index}-auth-pre-shared-key`}
          key={`module-${index}-auth-pre-shared-key`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t]
  );

  return (
    <>
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-12">
            <Controller name={`modules.${index}.auth.contextName`} control={control} render={ContextNameInput} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <Controller name={`modules.${index}.auth.securityLevel`} control={control} render={SecurityLevelInput} />
          </div>
        </div>
        {renderAuth && (
          <div className="columns">
            <div className="column is-3">
              <Controller name={`modules.${index}.auth.protocol`} control={control} render={ProtocolInput} />
            </div>
            <div className="column is-4">
              <Controller name={`modules.${index}.auth.username`} control={control} render={UsernameInput} />
            </div>
            <div className="column is-5">
              <Controller name={`modules.${index}.auth.password`} control={control} render={PasswordInput} />
            </div>
          </div>
        )}

        {renderEncryption && (
          <div className="columns">
            <div className="column is-6">
              <Controller
                name={`modules.${index}.auth.encryptionAlgorithm`}
                control={control}
                render={EncryptionAlgorithmInput}
              />
            </div>
            <div className="column is-6">
              <Controller name={`modules.${index}.auth.preSharedKey`} control={control} render={PreSharedKeyInput} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
